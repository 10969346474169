import { fullDateFormat } from 'app/utils/utils';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const DateText = (props: IProps) => {
  const [date, setDate] = useState<string | undefined>(props.date);
  const [realtimeUpdatingIntervalId, setRealtimeUpdatingIntervalId] = useState<any>();

  const updateDate = useCallback((date: string, time: string, format: string) => {
    const fullDate = moment(date);
    if (time) {
      fullDate.add(moment.duration(time));
    }

    let _format = fullDateFormat();
    if (format === 'time') {
      _format = 'hh:mm A';
    } else if (format === 'hh_mm') {
      _format = 'hh:mm';
    } else if (format === 'monthName') {
      _format = 'MMMM';
    } else if (format === 'date') {
      _format = 'DD/MM/YYYY';
    } else if (format === 'fullDate') {
      _format = 'DD MMM YYYY  hh:mm A';
    } else if (format === 'DD_MMM_YYYY_hh_mm_A') {
      _format = 'DD MMM YYYY  hh:mm A';
    } else if (format === 'dayNumber') {
      _format = 'DD';
    } else if (format === 'day') {
      _format = 'dddd';
    } else if (format === 'fromNow') {
      _format = 'fromNow';
    } else if (format === 'DD_MMM_YYYY') {
      _format = 'DD MMM YYYY';
    } else if (format === 'DD_MMMM') {
      _format = 'DD MMMM';
    } else if (format === 'DD_MM') {
      _format = 'DD MMM';
    } else if (format === 'ND_MMMM') {
      if (fullDate.isBefore(moment().startOf('year'))) {
        _format = 'Do MMMM YYYY';
      } else {
        _format = 'Do MMMM';
      }
    } else if (props.format === 'DD_MMM_YYYY?') {
      _format = 'DD MMM';
    } else {
      _format = format;
    }

    if (fullDate.isDST() && props.ignoreDST) {
      // ignore the daylight
      const timeWithoutDSTSections = fullDate.clone().startOf('year').format('Z').replace('+', '').split(':');
      const fullDateTimeSections = fullDate.clone().format('Z').replace('+', '').split(':');
      const hourDiff = Number(fullDateTimeSections.at(0)) - Number(timeWithoutDSTSections.at(0));
      const minDiff = Number(fullDateTimeSections.at(1)) - Number(timeWithoutDSTSections.at(1));
      fullDate.subtract(hourDiff, 'hour').subtract(minDiff, 'minute');
    }

    if (_format === 'fromNow') {
      setDate(fullDate.fromNow(true));
    } else {
      setDate(fullDate.format(_format));
    }
  }, []);

  useEffect(() => {
    if (!props.date) return;
    updateDate(props.date, props.time!, props.format!);
    if (props.realtimeEnabled) {
      setRealtimeUpdatingIntervalId(
        setInterval(() => {
          updateDate(props.date!, props.time!, props.format!);
        }, 1000)
      );
    }
    return () => {
      clearInterval(realtimeUpdatingIntervalId);
    };
  }, [props.date, props.time, props.format, props.realtimeEnabled]);

  return (
    <span title="" className={props.className}>
      {date}
    </span>
  );
};

export default DateText;

export interface IProps {
  className?: string;
  realtimeEnabled?: boolean;
  date?: string;
  time?: string;
  format?: Formats;
  ignoreDST?: boolean;
}

export type Formats =
  | 'fullDate'
  | 'date'
  | 'monthName'
  | 'time'
  | 'day'
  | 'dayNumber'
  | 'fromNow'
  | 'hh_mm'
  | 'DD_MMM_YYYY'
  | 'DD/MM/YYYY'
  | 'DD_MMMM'
  | 'ND_MMMM'
  | 'YYYY'
  | 'DD_MMM_YYYY?'
  | 'DD_MM';
