import _ from 'lodash';
import { Helmet } from 'react-helmet';

export const PageHeader = (props: { title?: string }) => {
  return (
    <Helmet>
      <title>
        {`${
          !_.isEmpty(props.title) &&
          !_.isUndefined(props.title) &&
          !_.isNull(props.title) &&
          !props.title?.includes('undefined')
            ? props.title + ' - '
            : ''
        }`}
        ClassTutor
      </title>
    </Helmet>
  );
};
