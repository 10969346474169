import { humanize, toTitleCase, transform } from '@alduino/humanizer/string';
import { ClassStatues, ParentStatuses, StudentClassStatus, StudentStatuses, TeacherStatues } from 'app/services/types';
import styled from 'styled-components';

const StatusTitleStyled = styled.div`
  display: flex;
`;
const LateBadgeStyled = styled.div`
  background: #fc5a5a;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 10px;
  width: fit-content;
  margin-left: 7px;
`;

const StatusTitle = (props: StatusBoxProps) => {
  return (
    <StatusTitleStyled>
      {transform(humanize(String(props.status)), toTitleCase)}{' '}
      {props?.status?.toString()?.includes('late') ? <LateBadgeStyled>L</LateBadgeStyled> : <></>}
    </StatusTitleStyled>
  );
};

export default StatusTitle;

type StatusBoxProps = {
  status: Statuses;
};

type Statuses = StudentStatuses | TeacherStatues | ParentStatuses | StudentClassStatus | ClassStatues | string;
