import { message } from 'antd';
import { useUpdateRegisterMutation } from 'app/services/registers';
import { CheckinLogs } from 'app/services/types';
import { MAIN_SELECTORS } from 'app/store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IconComponents } from '../Icons';

const LinkStyled = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  .btn {
    display: flex;
    color: white;
    border: 1px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding-right: 7px;
    padding-left: 2px;
    gap: 2px;
    padding-top: 3px;
    padding-bottom: 3px;

    &.active {
      background-color: #2196f3;
    }

    &.inactive {
      background-color: #e2e2ea;
    }

    .icon-bg {
      width: 22px;
      height: 22px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .icon {
      &.active {
        color: #2196f3;
      }
      &.inactive {
        color: #e2e2ea;
      }
    }
    span {
      font-weight: 500;
      text-decoration: none;
    }
  }
`;

const NoLinkStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  span {
    text-wrap: nowrap;
    padding-top: 2px;
    color: red;
    font-size: smaller;
  }
`;

interface ZoomComponentProps {
  link?: string;
  status?: string;
  id?: string;
  logs?: CheckinLogs[];
  zoomId?: string;
  passcode?: string;
}

export const JoinZoom = (props: ZoomComponentProps) => {
  const [createLog] = useUpdateRegisterMutation();
  const user = useSelector(MAIN_SELECTORS.user);

  const handleCreateLog = async () => {
    if (props.id && user.admin) {
      if (props.logs && props.logs?.length > 0) {
        await createLog({
          registerId: String(props.id),
          body: {
            checkinLogs: [
              ...props.logs,
              {
                admin: user.admin,
                timestamp: new Date()
              }
            ]
          }
        });
      } else {
        await createLog({
          registerId: String(props.id),
          body: {
            checkinLogs: [
              {
                admin: user.admin,
                timestamp: new Date()
              }
            ]
          }
        });
      }
    }
  };
  return (
    <>
      {props.link ? (
        <LinkStyled>
          <a
            href={props.link}
            target="_blank"
            rel="noreferrer"
            className={`btn ${props.status == 'started' ? 'active' : 'inactive'}`}
            onClick={(e) => {
              e.stopPropagation();
              handleCreateLog();
            }}
          >
            <div className="icon-bg">
              <IconComponents.LinkIconComponent
                className={`icon ${props.status == 'started' ? 'active' : 'inactive'}`}
              />
            </div>

            <span>Join</span>
          </a>
          <IconComponents.CopyIconComponent
            onClick={(e: any) => {
              e.stopPropagation();
              navigator.clipboard.writeText(`${props.link!} \nID:${props.zoomId} \nPasscode:${props.passcode}`);
              message.info('Zoom meeting details is copied to your clipboard');
            }}
          />
        </LinkStyled>
      ) : (
        <>
          <NoLinkStyled>
            <IconComponents.IncompleteIconComponent />
            <span>Zoom link not present</span>
          </NoLinkStyled>
        </>
      )}
    </>
  );
};
