import { Tabs } from 'antd';
import { useEffect, useState } from 'react';

export type FamilyTabProps = {
  linkedParents?: any;
  linkedStudents?: any;
  setTargetId?: (x: string) => void;
  targetId?: string;
  setRole?: (x?: string) => void;
  role?: string;
};

export const FamilyTab = (props: FamilyTabProps) => {
  const [tabsInfo, setTabsInfo] = useState([]);

  useEffect(() => {
    const linkedParents = props?.linkedParents ?? [];
    const linkedStudents = props?.linkedStudents ?? [];

    if (linkedParents.length > 0 || linkedStudents.length > 0) {
      const tempList: any = [
        { label: 'All', key: 'all' },
        ...linkedParents.map((parent: any) => ({
          label: `[P] ${parent?.name ?? parent?.attributes?.name} ${parent?.lastName ?? parent?.attributes?.lastName}`,
          key: `{"id":"${parent?.id}", "role":"parent"}`
        })),
        ...linkedStudents.map((student: any) => ({
          label: `[S] ${student?.name ?? student?.attributes?.name} ${
            student?.lastName ?? student?.attributes?.lastName
          }`,
          key: `{"id":"${student?.id}", "role":"student"}`
        }))
      ];
      setTabsInfo(tempList);
    } else {
      setTabsInfo([]);
    }
  }, [props?.linkedParents, props?.linkedStudents]);

  return (
    <Tabs
      activeKey={props?.targetId && props?.role ? `{"id":"${props?.targetId}", "role":"${props.role}"}` : 'all'}
      className="st-table-filter-tabs mt-5"
      onChange={(e) => {
        if (props.setTargetId && props.setRole) {
          if (e != 'all') {
            const keyParsed = JSON.parse(e);
            props.setTargetId(keyParsed.id);
            props.setRole(keyParsed.role);
          } else if (e == 'all') {
            props.setTargetId('all');
            props.setRole(undefined);
          }
        }
      }}
      items={tabsInfo}
    ></Tabs>
  );
};
