import { utcToLocalDateTime } from 'app/utils/utils';
import moment from 'moment';
import { HeaderProps } from 'react-big-calendar';
import styled from 'styled-components';
import DateText from '../DateText/DateText';

const CustomHeaderStyled = styled.div`
  font-family: var(--st-font-family-secondary);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 80px;
  .current-day {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    text-align: center;
    color: black;
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 50%;
    &.active {
      background: #45b1b3;
      color: #ffffff;
    }
  }
`;

export default function CustomHeader(props: HeaderProps) {
  const date = utcToLocalDateTime(moment(props.date).format());
  return (
    <CustomHeaderStyled>
      <div>
        <DateText date={date.toISOString()} format={'day'} />
      </div>
      <div className={`current-day ${date.startOf('day').isSame(moment().startOf('day')) && 'active'}`}>
        <DateText date={date.toISOString()} format={'dayNumber'} />
      </div>
    </CustomHeaderStyled>
  );
}
