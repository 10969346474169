import qs from 'qs';
import api from '.';
import { StudentSchedule, UserType } from './types';

export const scheduleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSchedule: builder.query<
      StudentSchedule[],
      {
        entityId: string;
        entityType: UserType;
        startDate: string;
        endDate: string;
        studentIds?: string[];
        subjectIds?: string[];
        yearIds?: string[];
      }
    >({
      query: ({ entityId, entityType, startDate, endDate, studentIds }) => {
        const payload: any = {
          startDate,
          endDate
        };

        if (studentIds?.length) {
          payload.studentIds = studentIds;
        }

        if (entityType === UserType.Teacher) {
          payload.teacherId = entityId;
        } else if (entityType === UserType.Parent) {
          payload.parentId = entityId;
        } else if (entityType === UserType.Student) {
          payload.studentId = entityId;
        } else if (entityType === UserType.Admin) {
          payload.adminId = entityId;
        }
        const query = qs.stringify(payload, {
          encodeValuesOnly: true
        });
        return {
          url: `/api/schedule/${entityType}/${entityId}?${query}`,
          method: 'GET'
        };
      },
      providesTags: ['Student', 'StudentClass']
    }),
    getScheduleLessons: builder.query<
      StudentSchedule[],
      {
        teacherIds?: string[];
        classIds?: string[];
        meetingAccountIds?: string[];
        studentIds?: string[];
        startDate: string;
        endDate: string;
      }
    >({
      query: (payload) => {
        return {
          url: `/api/schedule/lesson-schedule?${qs.stringify(payload, { encodeValuesOnly: true })}`,
          method: 'GET'
        };
      },
      providesTags: ['Student']
    })
  })
});
