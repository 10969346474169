import qs from 'qs';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Container from '../Container/Container';
import { IconComponents } from '../Icons';
import { Button } from '../v1/Button';

export const HomeworkV2 = (props: IProps) => {
  const [query, setQuery] = useState(qs.stringify({}));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    if(props.type=='homework'){
      setQuery(
        qs.stringify(
          {
            h: props.homeworkReference,
            s: props.studentId,
            n: props.studentName,
            l: props.studentLastName,
            u: `${process.env.REACT_APP_NODE_ENV ?? 'development'}_${props.studentId}_${props.registerId}_${
              props.registerStudentId
            }`,
            c: props.classId,
            d: props.registerClassDate,
            t: props.type
          },
          {
            encodeValuesOnly: true
          }
        )
      );
    }
    else if(props.type=='assessment'){
       setQuery(
         qs.stringify(
           {
             h: props.homeworkReference,
             s: props.studentId,
             n: props.studentName,
             l: props.studentLastName,
             u: `${process.env.REACT_APP_NODE_ENV ?? 'development'}_${props.studentId}`,
             c: 'assessment',
             d: '2024-09-02',
             t: props.type
           },
           {
             encodeValuesOnly: true
           }
         )
       );
    }
  },[props.type])

  return (
    <ReactModal
      isOpen={props.modalIsOpen!}
      onRequestClose={props.onRequestClose}
      className="card card-body modal__marker d-flex flex-column align-items-center st-student-homework"
    >
      <div className="d-flex justify-content-end w-100">
        <Button
          buttonType="text"
          style={{ width: '50px', height: '50px' }}
          onClick={(e: any) => {
            props.onRequestClose && props.onRequestClose(e);
          }}
        >
          <IconComponents.CloseIconComponent width={30} height={30} />
        </Button>
      </div>
      <Container isLoading={isLoading} className="w-100 h-100">
        <iframe
          className="w-100 h-100"
          src={`https://redirect.classtutor.co.uk/test?${query}`}
          onLoad={() => {
            setIsLoading(false);
          }}
        ></iframe>
      </Container>
    </ReactModal>
  );
};

interface IProps {
  homeworkReference: string;
  studentId: string;
  studentName: string;
  studentLastName: string;
  registerStudentId?: string;
  registerId?: string;
  classId?: string;
  registerClassDate?: string;
  modalIsOpen?: boolean;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  type:string;
}
