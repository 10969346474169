import { Divider, message } from 'antd';
import { ReactComponent as ChevronLeft } from 'app/assets/svgs/ic_Chevron Left.svg';
import { ReactComponent as ChevronRight } from 'app/assets/svgs/ic_Chevron Right.svg';
import { ReactComponent as Close } from 'app/assets/svgs/ic_Close.svg';
import Alert from 'app/components/Alerts/Alert';
import Input from 'app/components/Form/Input';
import Avatar from 'app/components/Icons/Avatar';
import Spinner from 'app/components/Spinner';
import { Button } from 'app/components/v1/Button';
import * as InputsV1 from 'app/components/v1/Input';
import { HomeworkStatus } from 'app/features/subjects/show-subject/homeworks/components/HomeworkStatCard';
import { registerStudentApi, useUpdateRegisterStudentMutation } from 'app/services/register-student';
import { registersApi, useAssignHomeworksToTeacherMutation } from 'app/services/registers';
import {
  ApiResponseData,
  HomeworkMarkingStatuses,
  Register,
  RegisterHomeworkErrorStatuses,
  RegisterStudent,
  Teacher,
  UploadedFile
} from 'app/services/types';
import { EventPayload } from 'app/services/web-socket/ws-socket.service';
import { AppStore, MAIN_SELECTORS, notificationsSlice } from 'app/store';
import { EMPTY_DATA_MARK, getFileUrl, getRandomInt, zipFiles } from 'app/utils/utils';
import fileSaver from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import '../markers.scss';
import TabContent from './TabContent';

const StudentHomeworkStyled = styled.div``;

export default function StudentHomework(props: IStudentHomeworkProps) {
  const user = useSelector(MAIN_SELECTORS.user);
  const [getRegister, { isLoading: getRegisterLoading }] = registersApi.endpoints.getRegister.useLazyQuery();
  const [getRegisterStudents, { isLoading: getRegisterStudentsLoading }] =
    registerStudentApi.endpoints.getLessonHomeworks.useLazyQuery();
  const [register, setRegister] = React.useState<RegisterMappedToViewProp>();
  const [updateRegister, {}] = registersApi.endpoints.updateRegister.useMutation();
  const [updateRegisterStudent, { isLoading: updatingRegisterStudent }] = useUpdateRegisterStudentMutation();
  const [assignHomeworksToTeacher] = useAssignHomeworksToTeacherMutation();
  const [filesRequired, setFilesRequired] = React.useState(false);

  const [currentStudentIndex, setCurrentStudentIndex] = React.useState(0);

  const [unableToMarkReason, setUnableToMarkReason] = React.useState(getCurrentStudent()?.error ?? '');

  function getCurrentStudent(): RegisterStudentMappedToViewProp {
    return register?.registerStudents?.at(currentStudentIndex)!;
  }

  React.useEffect(() => {
    loadRegister().then(() => {});
  }, [props.registerId]);
  const assignMarkerToHomework = async (teacherId: string, registerId: string) => {
    try {
      const res = await assignHomeworksToTeacher({
        teacherID: teacherId,
        registerId: registerId
      }).unwrap();
      setRegister((state: any) => {
        if (!state) return state;
        state.lockedByTeacher = user.entity;
        state.homeworkLockStartTime = res.homeworkLockStartTime;
        state.homeworkLockEndTime = res.homeworkLockEndTime;
        return { ...state };
      });
    } catch (e) {
      message.error("Couldn't assigned the homework");
    }
  };

  async function submitHomeWorkMarking() {
    try {
      if (!getCurrentStudent()?.homeworkSubmitted) {
        message.error('Please submit the homework first');
        return;
      }
      /*       if (moment(register?.homeworkDeadline).isAfter(moment())) {
        message.error('Homework is not yet due');
        return;
      } */
      if (
        (getCurrentStudent()?.mark === '' || !getCurrentStudent()?.mark) &&
        (!unableToMarkReason || unableToMarkReason === '')
      ) {
        message.error('Please select a mark or an error reason');
        return;
      }
      if (register?.homeworkMarks && parseInt(getCurrentStudent()?.mark) > register?.homeworkMarks) {
        message.error(`You can't set a mark more than ${register?.homeworkMarks}`);
        return;
      }
      if (register?.homeworkMarks && parseInt(getCurrentStudent()?.mark) < 0) {
        message.error(`You can't set a mark less than 0`);
        return;
      }
      if (unableToMarkReason?.split(',')[0]) {
        await unableToMarkUpdate();
      } else {
        await updateRegisterStudent({
          id: getCurrentStudent()?.id!,
          registerStudent: {
            homeworkMarkedFiles: getCurrentStudent().markedFiles?.map((file) => file?.id) || [],
            homeworkMark: getCurrentStudent().mark,
            homeworkMarkingStatus: 'marked',
            homework_marking_teacher: props.forceMarking ? register?.registerTeacherId : user.entity?.id,
            homeworkComment: getCurrentStudent().comment
          }
        }).unwrap();
        message.info('Homework marked successfully');
        await loadRegister();
        setUnableToMarkReason('');
        next();
      }
    } catch (e) {
      message.error('Something went wrong');
    }
    props.closeModal && props.closeModal(true);
  }
  async function loadRegister() {
    try {
      const register = _.cloneDeep(
        await getRegister({
          registerId: props.registerId,
          populate: [
            'class',
            'class.subject',
            'class.year',
            'homeworkFiles',
            'homeworkSolutions',
            'homework_locked_by_teacher',
            'homework_locked_by_teacher.user'
          ]
        }).unwrap()
      );
      (register.data.attributes as any).register_students = await getRegisterStudents({
        filters: {
          register: {
            id: {
              $eq: props.registerId
            }
          }
        },
        populate: {
          homeworkSubmissionFiles: true,
          homeworkMarkedFiles: true,
          student: true,
          teacher: true,
          homework_marking_teacher: {
            populate: '*'
          }
        }
      }).unwrap();
      const mappedRegisterToViewProp = mapRegisterToViewProp({
        ...register.data.attributes,
        id: register.data.id
      });
      setRegister(mappedRegisterToViewProp);

      let selectedRegisterStudentIndex = 0;
      if (props.registerStudentId) {
        selectedRegisterStudentIndex = mappedRegisterToViewProp?.registerStudents?.findIndex(
          (registerStudent) => registerStudent.id.toString() === props.registerStudentId?.toString()
        );
      } else {
        selectedRegisterStudentIndex = mappedRegisterToViewProp?.registerStudents?.findIndex(
          (registerStudent) => !registerStudent.markingStatus && !registerStudent.error
        );
      }

      setCurrentStudentIndex(selectedRegisterStudentIndex > 0 ? selectedRegisterStudentIndex : 0);

      return register;
    } catch (e) {
      message.error('Error loading register');
    }
  }

  async function unableToMarkUpdate() {
    try {
      const studentRegisterBody: any = {
        homework_marking_teacher: props.forceMarking ? register?.registerTeacherId : user?.entity?.id
      };
      const error = unableToMarkReason?.split(',')?.at(1);
      const errorType: string | undefined = unableToMarkReason?.split(',')?.at(0);
      if (errorType === 'teacher') {
        await updateRegister({
          registerId: register?.id!,
          body: {
            homeworkErrorStatus: RegisterHomeworkErrorStatuses.PENDING,
            homeworkErrorDate: moment().format(),
            homework_error_by: props.forceMarking ? register?.registerTeacherId : user.entity?.id,
            homeworkErrorReason: error,
            homeworkMarkingBlocked: true
          }
        }).unwrap();
      } else if (errorType === 'student') {
        studentRegisterBody.homeworkErrorDate = moment().format();
        studentRegisterBody.homework_error_by = props.forceMarking
          ? register?.registerTeacherId
          : (studentRegisterBody.homeworkErrorReason = error);
        studentRegisterBody.homeworkMarkingStatus = 'error';
      } else {
        studentRegisterBody.homeworkMarkedFiles =
          getCurrentStudent().markedFiles?.map((file: UploadedFile) => file?.id) || [];
        studentRegisterBody.homeworkComment = getCurrentStudent()?.comment;
      }
      await updateRegisterStudent({
        id: getCurrentStudent()?.id!,
        registerStudent: studentRegisterBody
      }).unwrap();
      await loadRegister();
      setUnableToMarkReason('');
      if (errorType === 'teacher') {
        props.closeModal('closed');
      }
    } catch (e) {
      message.error('Unable to mark');
    }
  }

  function next() {
    if (currentStudentIndex < register?.registerStudents?.length! - 1) {
      setCurrentStudentIndex(currentStudentIndex + 1);
    }
  }
  function prev() {
    if (currentStudentIndex > 0) {
      setCurrentStudentIndex(currentStudentIndex - 1);
    }
  }
  async function downloadStudentWork() {
    try {
      const urls: string[] = [];
      getCurrentStudent()?.studentSubmittedFiles?.forEach((file) => {
        urls.push(getFileUrl(file));
      });
      const content = await zipFiles(new JSZip(), [
        {
          name: `${getCurrentStudent()?.name}-${getRandomInt(100, 500000)}`,
          urls
        }
      ]);
      fileSaver.saveAs(content, `${new Date().toDateString()}-${Math.floor(Math.random() * 100000)}.zip`);
    } catch (e) {
      message.error("Couldn't download the student homework");
    }
  }

  async function downloadAllStudentsHomeworks() {
    const folders: { name: string; urls: string[] }[] = [];
    register?.registerStudents?.forEach((student) => {
      const folder = {
        name: `${student?.name}-${getRandomInt(100, 500000)}`,
        urls: [] as string[]
      };
      student.studentSubmittedFiles?.forEach((file) => {
        folder.urls.push(getFileUrl(file!));
      });
      folders.push(folder);
    });
    const content = await zipFiles(new JSZip(), folders);
    fileSaver.saveAs(content, `${new Date().toDateString()}-${Math.floor(Math.random() * 100000)}.zip`);
  }

  const notificationState: EventPayload = useSelector(
    (state: AppStore) => state[notificationsSlice.name]?.notification
  );

  React.useEffect(() => {
    // reload the register when something change by someone else
    if (!notificationState) return;
    if (
      ['1100', '1101'].includes(notificationState?.eventCode?.toString()) &&
      register?.id?.toString() === notificationState?.entityId?.toString()
    ) {
      loadRegister();
    }
  }, [notificationState]);

  if (!register || getRegisterLoading) {
    return (
      <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
        <Spinner />;
      </div>
    );
  }
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.closeModal('closed');
      }}
      contentLabel="Example Modal"
      className="card card-body modal__marker d-flex flex-column align-items-center st-student-homework"
    >
      <StudentHomeworkStyled className="container-fluid">
        <Header
          currentStudent={getCurrentStudent()}
          register={register}
          currentStudentIndex={currentStudentIndex}
          onCloseModal={() => props.closeModal('closed')}
          onNextStudentClicked={next}
          onPreviousStudentClicked={prev}
          profile={user.entity}
          onDownloadAllStudentsHomeworksClicked={() => {
            downloadAllStudentsHomeworks();
          }}
        />
        <div className="row modal__marker__content">
          <RegisterQuestionAndAnswer
            currentStudent={getCurrentStudent()}
            register={register}
            forceMarking={props.forceMarking}
            isLoading={updatingRegisterStudent}
            profile={user.entity}
          />
          <StudentQuestionAndAnswer
            currentStudent={getCurrentStudent()}
            register={register}
            profile={user.entity}
            forceMarking={props.forceMarking}
            onFilesUploaded={(files) => {
              setRegister((state) => {
                if (!state) return state;
                if (!state.registerStudents[currentStudentIndex]?.markedFiles?.length) {
                  state.registerStudents[currentStudentIndex].markedFiles = [];
                }
                state.registerStudents[currentStudentIndex].markedFiles =
                  state.registerStudents[currentStudentIndex].markedFiles?.concat(files);
                return { ...state };
              });
            }}
            currentStudentIndex={currentStudentIndex}
            filesRequired={filesRequired}
            onDownloadFile={() => downloadStudentWork()}
            onFileRemoved={(fileId) => {
              setRegister((state) => {
                if (!state) return state;
                state.registerStudents[currentStudentIndex].markedFiles = state.registerStudents[
                  currentStudentIndex
                ].markedFiles.filter((f) => f?.id?.toString() !== fileId.toString());
                return { ...state };
              });
            }}
          />
        </div>
        <Divider />
        <Footer
          currentStudent={getCurrentStudent()}
          forceMarking={props.forceMarking}
          register={register}
          onMarkChanged={(value) => {
            setRegister((state) => {
              if (!state) return state;
              state.registerStudents[currentStudentIndex].mark = value.toString();
              return { ...state };
            });
          }}
          onCommentChanged={(value) => {
            setRegister((state) => {
              if (!state) return state;
              state.registerStudents[currentStudentIndex].comment = value.toString();
              return { ...state };
            });
          }}
          onSetErrorReason={(e) => {
            setUnableToMarkReason(e ? e.toString() : '');
          }}
          onMarkingTeacherChanged={() => {
            assignMarkerToHomework(props.forceMarking ? register?.registerTeacherId : user.entity?.id, register.id);
          }}
          onSubmit={() => {
            submitHomeWorkMarking();
          }}
          isLoading={updatingRegisterStudent}
          profile={user.entity}
        />
      </StudentHomeworkStyled>
    </Modal>
  );
}
// -----------------------------------------------------------------------------------------------------------------
function StudentQuestionAndAnswer(props: {
  profile: Teacher;
  currentStudentIndex: number;
  register: RegisterMappedToViewProp;
  currentStudent: RegisterStudentMappedToViewProp;
  filesRequired: boolean;
  forceMarking?: boolean;
  onFilesUploaded: (files: UploadedFile[]) => void;
  onFileRemoved: (fileId: string) => void;
  onDownloadFile: () => void;
}) {
  return (
    <div className="col-lg-6 column left-border">
      {/* tabs */}
      <ul className="nav nav-tabs nav-tabs-desktop">
        <li>
          <a
            className={`${
              props.currentStudent?.markedFiles && props.currentStudent?.markedFiles?.length > 0 ? 'active' : ''
            }`}
            data-bs-toggle="tab"
            href="#MarkedPaper"
          >
            Marked Paper
          </a>
        </li>
        <li>
          <a
            className={`${
              props.currentStudent?.markedFiles && props.currentStudent?.markedFiles?.length > 0 ? '' : 'active'
            }`}
            data-bs-toggle="tab"
            href="#StudentWork"
          >
            Student Work
          </a>
        </li>
      </ul>
      {/* End tabs */}

      <div className="tab-content h-100">
        <div
          id="MarkedPaper"
          className={`tab-pane fade in ${
            props.currentStudent?.markedFiles && props.currentStudent?.markedFiles?.length > 0 ? 'active show' : ''
          }`}
          style={{ height: '90%' }}
        >
          <TabContent
            for={'student'}
            register={props.register}
            registerStudent={props.currentStudent}
            files={props.currentStudent?.markedFiles || []}
            forceMarking={props.forceMarking}
            type={
              props.currentStudent?.studentSubmittedFiles?.length &&
              (!!props.register?.lockedByTeacher?.id || props.forceMarking) &&
              props.currentStudent?.markingStatus !== 'marked'
                ? 'upload'
                : 'waiting'
            }
            filesRequired={props.filesRequired}
            onDownloadStudentWorkClicked={props.onDownloadFile}
            onFilesUploaded={props.onFilesUploaded}
            onFileRemoved={props.onFileRemoved}
          />
        </div>
        <div
          id="StudentWork"
          className={`tab-pane fade ${
            props.currentStudent?.markedFiles && props.currentStudent?.markedFiles?.length > 0 ? '' : 'active show'
          }`}
          style={{ height: '90%' }}
        >
          <TabContent
            for={'student'}
            register={props.register}
            registerStudent={props.currentStudent}
            forceMarking={props.forceMarking}
            files={props.currentStudent?.studentSubmittedFiles || []}
            onFilesUploaded={() => {}}
            onFileRemoved={() => {}}
            type="question"
          />
        </div>
      </div>
    </div>
  );
}
// -----------------------------------------------------------------------------------------------------------------
function Header(props: {
  profile: Teacher;
  currentStudentIndex: number;
  register: RegisterMappedToViewProp;
  currentStudent: RegisterStudentMappedToViewProp;
  onPreviousStudentClicked: () => void;
  onNextStudentClicked: () => void;
  onCloseModal: () => void;
  onDownloadAllStudentsHomeworksClicked: () => void;
}) {
  return (
    <div className="row modal__marker__header">
      <div className="col-lg-5">
        <ul className="side-students">
          <li>
            <button
              disabled={props.currentStudentIndex === 0}
              onClick={() => props.onPreviousStudentClicked()}
              className="st-navigate-button"
            >
              <ChevronLeft width={25} height={25} />
            </button>
          </li>
          <li className="avatar-part">
            <Avatar name={props.currentStudent?.avatarImageName!} width={50} height={50} />
            <div>
              <div className="st-student-name fw-bolder d-flex align-items-center mb-0">
                {props.currentStudent?.name}
              </div>
              <div className="st-student-sub-info">
                ID: {props.currentStudent?.studentId} . {props.currentStudent?.userName}
              </div>
              {/* statues 
                default : waiting 
                if submit or percentage% add flag tags-status-submit 
                if inprogress add flag class tags-status-inprogress
                if missing add flag tags-status-missing
                if mark add flag tags-status-mark &  <span className="mark">L</span> 
                 */}

              <p
                className={`st-student-status ${
                  !!props.currentStudent?.homeworkSubmitted && !props.currentStudent?.markingStatus
                    ? 'st-submitted'
                    : ''
                }  ${props.currentStudent?.markingStatus ? 'st-marked' : ''}  ${
                  !props.currentStudent?.homeworkSubmitted &&
                  moment(props.register?.homeworkDeadline).isSameOrAfter(moment())
                    ? 'st-waiting'
                    : ''
                }  ${props.currentStudent?.isLate ? 'st-missing' : ''}`}
              >
                <span>
                  {props.currentStudent?.markingStatus === 'marked'
                    ? 'Marked'
                    : !props.currentStudent?.homeworkSubmitted
                    ? 'Waiting'
                    : props.currentStudent?.isLate
                    ? 'Late'
                    : 'Submitted'}
                </span>
                {moment(props.currentStudent?.finishedAt).isAfter(moment(props.register.homeworkDeadline)) && (
                  <span className="st-late-badge">L</span>
                )}
              </p>
            </div>
          </li>
          <li>
            <button
              onClick={() => props.onNextStudentClicked()}
              disabled={props.currentStudentIndex === props.register.registerStudents.length - 1}
              className=" btn btn-outline st-navigate-button"
            >
              <ChevronRight width={25} height={25} />
            </button>
          </li>
        </ul>
      </div>
      <div className="col-lg-7">
        <ul className="right-side">
          {/* {props.currentStudent?.markingBy ? (
            <>
              <li className="avatar-part">
                {props.currentStudent?.markingBy.id ? (
                  <>
                    <h3 className="title">Marker:</h3>
                    <div className="d-flex">
                      <Avatar
                        url={getFileUrl(props.currentStudent?.markingBy?.photo?.data?.at(0)?.attributes)!}
                        width={30}
                        height={30}
                      />

                      <div>
                        <h1 className="fw-bolder d-flex align-items-center mb-0">
                          {props.currentStudent?.markingBy?.name} {props.currentStudent?.markingBy?.lastName}
                        </h1>
                        <p className="text-start text-muted">
                          ID: {props.currentStudent?.markingBy?.teacherId} .{props.currentStudent?.markingBy?.name}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="text-muted">No Marker Selected</span>
                )}
              </li>
              <li className="total-pay">
                <p>
                  Total pay: <span className="bold">£{props.register?.payAmount}</span>
                </p>
              </li>
            </>
          ) : (
            <li>No marker Selected</li>
          )} */}
          <li className="left-border progress-part">
            <p>
              {props.register?.registerStudents?.filter((registerStudent) => !!registerStudent.markingStatus)?.length}{' '}
              of {props.register?.registerStudents?.length}: HWK remaining
            </p>
            <div className="progress bg-tint-success" style={{ height: '8px', borderRadius: '4px' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${
                    (Number(
                      props.register?.registerStudents?.filter(
                        (registerStudent) => !!registerStudent.markingStatus || !!registerStudent?.error
                      )?.length
                    ) /
                      props.register?.registerStudents?.length!) *
                    100
                  }%`
                }}
              ></div>
            </div>
          </li>
          {/* <li>
              <Button
                textType="white"
                type="white"
                classes="btn-main__border color-primary"
              >
                <Download />
                <span>Mark These Homeworks</span>
              </Button>
            </li> */}
          <li className="screen-title">
            <h1>View Homework</h1>
          </li>
          <li>
            {!!props.register.lockedByTeacher && (
              <button onClick={() => props.onDownloadAllStudentsHomeworksClicked()} className="btn btn-outline-primary">
                Download All Homework
              </button>
            )}
          </li>
          <li className="close p-0">
            <button onClick={() => props.onCloseModal()}>
              <Close style={{ cursor: 'pointer' }} width={25} height={25} />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
// -----------------------------------------------------------------------------------------------------------------
function RegisterQuestionAndAnswer(props: {
  isLoading: boolean;
  profile: Teacher;
  forceMarking?: boolean;
  currentStudent: RegisterStudentMappedToViewProp;
  register: RegisterMappedToViewProp;
}) {
  return (
    <div className="col-lg-6 column">
      {/* tabs Mobile */}
      <ul className="nav nav-tabs nav-tabs-mobile">
        <li>
          <a className="active" data-bs-toggle="tab" href="#Question">
            Question
          </a>
        </li>
        <li>
          <a className="" data-bs-toggle="tab" href="#Solution">
            Solution
          </a>
        </li>
        <li>
          <a data-bs-toggle="tab" href="#MarkedPaper">
            Marked Paper
          </a>
        </li>
        <li>
          <a className="" data-bs-toggle="tab" href="#StudentWork">
            Student Work
          </a>
        </li>
      </ul>
      {/* End Tabs Mobile */}
      {/* tabs */}
      <ul className="nav nav-tabs nav-tabs-desktop">
        <li>
          <a className="active" data-bs-toggle="tab" href="#Question">
            Question
          </a>
        </li>
        <li>
          <a className="" data-bs-toggle="tab" href="#Solution">
            Solution
          </a>
        </li>
      </ul>
      {/* End tabs */}

      <div className="tab-content h-100">
        <div id="Question" className="tab-pane fade in active show" style={{ height: '90%' }}>
          <TabContent
            for={'teacher'}
            register={props.register}
            registerStudent={props.currentStudent}
            files={props.register?.questions || []}
            forceMarking={props.forceMarking}
            type="question"
            onFilesUploaded={() => {}}
            onFileRemoved={() => {}}
          />
        </div>
        <div id="Solution" className="tab-pane fade" style={{ height: '90%' }}>
          <TabContent
            for={'teacher'}
            register={props.register}
            registerStudent={props.currentStudent}
            files={props.register?.solutions || []}
            forceMarking={props.forceMarking}
            type="question"
            onFilesUploaded={() => {}}
            onFileRemoved={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
// -----------------------------------------------------------------------------------------------------------------
function Footer(props: {
  isLoading: boolean;
  profile: Teacher;
  currentStudent: RegisterStudentMappedToViewProp;
  register: RegisterMappedToViewProp;
  forceMarking?: boolean;
  onMarkChanged: (e: number) => void;
  onSetErrorReason: (e: string) => void;
  onCommentChanged: (e: string) => void;
  onSubmit: () => void;
  onMarkingTeacherChanged: () => void;
}) {
  const user = useSelector(MAIN_SELECTORS.user);
  const disabled = React.useMemo(() => {
    return (
      !props.currentStudent?.markingStatus &&
      !props.currentStudent?.error &&
      !props.register.error &&
      !props.isLoading &&
      props.currentStudent?.homeworkSubmitted
      // moment(props.register?.homeworkDeadline).isSameOrBefore(moment())
    );
  }, [props.currentStudent, props.register]);

  const canMark = React.useMemo(() => {
    const unMarked = !props.currentStudent?.markingStatus && !props.currentStudent?.error;
    return unMarked && !props.isLoading && props.currentStudent?.homeworkSubmitted;
  }, [props.currentStudent, props?.register]);
  const [remainingTime, setRemainingTime] = React.useState<string | undefined>();
  React.useEffect(() => {
    let timeIntervalId: any = null;
    if (props.register.homeworkLockEndTime) {
      timeIntervalId = setInterval(() => {
        setRemainingTime(
          moment(moment.duration(moment(props.register.homeworkLockEndTime).diff(moment())).asMilliseconds()).format(
            'HH:mm:ss'
          )
        );
      });
    }
    return () => {
      clearInterval(timeIntervalId);
    };
  }, []);
  return (
    <div className="row modal__marker__footer">
      <div className="list-footer flex-lg-nowrap gap-4 align-items-start">
        {(!!props.register?.lockedByTeacher?.id || props.forceMarking) &&
          !props.currentStudent?.markingStatus &&
          !props.currentStudent?.error &&
          !props.register.error &&
          props.currentStudent?.homeworkSubmitted && (
            <>
              <InputsV1.FormControl style={{ width: '15%' }}>
                <InputsV1.TextArea
                  name="Comments"
                  disabled={['marked', 'error'].includes(props.currentStudent.homeworkMarkingStatus!)}
                  onChange={(e) => props.onCommentChanged(e)}
                  value={props.currentStudent?.comment}
                  rows={5}
                  style={{ resize: 'none', height: '100%' }}
                />
              </InputsV1.FormControl>
              <div className="marks-part gap-2 flex-column align-items-start">
                <p className="mx-2">Mark:</p>
                <div className="d-flex flex-row gap-1">
                  <div>
                    <InputsV1.FormControl>
                      <InputsV1.Input
                        name="Mark"
                        type="number"
                        value={props.currentStudent?.mark}
                        disabled={!disabled}
                        onChange={(e) => props.onMarkChanged(Number(e))}
                      />
                    </InputsV1.FormControl>

                    {/* <Input
                    value={props.currentStudent?.mark}
                    onChange={(e) =>
                      props.onMarkChanged(parseInt(e.target.value, 10))
                    }
                    label="Mark"
                    type="number"
                    readOnly={!disabled}
                  /> */}
                  </div>
                  <div>
                    <p>/</p>
                  </div>
                  <div>
                    <Input label="Mark" type="text" value={props.register?.homeworkMarks?.toString()} readOnly />
                  </div>
                </div>

                {props.currentStudent.homeworkMarkingStatus === 'marked' && (
                  <p className="total-mark">
                    {(parseInt(props.currentStudent?.mark, 10) / props.register?.homeworkMarks) * 100} %
                  </p>
                )}
              </div>
              {/* {remainingTime && (
                <span className="shadow mx-1 py-3 px-2">
                  Remaining Time {remainingTime}
                </span>
              )} */}
              <div className="my-auto">
                {' '}
                <Alert type="attention">Make sure you marked correctly. There’s no undo option after submission</Alert>
              </div>
            </>
          )}
        {props.currentStudent?.markingStatus === 'marked' && (
          <span className="st-mark-score">
            <span>Mark: </span>
            <span
              className={`${
                parseInt(props.currentStudent?.percentage?.toString()) < 50
                  ? 'red'
                  : parseInt(props.currentStudent?.percentage?.toString()) < 90
                  ? 'yellow'
                  : parseInt(props.currentStudent?.percentage?.toString()) >= 90
                  ? 'green'
                  : ''
              }`}
            >
              {Number(((Number(props.currentStudent?.mark) / props.register?.homeworkMarks) * 100).toFixed(2))} %
            </span>
          </span>
        )}
        {!props.register?.lockedByTeacher?.id && !props.forceMarking && (
          <Alert type="attention">
            Once you confirm marking you’ll have <strong>3 hrs</strong> to mark this homework. Payment will only be
            given once all homeworks <br></br> have been marked. To learn more <a href="#">click</a> here
          </Alert>
        )}
        {!props.currentStudent?.markingStatus && !props.currentStudent?.error && !props.register.error && (
          <div className="my-auto ms-auto">
            <div className="d-flex ms-auto">
              {props.register?.lockedByTeacher?.id?.toString() === props?.profile?.id?.toString() ||
              props.forceMarking ? (
                <>
                  {/* <InputSelectBtn
                    label="Unable to mark"
                    classes="select-dropdown"
                    readOnly={!disabled}
                    options={
                      _.toLower(user?.userType) === 'teacher'
                        ? markingBlockerReasons
                        : markingBlockerReasons?.filter((e) => e.value?.includes('student'))
                    }
                    onChange={(e) => props.onSetErrorReason(e.target.value as string)}
                    value={props.currentStudent?.error}
                  /> */}

                  <Button onClick={() => props.onSubmit()} color="primary" className="fw-bolder" disabled={!disabled}>
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  {props.register?.lockedByTeacher?.id &&
                  props.register?.lockedByTeacher?.id?.toString() !== props?.profile?.id?.toString() &&
                  !props.forceMarking ? (
                    <Button buttonType="text" color="primary" className="fw-bolder" disabled>
                      Marking Locked
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() => props.onMarkingTeacherChanged()}
                        color="primary"
                        className="fw-bolder"
                        disabled={!canMark}
                      >
                        Mark These Homeworks
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
// -----------------------------------------------------------------------------------------------------------------
function mapRegisterToViewProp(register: Register): RegisterMappedToViewProp {
  return {
    id: register.id || EMPTY_DATA_MARK,
    registerTeacherId: register?.teacher?.data?.id?.toString()!,
    homeworkLockStartTime: register.homeworkLockStartTime,
    homeworkLockEndTime: register.homeworkLockEndTime,
    questions: register.homeworkFiles?.data?.map((file: any) => file?.attributes as UploadedFile),
    solutions: register.homeworkSolutions?.data?.map((file: any) => file?.attributes as UploadedFile),
    registerStudents: register.register_students?.data.map((registerStudent: ApiResponseData<RegisterStudent>) =>
      mapRegisterStudentToViewProp({
        ...registerStudent.attributes,
        id: registerStudent.id as any
      })
    ),
    homeworkMarks: register.homeworkMarks,
    homeworkDeadline: register.homeworkDeadline,
    lockedByTeacher: (register.homework_locked_by_teacher?.data?.attributes
      ? {
          ...register.homework_locked_by_teacher?.data?.attributes,
          id: register.homework_locked_by_teacher?.data?.id
        }
      : null) as any,
    payAmount: register.payAmount,
    error: register.homeworkErrorReason!
  };
}
// -----------------------------------------------------------------------------------------------------------------
function mapRegisterStudentToViewProp(registerStudent: RegisterStudent): RegisterStudentMappedToViewProp {
  return {
    id: registerStudent.id || EMPTY_DATA_MARK,
    avatarImageName: registerStudent?.student?.data?.attributes?.avatarImageName,
    name: registerStudent?.student?.data?.attributes?.name || EMPTY_DATA_MARK,
    studentId: registerStudent?.student?.data?.attributes?.studentId || EMPTY_DATA_MARK,
    userName: registerStudent?.student?.data?.attributes?.name || EMPTY_DATA_MARK,
    markedFiles: registerStudent?.homeworkMarkedFiles?.data?.map((file: any) => ({
      ...file?.attributes,
      id: file?.id
    })),
    studentSubmittedFiles: registerStudent?.homeworkSubmissionFiles?.data?.map((file: any) => ({
      ...file?.attributes,
      id: file?.id
    })),
    markingStatus: registerStudent?.homeworkMarkingStatus,
    homeworkStatus: registerStudent?.homeworkStatus as any,
    mark: registerStudent?.homeworkMark || '',
    isLate: registerStudent?.homeworkSubmittedLate || false,
    color: registerStudent?.homeworkMarksColour,
    percentage: registerStudent?.homeworkMarksPercentage,
    error: registerStudent?.homeworkErrorReason,
    homeworkSubmitted: registerStudent?.homeworkSubmitted === true,
    homeworkMarkingStatus: registerStudent?.homeworkMarkingStatus,
    finishedAt: registerStudent?.homeworkCompletionDate,
    comment: registerStudent.homeworkComment,
    markingBy: registerStudent?.homework_marking_teacher?.data
      ? {
          ...registerStudent?.homework_marking_teacher?.data?.attributes,
          id: registerStudent?.homework_marking_teacher?.data?.id
        }
      : null,

    engagement: registerStudent?.engagement
  };
}
// -----------------------------------------------------------------------------------------------------------------
export type RegisterMappedToViewProp = {
  id: string;
  homeworkLockStartTime: string;
  homeworkLockEndTime: string;
  questions: UploadedFile[];
  solutions: UploadedFile[];
  registerStudents: RegisterStudentMappedToViewProp[];
  homeworkMarks: number;
  homeworkDeadline: string;
  lockedByTeacher: Teacher;
  payAmount: number;
  error: string;
  registerTeacherId: string;
};
// -----------------------------------------------------------------------------------------------------------------
export type RegisterStudentMappedToViewProp = {
  id: string;
  avatarImageName?: string;
  name: string;
  studentId: string;
  userName: string;
  markedFiles: UploadedFile[];
  studentSubmittedFiles: UploadedFile[];
  markingBy?: Teacher;
  markingStatus: HomeworkMarkingStatuses;
  mark: string;
  isLate: boolean;
  color: string;
  percentage: string;
  error?: string;
  homeworkSubmitted: boolean;
  homeworkMarkingStatus: HomeworkMarkingStatuses;
  finishedAt?: string;
  comment?: string;
  engagement: string;
  homeworkStatus?: HomeworkStatus;
};
// -----------------------------------------------------------------------------------------------------------------
export interface IStudentHomeworkProps {
  registerId: string;
  registerStudentId?: string;
  forceMarking?: boolean;
  modalIsOpen: boolean;
  closeModal: (result: any) => void;
}
