import { message } from 'antd';
import { notesApi } from 'app/services/notes';
import qs from 'qs';

const useNote = (props: UseNoteProps): UseNotePayload => {
  const {
    data: commentsQuery,
    refetch,
    isLoading
  } = notesApi.useFindNotesQuery(
    {
      requestQuery:
        props.allFamilyQuery ??
        qs.stringify(
          {
            filters: props?.role
              ? {
                  ...(props?.role === 'student'
                    ? { student: props?.targetEntityId }
                    : props?.role === 'parent'
                    ? { parent: props?.targetEntityId }
                    : {})
                }
              : {
                  ...(props?.source === 'student' || props?.source === 'sales'
                    ? { student: props?.targetEntityId }
                    : props?.source === 'parent' || props?.source === 'parent_payment'
                    ? { parent: props?.targetEntityId }
                    : props?.source === 'teacher'
                    ? { teacher: props?.targetEntityId }
                    : props?.source === 'register' && props?.teacherId
                    ? {
                        student: props?.targetEntityId,
                        teacher: props?.teacherId
                      }
                    : props?.source === 'register'
                    ? {
                        student: props?.targetEntityId
                      }
                    : {})
                },
            populate: '*',
            sort: ['createdAt:desc'],
            pagination: {
              pageSize: 1000
            }
          },
          {
            encodeValuesOnly: true
          }
        )
    },
    { skip: !props?.targetEntityId, refetchOnMountOrArgChange: true }
  );

  const [createOneNote, { isLoading: isCreatingNote }] = notesApi.useCreateOneNoteMutation();

  const [deleteOneNote, { isLoading: isDeletingNote }] = notesApi.useDeleteOneNoteMutation();

  const [updateOneNote, { isLoading: isUpdatingNote }] = notesApi.useUpdateOneNoteMutation();

  const addNote = async (content: string) => {
    try {
      await createOneNote({
        text: content,
        admin: props.sourceEntityId,
        ...(props.role
          ? props.role == 'student'
            ? { student: props?.targetEntityId }
            : props.role == 'parent'
            ? { parent: props?.targetEntityId }
            : {}
          : props?.source === 'student' || props?.source === 'sales'
          ? { student: props?.targetEntityId }
          : props?.source === 'parent' || props?.source === 'parent_payment'
          ? { parent: props?.targetEntityId }
          : props?.source === 'teacher'
          ? { teacher: props?.targetEntityId }
          : props?.source === 'register'
          ? {
              student: props?.targetEntityId,
              teacher: props?.teacherId,
              register: props?.regId,
              class: props?.classId
            }
          : {}),
        source:
          props?.source === 'student'
            ? 'student_detail'
            : props?.source === 'parent'
            ? 'parent_detail'
            : props?.source === 'teacher'
            ? 'teacher_detail'
            : props?.source
      }).unwrap();
      await refetch();
    } catch (e) {
      message.error('Something wrong happened');
    }
  };

  const deleteNote = async (id: string) => {
    await deleteOneNote({ id }).unwrap();
    await refetch();
  };

  const updateNote = async (id: string, content: string) => {
    await updateOneNote({
      id: id!,
      text: content
    }).unwrap();
    await refetch();
  };

  return {
    addNote,
    deleteNote,
    updateNote,
    refetch,
    isLoading: isLoading || isCreatingNote || isUpdatingNote || isDeletingNote,
    notes:
      commentsQuery?.data?.map((data: any) => {
        return {
          id: data?.id?.toString()!,
          content: data?.attributes?.text!,
          datetime: data?.attributes?.createdAt!,
          name: data?.attributes?.admin?.data?.attributes?.name || data?.attributes?.teacher?.data?.attributes?.name,
          source: data?.attributes?.source
        };
      }) || []
  };
};

export default useNote;

export type UseNoteProps = {
  sourceEntityId: string;
  targetEntityId: string;
  classId?: string;
  regId?: string;
  teacherId?: string;
  source: string;
  allFamilyQuery?: any;
  role?: string;
};

export type UseNotePayload = {
  refetch: Function;
  notes: Note[];
  addNote: Function;
  deleteNote: Function;
  updateNote: Function;
  isLoading: boolean;
};

export type Note = {
  id: string;
  content: string;
  datetime: string;
  name: string;
  source: string;
};
