import { Popover } from 'antd';
import { StudentSchedule } from 'app/services/types';
import _ from 'lodash';
import styled from 'styled-components';
import Typography from '../Typography/Typography';
import { EventPopup } from './EventPopup';

const Styled = styled.div`
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  padding: 6px;

  .subject-name {
    font-weight: 700;
  }

  .event-student-name {
    font-size: 10px;
    color: white;
    padding: 0px;
    margin: 0px;
  }
`;

export const CustomEvent = (props: IProps) => {
  const resource = props?.event.resource as StudentSchedule;

  let content = (
    <Styled className="event-container-custom">
      <div className="subject-name">{resource?.subject}</div>
      <Typography type="text" width="110px" className="event-student-name">
        {resource?.classId} . {resource?.year} . {_.capitalize(resource?.classType)}
      </Typography>
    </Styled>
  );
  if (resource?.forEntityType === 'teacher') {
    content = (
      <Styled className="event-container-custom">
        <div className="subject-name">{resource?.subject}</div>
        <Typography type="text" width="80px" className="event-student-name">
          {resource?.classId} . {resource?.year}
        </Typography>
        <Typography type="text" width="80px" className="event-student-name">
          {resource?.entityName}
        </Typography>
      </Styled>
    );
  }
  return props?.event?.showEventPopup ? (
    <Popover trigger="click" arrow={false} title={<EventPopup event={props?.event} />}>
      {content}
    </Popover>
  ) : (
    content
  );
};

export interface IProps {
  event: {
    resource?: any;
    showEventPopup?: boolean;
  };
}
