import { ReactComponent as ArrowDown } from 'app/assets/svgs/ic_Chevron Down.svg';
import { ReactComponent as ArrowUp } from 'app/assets/svgs/ic_Chevron Up.svg';
import styled from 'styled-components';
import { Button, ButtonProps } from '../Button';

const TogglerButtonStyled = styled(Button)`
  padding: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.01em;
  justify-content: flex-start;
  color: #171725 !important;
  width: 100%;
`;

export type TogglerButtonProps = React.PropsWithChildren<ButtonProps & { opened?: boolean; iconOnLeftSide?: boolean }>;

export function TogglerButton(props: TogglerButtonProps) {
  return (
    <TogglerButtonStyled
      buttonType="text"
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
      className={props.className}
    >
      {props.iconOnLeftSide && (props.opened ? <ArrowUp /> : <ArrowDown />)}
      {props.children ? props.children : props.name}
      {!props.iconOnLeftSide && (props.opened ? <ArrowUp /> : <ArrowDown />)}
    </TogglerButtonStyled>
  );
}
