import { Pagination, Popconfirm, Typography, message } from 'antd';
import { Drawer } from 'app/components/Drawer/Drawer';
import { IconComponents } from 'app/components/Icons';
import { PageHeader } from 'app/components/PageHeader';
import SearchInput from 'app/components/SearchInput/SearchInput';
import { Table, TableColumn } from 'app/components/Table/Table';
import { Button } from 'app/components/v1/Button';
import { externalContactApi } from 'app/services/external-contact';
import { RequestQuery } from 'app/services/types';
import { defaultPageSize } from 'app/utils/consts';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { ExternalContactDetails } from './ExternalContactDetails';

const ExternalContactListStyled = styled.div`
  padding: 50px 20px;
`;
interface ExternalContact {
  id: string;
  attributes: {
    name: string;
    email: string;
    phone: string;
    address: string;
    companyName: string;
    jobRole: string;
    note: string;
  };
}

export default function ExternalContacts({}: {}) {
  const columns: TableColumn[] = useMemo<TableColumn[]>(
    () => [
      {
        name: 'Name',
        rowDataAccessor: (e: any) => ({
          ...e
        }),
        id: 'name',
        sortable: true,
        sortAccessor: 'name',
        cell: ({ value }: any) => {
          return (
            <div>
              <Typography>{value?.name}</Typography>
            </div>
          );
        }
      },
      {
        name: 'Email',
        rowDataAccessor: (e: any) => ({
          ...e
        }),
        id: 'email',
        sortable: true,
        sortAccessor: 'email',
        cell: ({ value }: any) => {
          return (
            <Typography className="d-flex">
              <a
                href={`mailto:${value?.email}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value?.email}
              </a>
            </Typography>
          );
        }
      },
      {
        name: 'Company Name',
        rowDataAccessor: (e: any) => ({
          ...e
        }),
        id: 'companyName',
        sortable: true,
        sortAccessor: 'companyName',
        cell: ({ value }: any) => {
          return <Typography className="d-flex">{value?.companyName}</Typography>;
        }
      },
      {
        name: 'Job Role',
        rowDataAccessor: (e: any) => ({
          ...e
        }),
        id: 'jobRole',
        sortable: true,
        sortAccessor: 'jobRole',
        cell: ({ value }: any) => {
          return <Typography className="d-flex">{value?.jobRole}</Typography>;
        }
      },
      {
        name: 'Phone',
        rowDataAccessor: (e: any) => ({
          ...e
        }),
        id: 'phone',
        sortable: true,
        sortAccessor: 'phone',
        cell: ({ value }: any) => {
          return (
            <Typography className="d-flex">
              <a
                href={`tel:${value?.phone}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value?.phone}
              </a>
            </Typography>
          );
        }
      }
    ],
    []
  );

  const [query, setQuery] = useState<RequestQuery>({
    pagination: {
      page: 1,
      pageSize: defaultPageSize
    }
  });

  const { data, isFetching, refetch } = externalContactApi.useGetExternalContactsQuery(query);
  const [deleteOne, deleteQuery] = externalContactApi.useDeleteExternalContactMutation();
  const [updateOne, updateQuery] = externalContactApi.useUpdateExternalContactMutation();
  const [createOne, createQuery] = externalContactApi.useCreateExternalContactMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: externalContact, isFetching: isFetchingExternalContact } =
    externalContactApi.useGetExternalContactByIdQuery(searchParams.get('externalContact')!, {
      skip: !searchParams.has('externalContact')
    });

  const handleOnPaginationChanged = (page: number, pageSize: number) => {
    setQuery({
      ...query,
      pagination: {
        ...query.pagination,
        page,
        pageSize
      }
    });
  };

  const formik = useFormik<any>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      email: yup.string().email('Invalid email format').optional()
    }),
    onSubmit: async (values) => {
      try {
        if (searchParams.has('externalContact')) {
          await updateOne({
            id: searchParams.get('externalContact')!,
            profileData: {
              name: values.name,
              email: values.email,
              phone: values.phone,
              address: values.address,
              companyName: values.companyName,
              jobRole: values.jobRole,
              note: values.note
            }
          }).unwrap();
        } else {
          await createOne({
            profileData: {
              name: values.name,
              email: values.email,
              phone: values.phone,
              address: values.address,
              companyName: values.companyName,
              jobRole: values.jobRole,
              note: values.note
            }
          }).unwrap();
        }
        message.success('External Contact is saved successfully');
        formik.resetForm();
        refetch();
      } catch (e: any) {
        formik.setErrors({ name: e?.data?.error?.message });
      }
    },
    onReset: () => {
      setSearchParams({}, { replace: true });
      formik.setErrors({});
      refetch();
    }
  });
  useEffect(() => {
    if (!externalContact) return;

    formik.setValues({
      name: externalContact.data.attributes.name || '',
      email: externalContact.data.attributes.email || '',
      phone: externalContact.data.attributes.phone || '',
      address: externalContact.data.attributes.address || '',
      companyName: externalContact.data.attributes.companyName || '',
      jobRole: externalContact.data.attributes.jobRole || '',
      note: externalContact.data.attributes.note || ''
    });
  }, [externalContact, searchParams]);

  const isLoading =
    isFetching || updateQuery.isLoading || createQuery.isLoading || deleteQuery.isLoading || isFetchingExternalContact;

  return (
    <ExternalContactListStyled className="st-student-page d-flex flex-column col-12 bg-light p-6 ">
      <div className="st-page-header d-flex flex-column flex-lg-row w-100 align-items-lg-center">
        <PageHeader title="External Contacts" />
        <h1 className="col">External Contacts</h1>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <SearchInput
            name="Search"
            onChange={useCallback(
              (value: string) => {
                setQuery({
                  ...query,
                  filters: {
                    $or: [
                      { name: { $containsi: value } },
                      { email: { $containsi: value } },
                      { companyName: { $containsi: value } },
                      { phone: { $containsi: value } }
                    ]
                  }
                });
              },
              [query]
            )}
          />

          <Button
            onClick={() => {
              setSearchParams({ createNew: 'true' });
            }}
          >
            <IconComponents.PlusIconComponent /> Add External Contact
          </Button>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Table
          className="mt-5"
          columns={columns}
          defaultSortDir="desc"
          defaultSortedColumnId="email"
          rows={data?.data?.map((e: ExternalContact) => ({ ...e.attributes, id: e.id })) || []}
          // isLoading={isLoading}
          onRowClicked={async (id) => {
            if (id) {
              setSearchParams({ externalContact: id });
            } else {
              console.error('Invalid row clicked');
            }
          }}
          onSorting={async (dir, column) => {
            setQuery((state) => ({
              ...state,
              sort: [`${column.sortAccessor}:${dir}`]
            }));
          }}
        />
      </div>
      <div className="d-flex flex-row justify-content-center mt-3">
        {data && (
          <Pagination
            defaultCurrent={data?.meta?.pagination?.page || 1}
            current={data?.meta?.pagination?.page || 1}
            total={data?.meta?.pagination?.total}
            pageSize={data?.meta?.pagination?.pageSize || 10}
            onChange={(page, pageSize) => handleOnPaginationChanged(page, pageSize)}
            showSizeChanger
          ></Pagination>
        )}
      </div>

      <Drawer
        title={
          <div className="d-flex flex-row align-items-center justify-content-between">
            <h4 className="m-0 col">{searchParams.has('createNew') ? 'Add' : 'Edit'}</h4>
            {/* {searchParams.has('externalContact') && (
              <Popconfirm
                disabled={isLoading}
                title="Are you sure to proceed with this, it can not be undone?"
                okText="Yes"
                cancelText="No"
                onConfirm={async () => {
                  try {
                    await deleteOne(searchParams.get('externalContact')!).unwrap();
                    message.success('External Contact deleted');
                    formik.resetForm();
                    refetch();
                  } catch (e: any) {
                    message.error(e?.data?.error?.message || 'Failed to delete external contact');
                  }
                }}
              >
                <IconComponents.DeleteIconComponent />
              </Popconfirm>
            )} */}
          </div>
        }
        onClose={() => {
          formik.resetForm();
          formik.setErrors({});
        }}
        closable={false}
        maskClosable={false}
        open={searchParams.has('externalContact') || searchParams.has('createNew')}
      >
        <ExternalContactDetails formik={formik} isLoading={isLoading} />
      </Drawer>
    </ExternalContactListStyled>
  );
}
