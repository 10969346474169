import api from './index';
import { GetQueryPayload, GetRegisterStudents } from './types';

export const issueReasonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIssueReasonsRegisterStudent: builder.query<GetRegisterStudents, GetQueryPayload>({
      providesTags: ['IssueReason'],
      query: (payload) => ({
        url: `/api/issue-reasons?${payload.requestQuery}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetIssueReasonsRegisterStudentQuery } = issueReasonApi;
