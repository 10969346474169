import { Country } from 'app/services/types';
import { countries } from 'country-list-json';
import * as _ from 'lodash';

export const getCountries = (options?: { orderBy: 'dial_code' | 'name' }): Country[] => {
  const mapped = countries.map((country: Country) => {
    country.image = `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${country.code}.svg`;
    return country;
  });
  const sortedByProp = options?.orderBy ?? 'dial_code';
  const sorted = _.sortedUniqBy(
    _.sortBy(mapped, (country) => Number(country[sortedByProp].replace(/[\+ ]/, ''))),
    (e) => e[sortedByProp]
  );
  const nuzlanda: Country = sorted.find((s) => s.dial_code === '+599') as Country;
  nuzlanda.image = 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg';
  return _.cloneDeep([
    {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'GB',
      flag: '🇬🇧',
      image: `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg`
    },
    ...sorted
  ]);
};
