import { FilePreview } from 'app/components/FilePreview/FilePreview';
import { IconComponents } from 'app/components/Icons';
import { Button } from 'app/components/v1/Button';
import { File } from 'app/components/v1/FileUploader';
import {
  RegisterMappedToViewProp,
  RegisterStudentMappedToViewProp
} from 'app/features/markers/student-homework/StudentHomework';
import { UploadedFile } from 'app/services/types';
import { dateFormat, getFileUrl } from 'app/utils/utils';
import moment from 'moment';
import Alert from '../../../components/Alerts/Alert';
import InputFilesUpload from '../../../components/Form/InputFilesUpload';

export default function TabContent(props: TabContentProps) {
  const { type, files, filesRequired } = props;

  if (props.for === 'student') {
    if (
      props.registerStudent?.markingBy &&
      props.register?.lockedByTeacher &&
      props.registerStudent?.markingBy?.id?.toString() !== props.register?.lockedByTeacher?.id?.toString() &&
      !props.forceMarking
    ) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
          <h1 className="st-alert-message tab-content__container__waiting">
            This homework is currently being marked by another teacher [{props.register?.lockedByTeacher?.name}]. You
            will not be able to mark it
          </h1>
        </div>
      );
    }
    if (
      !props.register?.lockedByTeacher?.id &&
      moment(props.register?.homeworkDeadline).isSameOrBefore(moment()) &&
      !props.forceMarking
    ) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
          <h1 className="st-alert-message tab-content__container__waiting">
            Please confirm that you will be marking these homeworks by clicking the button below
          </h1>
        </div>
      );
    } /*  else if (props.registerStudent?.homeworkSubmitted && moment(props.register?.homeworkDeadline).isAfter(moment())) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
          <h1 className="st-alert-message tab-content__container__waiting">
            Homework submitted but deadline not yet passed. You will not be able to mark it until [
            {moment(props.register?.homeworkDeadline).format(dateFormat())}]
          </h1>
        </div>
      );
    } */ else if (
      !props.registerStudent?.homeworkSubmitted &&
      moment(props.register?.homeworkDeadline).isAfter(moment())
    ) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
          <h1 className="st-alert-message tab-content__container__waiting">
            Awaiting submission. Deadline is on [{moment(props.register?.homeworkDeadline).format(dateFormat())}
            ].
            {/* You will NOT be able to mark it until then */}
          </h1>
        </div>
      );
    } else if (type === 'upload' && props.registerStudent?.homeworkSubmitted) {
      if (!!props.registerStudent?.error || !!props.register?.error) {
        return (
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <Alert type="attention">
              {props.registerStudent?.error?.replaceAll(/(student)|(,)|(teacher)/g, '') ||
                props.register?.error.replaceAll(/(student)|(,)|(teacher)/g, '')}
            </Alert>
          </div>
        );
      }
      return (
        <div className="tab-content__container" style={{ height: '90%' }}>
          <div className="tab-content__container__upload">
            {/*             {props.registerStudent?.homeworkSubmitted &&
              moment(props.registerStudent.finishedAt).isAfter(moment(props.register?.homeworkDeadline)) && (
                <Alert type="attention">
                  <span className="fw-bolder">
                    Attention! This homework was submitted late. Marking is optional and{' '}
                    <span className="fw-bolder">wont</span> be paid for
                  </span>
                </Alert>
              )} */}
            <div className="download-part">
              <p className="fw-bolder mb-4">Download the questions paper, mark it and upload it</p>
              <Button
                className="st-v1-button-primary"
                onClick={() => {
                  props.onDownloadStudentWorkClicked && props.onDownloadStudentWorkClicked();
                }}
              >
                <IconComponents.DownloadIconComponent fill="white" color="white" />
                <span>Download Student Work</span>
              </Button>
            </div>

            <div className="upload-part">
              <p className="fw-bolder">Already marked the homework?</p>
              <InputFilesUpload
                label="Upload Marked Paper"
                path="homework-submission/markedFiles"
                files={files}
                //required
                isInvalid={filesRequired}
                onChange={(files) => {
                  props.onFilesUploaded && props.onFilesUploaded(files);
                }}
                onDelete={(file) => {
                  props.onFileRemoved && props.onFileRemoved(file);
                }}
                fileTypes="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
              />
              {filesRequired && <p className="text-danger text-center mt-1">*Required</p>}
            </div>
          </div>
        </div>
      );
    }
  }

  //  ---------------------------------------------------------------------------------------------------------------
  return (
    <div className="tab-content__container" style={{ height: '90%' }}>
      <FilePreview
        files={props.files.map(
          (file) =>
            ({
              id: file.id!,
              name: file.name,
              url: getFileUrl(file),
              file: null,
              type: file.mime
            } as File)
        )}
      />
    </div>
  );
}

export type TabContentProps = {
  for?: 'student' | 'teacher';
  filesRequired?: boolean;
  type: 'upload' | 'waiting' | 'question';
  files: UploadedFile[];
  register?: RegisterMappedToViewProp;
  registerStudent?: RegisterStudentMappedToViewProp;
  forceMarking?: boolean;
  onDownloadStudentWorkClicked?: () => void;
  onFilesUploaded: (files: UploadedFile[]) => void;
  onFileRemoved: (fileId: string) => void;
};
