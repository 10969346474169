import studentsIcon from 'app/assets/img/usersIcons/students.png';
import teacherIcon from 'app/assets/img/usersIcons/teachers.png';
import AuthCard from 'app/components/Cards/AuthCard';
import Input from 'app/components/Form/Input';
import { Button } from 'app/components/v1/Button';
import { useGetAccountByEmailMutation } from 'app/services/auth';
import { UserType } from 'app/services/types';
import { useFormValidation } from 'app/utils/hooks/useFormValidation';
import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import UserTypeCard from '../signin/UserTypeCard';

export interface ISignupProps {}

export default function Signup(props: ISignupProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const [getAccountByEmail, { data, isSuccess: isAccountSuccess, isError: isAccountError, isLoading }] =
    useGetAccountByEmailMutation();

  const accountRegistered = true;

  useEffect(() => {
    if (isAccountError) {
      navigate('/signup/accountType');
    }
    if (isAccountSuccess && data?.User.IsParent) {
      navigate('/signup/parent');
    }
  }, [isAccountSuccess, isAccountError]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isAccountSuccess) {
    } else {
      getAccountByEmail({ email });
    }
  };

  useFormValidation();

  return (
    <div className="col-12 col-md-8 col-lg-8 col-xl-5">
      <AuthCard title="Create your account">
        <form className="z-index-1 position-relative needs-validation" noValidate onSubmit={onSubmit}>
          <div className="col-12">
            <Input
              label="Your Email"
              type="email"
              feedback="Please enter a valid email address"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button disabled={isLoading} color="primary" type="submit">
            Continue
          </Button>
        </form>
      </AuthCard>
    </div>
  );
}

export function SelectUserType(props: { type?: 'sign-up' | 'sign-in' }) {
  if (props.type === 'sign-in') {
    return <Navigate to={'/login'} />;
  }
  return (
    <div className="col-12 col-md-10 col-lg-10 col-xl-7">
      <AuthCard
        title="Choose your account type"
        subtitle="Select the type of account you want"
        bgOpacity="bg-opacity-50"
      >
        <div className="d-flex  justify-content-evenly flex-wrap">
          <UserTypeCard
            icon={<img src={studentsIcon} alt="Student/Parent Icon" width={"100px"} />}
            type={`${UserType.Student} / ${UserType.Parent}`}
            url={props.type === 'sign-up' ? `/signup/${UserType.Parent.toLowerCase()}` : `/login`}
          />
          
           <UserTypeCard
            icon={<img src={teacherIcon} alt="Teacher Icon" width={"100px"} />}
            type={UserType.Teacher}
            url={props.type === 'sign-up' ? `/signup/${UserType.Teacher.toLowerCase()}` : `/login`}
          />
        </div>
      </AuthCard>
      <p className="text-center text-primary mt-6">
              Already have an account?{' '}
              <Link to="/login" className="">
                Log In
              </Link>
            </p>
    </div>
  );
}
