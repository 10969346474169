import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IAuthCardProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  bgOpacity?: string;
  icon?: any;
  type?: string;
  onClick?: any;
  classes?: string;
}

export default function AuthCard(props: IAuthCardProps) {
  const { title, subtitle, children, bgOpacity = 'bg-opacity-100', icon, type, onClick, classes } = props;

  return (
    <div className={`card card-body ${bgOpacity} bg-white py-8 px-6 border-0 mb-4 ${classes}`}>
      {type && (
        <div className="d-flex justify-content-center align-items-center mb-4">
          <div>{icon}</div>
          <div>
            <div className="fw-bolder text-body">{type} Account</div>
            <Link onClick={onClick} to="/signup" className="primary">
              Change
            </Link>
          </div>
        </div>
      )}
      <h4 className="text-center fs-2">{title}</h4>
      <p className="mb-8 text-muted text-center m-auto" style={{ maxWidth: '80%' }}>
        {subtitle}
      </p>
      {children}
    </div>
  );
}
