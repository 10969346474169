import {
  ApiResponse,
  FamilyLink,
  FindByEntityIdPRequestQuery,
  FindByEntityIdResponse,
  LinkRequestQuery,
  UnLinkByEntityIdPayload
} from 'app/services/types';
import _ from 'lodash';
import qs from 'qs';
import api from '.';
import { RequestQuery } from './types';

const familyLinkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFamilyLinks: builder.query<ApiResponse<FamilyLink[]>, RequestQuery>({
      providesTags: ['FamilyLinks', 'All'],
      query: (payload) => {
        const query = qs.stringify(payload, {
          encodeValuesOnly: true
        });
        return {
          url: `/api/family-links?${query}`,
          method: 'GET'
        };
      }
    }),
    findByEntityId: builder.query<FindByEntityIdResponse, FindByEntityIdPRequestQuery>({
      providesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'FamilyLinks',
                id: result.data.familyLinkId
              },
              {
                type: 'FamilyLinks',
                id: `owner-${payload.type}-${payload.entityId}`
              },
              ...result.data.parentIds.map((e) => ({
                type: 'FamilyLinks',
                id: `parent-${e}`
              })),
              ...result.data.studentIds.map(
                (e) =>
                  ({
                    type: 'FamilyLinks',
                    id: `student-${e}`
                  } as any)
              )
            ]
          : ['FamilyLinks', 'All'];
      },
      query: (payload) => {
        return {
          url: `/api/family-links/findByEntityId/${payload.type}/${payload.entityId}`,
          method: 'GET'
        };
      }
    }),
    createOrUpdate: builder.mutation<ApiResponse<string | boolean>, LinkRequestQuery>({
      invalidatesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'FamilyLinks',
                id: result
              },
              {
                type: 'FamilyLinks',
                id: `owner-${payload.entityType}-${payload.entityId}`
              },
              ...payload.parentIds.map((e) => ({
                type: 'FamilyLinks',
                id: `parent-${e}`
              })),
              ...(payload.studentIds.map((e) => ({
                type: 'FamilyLinks',
                id: `student-${e}`
              })) as any)
            ]
          : ['FamilyLinks', 'All'];
      },
      query: ({ parentIds, studentIds, entityId, entityType, mergeFamilyLinks }) => {
        return {
          url: `/api/family-links/createOrUpdate`,
          method: 'PUT',
          body: {
            entityId,
            entityType,
            parentIds: parentIds.map((e) => _.toInteger(e)),
            studentIds: studentIds.map((e) => _.toInteger(e)),
            mergeFamilyLinks
          }
        };
      }
    }),
    canUnlinkEntityId: builder.query<ApiResponse, UnLinkByEntityIdPayload>({
      providesTags: (result, error, payload) =>
        result
          ? ([
              { type: 'FamilyLinks', id: `owner-${payload.type}-${payload.entityId}` },
              ...payload.entityIdsToUnlink.map((e) => ({
                type: 'FamilyLinks',
                id: `${payload.entityTypeToUnlink}-${e}`
              }))
            ] as any)
          : ['FamilyLinks', 'All'],
      query: ({ type, entityId, entityTypeToUnlink, entityIdsToUnlink }) => {
        const query = qs.stringify(
          {
            entityTypeToUnlink,
            entityIdsToUnlink
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/family-links/canUnLinkByEntityId/${type}/${entityId}?${query}`,
          method: 'GET'
        };
      }
    }),
    unLinkByEntityId: builder.mutation<ApiResponse, UnLinkByEntityIdPayload>({
      invalidatesTags: (result, error, payload) =>
        result
          ? ([
              { type: 'FamilyLinks', id: `owner-${payload.type}-${payload.entityId}` },
              ...payload.entityIdsToUnlink.map((e) => ({
                type: 'FamilyLinks',
                id: `${payload.entityTypeToUnlink}-${e}`
              }))
            ] as any)
          : ['FamilyLinks', 'All'],
      query: ({ type, entityId, entityTypeToUnlink, entityIdsToUnlink }) => {
        return {
          url: `/api/family-links/unLinkByEntityId/${type}/${entityId}`,
          method: 'PUT',
          body: {
            entityTypeToUnlink,
            entityIdsToUnlink
          }
        };
      }
    }),
    /**
     * @deprecated use link instead
     */
    updateFamilyLinks: builder.mutation<any, any>({
      invalidatesTags: (result, error, payload) => (result ? [{ type: 'FamilyLinks', id: payload.id }] : []),
      query: (payload) => {
        return {
          url: `/api/family-links/${payload.id}`,
          body: {
            data: payload.data
          },
          method: 'PUT'
        };
      }
    })
  })
});

export default familyLinkApi;
