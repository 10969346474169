import { User } from '@sentry/types';
import qs from 'qs';
import api from './index';
import { GetUserResponse, RequestQuery } from './types';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponse, string>({
      providesTags: ['Auth', 'User'],
      query: (id) => {
        const query = qs.stringify(
          {
            populate: {
              admin: true,
              user_types: {
                populate: ''
              },
              student: {
                populate: {
                  year: {
                    populate: ''
                  },
                  parents: {
                    populate: ''
                  },
                  scholarship: {
                    populate: ''
                  },
                  primary_parent: {
                    fields: ['id', 'name', 'phone', 'email', 'addressLine1']
                  }
                }
              },
              parent: {
                populate: ''
              },
              teacher: {
                populate: {
                  photo: true
                }
              },
              additionalPermission: true,
              role: true
            }
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/users/${id}?${query}`,
          method: 'GET'
        };
      }
    }),
    getUsers: builder.query<User[], { query: RequestQuery }>({
      providesTags: ['Auth', 'User'],
      query: (payload) => {
        return {
          url: `/api/users?${qs.stringify(payload.query, { encodeValuesOnly: true })}`,
          method: 'GET'
        };
      }
    }),
    updateUser: builder.mutation<void, { id: string; data: Partial<User> }>({
      invalidatesTags: ['Auth', 'User'],
      query: (payload) => {
        return {
          url: `/api/users/${payload.id}`,
          method: 'PUT',
          body: payload.data
        };
      }
    })
  })
});
export const {useGetUserQuery} = userApi
