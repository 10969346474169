import { ReactComponent as Avatar0 } from 'app/assets/svgs/default-avatar.svg';
import { ReactComponent as Avatar25 } from 'app/assets/svgs/usersAvatars/avatar-02-25.svg';
import { ReactComponent as Avatar1 } from 'app/assets/svgs/usersAvatars/avatar-02_1.svg';
import { ReactComponent as Avatar11 } from 'app/assets/svgs/usersAvatars/avatar-02_11.svg';
import { ReactComponent as Avatar12 } from 'app/assets/svgs/usersAvatars/avatar-02_12.svg';
import { ReactComponent as Avatar14 } from 'app/assets/svgs/usersAvatars/avatar-02_14.svg';
import { ReactComponent as Avatar15 } from 'app/assets/svgs/usersAvatars/avatar-02_15.svg';
import { ReactComponent as Avatar16 } from 'app/assets/svgs/usersAvatars/avatar-02_16.svg';
import { ReactComponent as Avatar17 } from 'app/assets/svgs/usersAvatars/avatar-02_17.svg';
import { ReactComponent as Avatar19 } from 'app/assets/svgs/usersAvatars/avatar-02_19.svg';
import { ReactComponent as Avatar2 } from 'app/assets/svgs/usersAvatars/avatar-02_2.svg';
import { ReactComponent as Avatar20 } from 'app/assets/svgs/usersAvatars/avatar-02_20.svg';
import { ReactComponent as Avatar21 } from 'app/assets/svgs/usersAvatars/avatar-02_21.svg';
import { ReactComponent as Avatar24 } from 'app/assets/svgs/usersAvatars/avatar-02_24.svg';
import { ReactComponent as Avatar4 } from 'app/assets/svgs/usersAvatars/avatar-02_4.svg';
import { ReactComponent as Avatar5 } from 'app/assets/svgs/usersAvatars/avatar-02_5.svg';
import { ReactComponent as Avatar6 } from 'app/assets/svgs/usersAvatars/avatar-02_6.svg';
import { ReactComponent as Avatar7 } from 'app/assets/svgs/usersAvatars/avatar-02_7.svg';
import { ReactComponent as Avatar8 } from 'app/assets/svgs/usersAvatars/avatar-02_8.svg';
import { ReactComponent as Avatar9 } from 'app/assets/svgs/usersAvatars/avatar-02_9.svg';

const avatars = [
  { component: Avatar0, name: '' },
  { component: Avatar1, name: 'Avatar1' },
  { component: Avatar2, name: 'Avatar2' },
  { component: Avatar4, name: 'Avatar4' },
  { component: Avatar5, name: 'Avatar5' },
  { component: Avatar6, name: 'Avatar6' },
  { component: Avatar7, name: 'Avatar7' },
  { component: Avatar8, name: 'Avatar8' },
  { component: Avatar9, name: 'Avatar9' },
  { component: Avatar11, name: 'Avatar11' },
  { component: Avatar12, name: 'Avatar12' },
  { component: Avatar14, name: 'Avatar14' },
  { component: Avatar15, name: 'Avatar15' },
  { component: Avatar16, name: 'Avatar16' },
  { component: Avatar17, name: 'Avatar17' },
  { component: Avatar19, name: 'Avatar19' },
  { component: Avatar20, name: 'Avatar20' },
  { component: Avatar21, name: 'Avatar21' },
  { component: Avatar24, name: 'Avatar24' },
  { component: Avatar25, name: 'Avatar25' }
];

export const avatarsMap = {
  Avatar0,
  Avatar1,
  Avatar2,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar11,
  Avatar12,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar19,
  Avatar20,
  Avatar21,
  Avatar24,
  Avatar25
};

export default avatars;
