export const Theme = {
  colors: {
    primaryColor: '#4C54A0',
    secondaryColor: '#F5F5F5',
    tertiaryColor: '#F5F5F5',
    white: '#ffffff',
    light: '#F7F9FC;',
    transparent: 'transparent'
  },
  textColors: {
    primaryColor: '#4C54A0',
    secondaryColor: '#F5F5F5',
    tertiaryColor: '#F5F5F5',
    white: '#ffffff',
    light: '#92929D;'
  },
  fonts: {
    primaryFont: 'Montserrat',
    secondaryFont: 'Open Sans'
  },
  fontSizes: {
    small: '1.4rem',
    medium: '1.6rem',
    large: '2.4rem',
    xLarge: '3.2rem',
    xxLarge: '4.8rem'
  },
  fontWeight: {
    bold: 700,
    light: 300,
    regular: 400,
    semiBold: 600,
    semiLight: 200,
    semiRegular: 500
  }
};
