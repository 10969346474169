import { Typography as AntdTypography } from 'antd';
import _ from 'lodash';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import styled from 'styled-components';

const TypographyStyled = styled.div<{ type: Type }>`
  ${(props) => {
    if (props.type === 'link')
      return `
    .ant-typography{
      color:#44444f;
      :hover{
        color:#44444f;
        text-decoration: underline;
      }
    }
      
    `;
  }}
`;

const Typography = (props: PropsWithChildren<ITextProps | ILinkProps>) => {
  const style = useMemo(() => {
    return { width: props.width || '150px' };
  }, [props.width]);

  const onClick = useCallback(
    (e: any) => {
      if (props.type === 'link') {
        window.open(props.link);
        e.preventDefault();
        e.stopPropagation();
      }
      props.onClick && props.onClick(e);
    },
    [props.onClick, props.type]
  );

  const children = useMemo(() => {
    return (props as ITextProps).capitalize ? _.capitalize(props.children as string) : props.children;
  }, [props.children, (props as ITextProps).capitalize]);

  const content = useMemo(() => {
    return (
      <AntdTypography.Text
        className={props.className}
        ellipsis={{
          tooltip: children,
          suffix: props.ellipsis?.suffix
        }}
      >
        {children}
      </AntdTypography.Text>
    );
  }, [props.type, children, props.className]);

  return (
    <TypographyStyled className={props.containerClassName} type={props.type} style={style} onClick={onClick}>
      {content}
    </TypographyStyled>
  );
};

export default Typography;

interface IProps {
  type: Type;
  width?: string;
  className?: string;
  containerClassName?: string;
  ellipsis?: {
    suffix?: string;
  };
  onClick?: (event: MouseEvent) => void;
}

interface ITextProps extends IProps {
  type: 'text';
  capitalize?: boolean;
}

interface ILinkProps extends IProps {
  type: 'link';
  link: string;
}

type Type = 'text' | 'link';
