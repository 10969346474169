import { Popover } from 'antd';
import { ReactComponent as CheckerIcon } from 'app/assets/svgs/checker-icon.svg';
import { ReactComponent as ClockIcon } from 'app/assets/svgs/clock-icon.svg';
import { ReactComponent as InfoIcon } from 'app/assets/svgs/info-mark.svg';
import { displayTimeDiffrence } from 'app/utils/utils';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckinLogPopover } from './CheckinLogPopover';

const CheckinStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;

  checkin-popover {
    background: red;
  }

  .details-row {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .time,
    .checking-times {
      color: #44444f;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3px;

      svg {
        width: 15px;
        height: 15px;
        path {
          stroke: #44444f !important;
          stroke-width: 2;
          font-weight: 500;
        }
      }

      .time-text {
        text-wrap: nowrap;
        font-size: 12px;
      }
      .number-text {
        text-wrap: nowrap;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }
`;

const UncheckedStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;

  svg {
    path {
      fill: #fc5a5a;
    }
  }

  span {
    color: #fc5a5a;
  }
`;

interface CheckinLogsProps {
  logs: any;
}

export const CheckinLogCell = (props: CheckinLogsProps) => {
  const [diffrence, setDiffrence] = useState('');

  const updateTime = () => {
    const diff = displayTimeDiffrence(props?.logs?.[props?.logs?.length - 1]?.timestamp);
    setDiffrence(diff);
  };

  useEffect(() => {
    updateTime();
    const interval = setInterval(updateTime, 10000); //every 10 seconds
    return () => clearInterval(interval);
  }, [props?.logs]);

  return (
    <>
      {props?.logs?.length > 0 ? (
        <Popover
          overlayInnerStyle={{ marginRight: 20 }}
          trigger="click"
          title="Check-in Logs"
          content={<CheckinLogPopover logs={props.logs} />}
        >
          <CheckinStyled onClick={(e) => e.stopPropagation()}>
            <span className="name">
              {props?.logs?.[props?.logs?.length - 1]?.admin?.data?.attributes?.name ||
                props?.logs?.[props?.logs?.length - 1]?.admin?.name}
            </span>
            <div className="details-row">
              <div className="time">
                <ClockIcon />
                <span className="time-text">{diffrence}</span>
              </div>
              <div className="checking-times">
                <CheckerIcon />
                <span className="number-text">{props?.logs?.length}</span>
              </div>
            </div>
          </CheckinStyled>
        </Popover>
      ) : (
        <UncheckedStyle>
          <InfoIcon />
          <span>Unchecked</span>
        </UncheckedStyle>
      )}
    </>
  );
};
