import { Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from 'app/components/v1/Button';
import { Errors, FormControl } from 'app/components/v1/Input';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { Note } from './Note';

const NoteComponentStyled = styled.div`
  padding: 0px;
  .notes {
    gap: 16px;
    margin-top: 20px;
  }
  .create-note-container {
    margin-top: 30px;
    width: 100%;
    .note-content {
      width: 100%;
      padding: 20px;
      font-family: var(--st-font-family-primary);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #92929d;
      height: fit-content;
    }
  }
  .close-btn {
    width: 20px;
    height: 20px;
  }
`;

const NoteComponent = (props: NoteComponentProps) => {
  const form = useFormik<NoteComponentFormValue>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      content: yup.string().nullable().trim().required('Please add some text')
    }),
    onSubmit: async (values, e) => {
      props.onSubmit &&
        (await props.onSubmit({
          content: values.content!
        }));
      e.resetForm();
    }
  });

  return (
    <NoteComponentStyled className="d-flex flex-column">
      <div className="a-n-m-header">Add Note</div>
      <div className="d-flex flex-row justify-content-end"></div>
      <div className="d-flex flex-row w-100">
        <FormControl className="create-note-container">
          <TextArea
            className="note-content"
            placeholder="Type a new note here"
            value={form.values.content}
            onChange={(e) => {
              form.setFieldValue('content', e.target.value);
            }}
          />
          {form.errors.content && <Errors errors={{ required: [form.errors.content] }} />}
        </FormControl>
      </div>
      <div className="d-flex flex-row justify-content-end mt-2">
        <Button
          disabled={props.loading}
          onClick={async () => {
            await form.submitForm();
          }}
        >
          {props?.loading ? <Spin size="small" /> : 'Save'}
        </Button>
      </div>
      <div className="notes d-flex flex-column">
        {props?.value?.map((comment) => {
          return (
            <Note
              key={comment.id}
              disabled={props.loading}
              avatar={null!}
              content={comment.content}
              name={comment.name}
              datetime={comment.datetime}
              onSaved={async (content) => {
                props.onSubmit &&
                  props.onSubmit({
                    id: comment.id?.toString(),
                    content
                  });
              }}
              onDelete={async () => {
                props.onDelete && props.onDelete(comment.id?.toString());
              }}
            />
          );
        })}
      </div>
    </NoteComponentStyled>
  );
};

export default NoteComponent;

export type NoteComponentProps = {
  onDelete?: (id: string) => Promise<void>;
  onSubmit?: (value: OnSubmitValue) => Promise<void>;
  value: Value;
  loading?: boolean;
};

export type OnSubmitValue = {
  id?: string;
  content: string;
};

export type Value = {
  id: string;
  content: string;
  name: string;
  datetime: string;
}[];

export type NoteComponentFormValue = {
  content?: string;
};
