import { StatusCount } from 'app/services/types';
import styled from 'styled-components';

const StudentsSubscribedToClassCountsStyled = styled.div``;

export const StudentsSubscribedToClassCounts = (props: StudentsSubscribedToClassCountsProps) => {
  return (
    <StudentsSubscribedToClassCountsStyled className={props.className}>
      <span style={{ fontSize: '12px', color: '#696974' }}>
        {props.statusCounts?.map((e, i) => `${e.total} ${e.name}${props.statusCounts.length - 1 !== i ? ', ' : ''}`)}
      </span>
    </StudentsSubscribedToClassCountsStyled>
  );
};

type StudentsSubscribedToClassCountsProps = {
  statusCounts: StatusCount[];
  className?: string;
};

export default StudentsSubscribedToClassCounts;
