import { Modal } from 'antd';
import { ReactComponent as DoubleArrow } from 'app/assets/svgs/double-arrow-icon.svg';
import { ReactComponent as CallIcon } from 'app/assets/svgs/phone-icon.svg';
import useNote from 'app/components/NoteComponent/useNote';
import { Errors, FormControl, TextArea } from 'app/components/v1/Input';
import { Select } from 'app/components/v1/Select';
import familyLinkApi from 'app/services/family-link';
import { useUpdateRegisterStudentMutation } from 'app/services/register-student';
import { studentApi } from 'app/services/student';
import { CallLogs, Student } from 'app/services/types';
import { MAIN_SELECTORS } from 'app/store';
import { attendanceResponse, callReasons, generalIssueResponse, salesCallResponse } from 'app/utils/consts';
import { getCountries } from 'app/utils/get-countries';
import { displayTimeDiffrence } from 'app/utils/utils';
import { yup } from 'app/utils/validations';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PhoneNumber = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  div {
    width: fit-content !important;
  }
  svg {
    margin-right: 6px;
  }
  path {
    stroke: #2096f3;
  }
  .phone-link {
    color: #000000 !important;
    font-weight: 600;
    font-size: 14px;
  }
`;

const LogsHistory = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  span {
    font-size: 20px;
    font-weight: 600;
  }

  .all-logs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 21rem;
    overflow: scroll;
    padding-top: 10px;
  }
  .log-entry {
    margin-bottom: 2px;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    box-shadow: 0px 0px 4px 0px #00000014;

    .light-text {
      color: #92929d;
      font-weight: 500;
      font-size: 12px;
    }

    .normal-text {
      font-weight: 400;
      font-size: 14px;
    }

    .first-row {
      display: flex;
      flex-direction: row;
      text-wrap: nowrap;
      gap: 9px;

      &.noAnswer {
        path {
          stroke: red;
        }
      }

      svg {
        width: 15px;
        height: 15px;
      }

      path {
        stroke: #3dd598;
      }

      span {
        font-size: 12px;
      }
    }
    .second-row {
      display: flex;
      flex-direction: column;
    }
    .third-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      div {
        text-wrap: nowrap;

        &.response {
          color: #3dd598;
        }

        &.noAnswer {
          color: #ffc542;
        }
      }
    }
    .fourth-row {
      display: flex;
      flex-direction: column;
      .notes-title {
        color: #4c54a0;
        font-weight: 600;
      }
      .notes-button {
        color: #4c54a0;
        background: transparent;
        border: none;
        font-size: 12px;
      }
      .notes-body {
        line-height: 12px;
      }
    }
  }
`;

interface ContactParentModalProps {
  show: boolean;
  setShowCallModal: (x: boolean) => void;
  studentId: number;
  primaryParentId: number;
  relId?: number;
  callLogs: CallLogs[];
  registerId?: number;
  student: Student;
  noteRequired?: boolean;
}
const formatContacts = (
  parents: any,
  primaryParentId: number,
  setDefaultParent: (val: number | undefined) => void,
  form: any,
  student: Student,
  studentId: number
) => {
  const options: any[] = [];
  let index = 1;
  let defaultSet = false;
  if (student?.phone || student?.phone2) {
    options.push({
      label: `${student.name} (Student)`,
      value: index,
      phone: student?.phone,
      phone2: student?.phone2,
      code: getCountries().filter((country) => country.code == student.phoneCountryCode)?.[0]?.dial_code,
      code2: getCountries().filter((country) => country.code == student.phoneCountryCode2)?.[0]?.dial_code,
      contactId: studentId,
      isParent: false,
      name: `${student.name ? student.name : ''} ${student.lastName ? student.lastName : ''}`
    });
    index += 1;
  }
  if (parents?.length > 0 && parents?.[0]?.attributes?.parents?.data?.length > 0) {
    parents?.[0]?.attributes?.parents?.data.map((parent: any) => {
      if (parent.attributes.phone || parent.attributes.phone2) {
        if (primaryParentId == parent.id && defaultSet == false) {
          form.setFieldValue('phone', index);
          setDefaultParent(index);
          defaultSet = true;
        }
        options.push({
          label: `${parent.attributes.name}  ${primaryParentId == parent.id ? '(Default Parent)' : ''}`,
          value: index,
          phone: parent.attributes.phone,
          phone2: parent.attributes.phone2,
          code: getCountries().filter((country) => country.code == parent.attributes.phoneCountryCode)?.[0]?.dial_code,
          code2: getCountries().filter((country) => country.code == parent.attributes.phoneCountryCode2)?.[0]
            ?.dial_code,
          contactId: parent.id,
          isParent: true,
          name: `${parent.name ? parent.name : ''} ${parent.lastName ? parent.lastName : ''}`
        });
      }
      index += 1;
    });
  } else {
    return [];
  }
  return options;
};

export const ContactParentModal = (props: ContactParentModalProps) => {
  const [formattedContacts, setformattedContacts] = useState<any[]>([]);
  const [defaultParent, setDefaultParent] = useState<number | undefined>(undefined);
  const [updateRegisterStudent, { isLoading: loadingUpdate }] = useUpdateRegisterStudentMutation();
  const [updateStudent, { isLoading: studentLoadingUpdate }] = studentApi.useUpdateStudentMutation();
  const user = useSelector(MAIN_SELECTORS.user);
  const [responseOptions, setResponseOptions] = useState(
    props.relId && props.registerId ? attendanceResponse : salesCallResponse
  );
  const [wholeNumber1, setwholeNumber1] = useState<string | undefined>(undefined);
  const [wholeNumber2, setwholeNumber2] = useState<string | undefined>(undefined);
  const { addNote, isLoading: isNotesLoading } = useNote({
    sourceEntityId: user?.entity?.id!,
    targetEntityId: props?.studentId?.toString()!,
    source: 'sales'
  });

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

  const toggleExpanded = (index: number) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };
  const form = useFormik<{
    phone?: number | undefined;
    reason: string;
    response: string | undefined;
    note: string | undefined;
    noteRequired: boolean;
  }>({
    initialValues: {
      phone: defaultParent,
      reason: props.relId && props.registerId ? 'attendance' : 'sales',
      response: undefined,
      note: '',
      noteRequired: props?.noteRequired ?? false
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      phone: yup.number().required('Choose a contact'),
      response: yup.string().required('you have to choose a call response'),
      noteRequired: yup.boolean(),
      note: yup.string().when('noteRequired', (noteRequired, schema) => {
        if (noteRequired?.[0]) return schema.required('you must add a note');
        return schema;
      })
    }),
    onSubmit: async (values) => {
      let callLogs = [];

      const newLog: any = {
        note: values.note,
        admin: user.admin,
        reason: values.reason,
        response: values.response,
        timestamp: new Date()
      };
      const targetContact: any = formattedContacts.filter((contact) => values.phone == contact.value)?.[0];
      if (targetContact?.isParent) {
        newLog.parent = targetContact?.contactId;
      } else {
        newLog.student = targetContact?.contactId;
      }
      if (props.callLogs.length == 0) {
        callLogs.push(newLog);
      } else {
        callLogs = [...props.callLogs, newLog];
      }

      if (props.relId && props.registerId) {
        await updateRegisterStudent({
          id: props?.relId?.toString(),
          registerStudent: {
            callLogs: callLogs,
            registerId: props.registerId
          }
        });
      } else {
        await updateStudent({
          id: props?.studentId?.toString(),
          profileData: {
            callLogs: callLogs
          }
        });
        await addNote(
          `Called: ${targetContact?.isParent ? 'Parent' : 'Student'} ${targetContact?.name} \n Reason: ${
            callReasons.filter((reason) => reason.value == values.reason)?.[0]?.label
          } \n Response: ${
            values.reason == 'attendance'
              ? attendanceResponse.filter((resp) => resp.value == values.response)?.[0]?.label
              : values.reason == 'general'
              ? generalIssueResponse.filter((resp) => resp.value == values.response)?.[0]?.label
              : salesCallResponse.filter((resp) => resp.value == values.response)?.[0]?.label
          } \n Notes: ${values.note} \n Called By:${user?.entity?.name}`
        );
      }

      props.setShowCallModal(false);
      form.resetForm();
    }
  });
  const { data: familyLinks } = familyLinkApi.endpoints.getFamilyLinks.useQuery(
    {
      filters: {
        students: {
          id: {
            $eq: props.studentId
          }
        }
      },
      populate: {
        parents: {
          fields: ['id', 'name', 'phone', 'phone2', 'phoneCountryCode', 'phoneCountryCode2']
        }
      }
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (form.values.reason == 'attendance') {
      setResponseOptions(attendanceResponse);
    } else {
      setResponseOptions(generalIssueResponse);
    }
  }, [form.values.reason]);

  useEffect(() => {
    setformattedContacts(
      formatContacts(familyLinks?.data, props.primaryParentId, setDefaultParent, form, props.student, props.studentId)
    );
  }, [familyLinks, props.student]);

  useEffect(() => {
    const parentInfo = formattedContacts.filter((contact) =>
      form.values.phone ? form.values.phone == contact.value : defaultParent == contact.value
    )[0];
    if (parentInfo?.code && parentInfo?.phone) {
      setwholeNumber1(`${parentInfo?.code}${parentInfo?.phone}`);
    } else {
      setwholeNumber1(undefined);
    }

    if (parentInfo?.code2 && parentInfo?.phone2) {
      setwholeNumber2(`${parentInfo?.code2}${parentInfo?.phone2}`);
    } else {
      setwholeNumber2(undefined);
    }
  }, [form.values.phone]);

  useEffect(() => {
    form.setFieldValue('noteRequired', props?.noteRequired);
  }, [props?.noteRequired]);

  useEffect(() => {
    if (props.relId && props.registerId) {
      setResponseOptions(attendanceResponse);
    } else {
      setResponseOptions(salesCallResponse);
    }
  }, [props.relId, props.registerId]);

  return (
    <Modal
      className="w-50 mx-auto"
      open={props.show}
      onCancel={() => {
        props.setShowCallModal(false);
        form.resetForm();
      }}
      onOk={() => form.submitForm()}
      okText={'Submit'}
      confirmLoading={loadingUpdate || studentLoadingUpdate || isNotesLoading}
    >
      <h3 className="header">New Call</h3>
      <div className="d-flex flex-row gap-10 w-100">
        <div className="d-flex flex-column gap-2 w-50">
          <FormControl>
            <Select
              className="d-flex flex-row justify-content-center "
              name={'Contact'}
              options={formattedContacts}
              value={form.values.phone || defaultParent}
              onChange={(e) => {
                form.setFieldValue('phone', e);
              }}
              allowClear={false}
            />
            {form.errors.phone && <Errors errors={{ required: [form.errors.phone] }} />}
          </FormControl>
          {wholeNumber1 ? (
            <PhoneNumber>
              <a className="phone-link" href={`tel:${wholeNumber1}`}>
                <CallIcon />
                {wholeNumber1}
              </a>
            </PhoneNumber>
          ) : (
            <></>
          )}
          {wholeNumber2 ? (
            <PhoneNumber>
              <a className="phone-link" href={`tel:${wholeNumber2}`}>
                <CallIcon />
                {wholeNumber2}
              </a>
            </PhoneNumber>
          ) : (
            <></>
          )}
          <FormControl>
            <Select
              allowClear={false}
              className="d-flex flex-row justify-content-center  mb-2"
              name={'Call Reason'}
              options={
                props.relId && props.registerId
                  ? callReasons.filter((reason) => reason.value != 'sales')
                  : callReasons.filter((reason) => reason.value == 'sales')
              }
              value={form.values.reason}
              onChange={(value) => form.setFieldValue('reason', value)}
            />
          </FormControl>
          <FormControl>
            <Select
              allowClear={false}
              className="d-flex flex-row justify-content-center "
              name={'Call Response'}
              options={responseOptions}
              value={form.values.response}
              onChange={(value) => form.setFieldValue('response', value)}
            />
            {form.errors.response && <Errors errors={{ required: [form.errors.response] }} />}
          </FormControl>
          <FormControl className="h-100">
            <TextArea
              name="Notes"
              rows={5}
              style={{ resize: 'none', height: '100%' }}
              value={form.values.note}
              onChange={(value) => {
                form.setFieldValue('note', value);
              }}
            />
            {form.errors.note && <Errors errors={{ required: [form.errors.note] }} />}
          </FormControl>
        </div>
        <LogsHistory>
          <span>Logs</span>
          <div className="all-logs">
            {props?.callLogs?.map((log, index) => {
              return (
                <div key={index} className="log-entry">
                  <div className={`first-row ${log.response}`}>
                    <CallIcon />
                    <span className="light-text">Called : </span>
                    {log?.parent?.data ? (
                      <span>
                        {log?.parent?.data?.attributes?.name} {log?.parent?.data?.attributes?.lastName}
                      </span>
                    ) : (
                      <span>
                        {log?.student?.data?.attributes?.name} {log?.student?.data?.attributes?.lastName}
                      </span>
                    )}

                    <span className="light-text">{displayTimeDiffrence(log?.timestamp.toString())}</span>
                  </div>
                  <div className="second-row">
                    <span className="normal-text">{log?.admin?.data?.attributes?.name}</span>
                    <span className="light-text">Checker</span>
                  </div>
                  <div className="third-row">
                    <div>
                      {log.reason == 'attendance'
                        ? '"Student Absent"'
                        : log.reason == 'general'
                        ? '"General Issue"'
                        : '"sales"'}
                    </div>
                    <DoubleArrow />
                    <div className={`response ${log.response}`}>
                      {log.reason == 'attendance'
                        ? `"${attendanceResponse.filter((resp) => resp.value == log.response)?.[0]?.label}"`
                        : log.reason == 'general'
                        ? `"${generalIssueResponse.filter((resp) => resp.value == log.response)?.[0]?.label}"`
                        : `"${salesCallResponse.filter((resp) => resp.value == log.response)?.[0]?.label}"`}
                    </div>
                  </div>
                  {log.note && log.note.length > 0 ? (
                    <div className="fourth-row">
                      <span className="light-text notes-title">Notes</span>
                      <span className="notes-body">
                        <span className="light-text">
                          {expandedIndexes.includes(index)
                            ? log.note
                            : log.note.length > 50
                            ? `${log.note.slice(0, 50)}...`
                            : log.note}
                        </span>
                        {log.note.length > 60 ? (
                          <button className="notes-button" onClick={() => toggleExpanded(index)}>
                            View {expandedIndexes.includes(index) ? 'Less' : 'More'}
                          </button>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </LogsHistory>
      </div>
    </Modal>
  );
};
