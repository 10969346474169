import qs from 'qs';
import api from './index';
import { GetClasseroomResponse, GetClasseroomsResponse, GetQueryPayload, classSummaryResponse } from './types';

export const classApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClassSummary: builder.query<classSummaryResponse, string>({
      providesTags: ['Class'],
      query: (id) => {
        return {
          url: `/api/classes/getSummary/${id}`,
          method: 'GET'
        };
      }
    }),
    getSingleClass: builder.query<any, string>({
      providesTags: ['Class'],
      query: (classId) => {
        const query = qs.stringify(
          {
            populate: ['subject', 'subject.curriculums', 'year', 'teacher']
          },
          {
            encodeValuesOnly: true
          }
        );

        return {
          url: `/api/classes/${classId}?${query}`,
          method: 'GET'
        };
      },
      transformResponse: (response: any) => ({
        ...response?.data?.attributes,
        subject: response?.data?.attributes.subject?.data?.attributes?.name,
        yearGroup: response?.data?.attributes.year?.data?.attributes?.shortName,
        progress: `${response?.data?.attributes.progress}%`,
        teacher: {
          id: response?.data?.attributes?.teacher?.data?.id,
          ...response?.data?.attributes?.teacher?.data?.attributes
        }
      })
    }),
    getClasserooms: builder.query<GetClasseroomsResponse, GetQueryPayload>({
      providesTags: ['Class'],
      query: (payload) => {
        return {
          url: `/api/classes?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    getClasseroomById: builder.query<GetClasseroomResponse, GetQueryPayload & { classId: string }>({
      providesTags: ['Class'],
      query: (payload) => {
        return {
          url: `/api/classes/${payload.classId}?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    updateClassroom: builder.mutation<any, { profileData: any; id: string }>({
      invalidatesTags: ['Class'],
      query: (body) => ({
        url: `/api/classes/${body.id}`,
        method: 'PUT',
        body: { data: body.profileData }
      })
    }),
    createClassroom: builder.mutation<any, { profileData: any }>({
      invalidatesTags: ['Class'],
      query: (body) => ({
        url: '/api/classes',
        method: 'POST',
        body: { data: body.profileData }
      })
    }),

    deleteClassroom: builder.query<any, string>({
      providesTags: ['Class'],
      query: (id) => {
        return {
          url: `/api/classes/${id}`,
          method: 'DELETE'
        };
      }
    }),
  })
});

export const {
  useGetClasseroomsQuery,
  useLazyGetClasseroomsQuery,
  useGetClasseroomByIdQuery,
  useLazyGetClasseroomByIdQuery,
  useDeleteClassroomQuery,
  useUpdateClassroomMutation,
  useLazyDeleteClassroomQuery,
  useCreateClassroomMutation
} = classApi;
