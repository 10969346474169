import { Tooltip } from 'antd';
import { ParentStatuses, StudentStatuses } from 'app/services/types';
import styled from 'styled-components';
import { IconComponents } from '../Icons';
import StatusTitle from './StatusTitle';

const StatusComponentStyled = styled.div`
  border-radius: 5px;
  padding: 6px 16px;
  display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  display: inline-block;
  color: #ffffff;
  height: fit-content;
  text-transform: capitalize;
  width: fit-content;
  text-wrap: nowrap;

  &.icon-status {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 0px;
  }
  &.mark_late,
  &.marked_late {
    background: #4c54a0;
  }
  &.mark {
    background: #50b5ff;
  }
  &.inprogress {
    background: #ffc542;
  }
  &.waiting {
    background: #92929d;
  }
  &.review {
    background: #ffc542;
  }
  &.marked {
    background: #50b5ff;
  }
  &.na {
    background: #c4c4c4;
  }
  &.new {
    background: #50b5ff;
  }
  &.cover {
    background: #50b5ff;
  }
  &.archived {
    background: #c4c4c4;
  }
  &.actionRequired {
    background: #fc5a5a;
  }
  &.invalid {
    background: #c4c4c4;
  }
  &.inProcess {
    background: #ffc542;
  }
  &.postponed {
    background: #45b1b2;
  }
  &.active,
  &.started {
    background: #3dd598;
  }
  &.open {
    background: #3dd598;
  }
  &.inactive {
    background: #92929d;
  }
  &.upcoming {
    background: #50b5ff;
  }
  &.missing {
    background: #fc5a5a;
  }
  &.lesson {
    background: #50b5ff;
  }
  &.incomplete {
    background: #50b5ff;
  }
  &.complete {
    background: #50b5ff;
  }
  &.stuck {
    background: #50b5ff;
  }
  &.coaching {
    background: #ffc542;
  }
  &.meeting {
    background: #4c54a0;
  }
  &.tutorInterview {
    background: #3dd598;
  }
  &.staffInterview {
    background: #2a956a;
  }
  &.client_call {
    background: #fc5a5a;
  }
  &.tutorial {
    background: #45b1b2;
  }
  &.submitted {
    background: #45b1b2;
  }
  &.other {
    background: #92929d;
  }
  &.absent,
  &.completed,
  &.cancelled,
  &.ended {
    background: #f1f1f5;
    color: #44444f;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &.payment-bonus {
    background: #50b5ff;
  }
  &.payment-lesson,
  &.payment-adjustment {
    background: #4c54a0;
  }
  &.payment-marking {
    background: #3dd598;
  }
  &.payment-penalty {
    background: #f1543f;
  }
  &.payment-commission {
    background: #45b1b2;
  }
  &.notStarted {
    background: #f8b64c;
  }
  &.icon-status {
    background: transparent;
    &.incomplete {
      color: #92929d;
    }
    &.complete {
      color: #3dd598;
    }
    &.stuck {
      color: #fc5a5a;
    }
    &.ended {
      color: #44444f;
    }
  }

  &.dot-status {
    background: currentColor;
    width: 10px;
    height: 10px;
  }
`;

export const StatusComponent = (props: StatusComponentProps) => {
  return (
    <StatusComponentStyled
      className={`${props.status} ${props.className ? props.className : ''} ${
        props.as === 'icon' ? 'icon-status' : ''
      }`}
    >
      {props.as === 'icon' && (
        <>
          {props.status === 'complete' ? (
            <IconComponents.MeetingRecordingCompleteIconComponent />
          ) : props.status === 'incomplete' ? (
            <IconComponents.MeetingRecordingInCompleteIconComponent />
          ) : props.status === 'stuck' ? (
            <IconComponents.MeetingRecordingStuckIconComponent />
          ) : (
            ''
          )}
        </>
      )}
      {props.as === 'dot' ? (
        <span className={`dot-status ${props.status?.toLowerCase()}`}></span>
      ) : props.as !== 'icon' ? (
        <>
          {props.icon ? (
            <img
              src={props.icon}
              alt={props.alt}
              style={{ marginTop: '5px', marginRight: '5px' }}
              height="20px"
              className={props.iconClassName}
            />
          ) : (
            <></>
          )}
          <Tooltip title={props.status == 'na' ? 'No homework was set' : undefined}>
            <>
              <StatusTitle status={props.status == 'na' ? 'N/a' : props.status} />
            </>
          </Tooltip>
        </>
      ) : (
        ''
      )}
    </StatusComponentStyled>
  );
};

type StatusComponentProps = {
  status: StudentStatuses | ParentStatuses | string;
  as?: 'icon' | 'badge' | 'dot' | 'icon-text';
  className?: string;
  iconClassName?: string;
  text?: string;
  icon?: any;
  alt?: string;
};

export default StatusComponent;
