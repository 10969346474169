import styled from 'styled-components';
import { Button, ButtonProps } from '../Button';

const TogglerSectionStyled = styled.div``;

export type TogglerSectionProps = React.PropsWithChildren<{
  className?: string;
}>;

export function TogglerSection(props: TogglerSectionProps) {
  return <TogglerSectionStyled {...props}>{props.children}</TogglerSectionStyled>;
}
