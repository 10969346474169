import { Checkbox } from 'antd';
import { useUpdateRegisterStudentMutation } from 'app/services/register-student';

interface CheckerAttendanceProps {
  value: boolean;
  relId: string;
  registerId: number;
  className?: string;
}

export const CheckerAttendanceInput = (props: CheckerAttendanceProps) => {
  const [updateCheckerAttendance, { isLoading }] = useUpdateRegisterStudentMutation();
  return (
    <Checkbox
      className={`checker-attendance ${props.className}`}
      checked={props.value}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={async (value) => {
        await updateCheckerAttendance({
          id: props.relId,
          registerStudent: { hasAttendedByChecker: value.target.checked, registerId: props.registerId }
        });
      }}
      disabled={isLoading}
    />
  );
};
