import qs from 'qs';
import api from '.';
import { GetQueryPayload, GetYearsResponse } from './types';

export const yearsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getYears: builder.query<GetYearsResponse, GetQueryPayload>({
      query: (payload) => {
        const reqQuery: any = {
          ...(payload.requestQuery ? qs.parse(payload.requestQuery) : {}),
          pagination: { pageSize: 25 }
        };
        return {
          url: `/api/years?${qs.stringify({ sort: ['order:asc'], ...reqQuery }) || ''}`,
          method: 'GET'
        };
      }
    })
  })
});
