import Spinner from 'app/components/Spinner';
import { useAuth } from 'app/utils/hooks/useAuth';
import { useEffect } from 'react';

function GuestAuth({ children }: { children: JSX.Element }) {
  const { userData, navigateToDashboard, loading } = useAuth();
  useEffect(() => {
    if (userData) {
      navigateToDashboard(userData.user_types?.at(0)?.name?.toLocaleLowerCase() || '');
    }
  });
  if (loading) {
    return <Spinner />;
  }
  return children;
}

export default GuestAuth;
