import * as React from 'react';
import Modal from 'react-modal';
// import { ReactComponent as SheildIcon } from "app/assets/sheild.svg";
// import { ReactComponent as SheildClickedIcon } from "app/assets/sheild2.svg";
import { ReactComponent as ChevronLeft } from 'app/assets/svgs/ic_Chevron Left.svg';
import InputSelectMultiple from 'app/components/Form/InputSelectMultiple';
import { Button } from 'app/components/v1/Button';
import { Errors, FormControl, TextArea } from 'app/components/v1/Input';
import { issueReasonApi } from 'app/services/issue-reason';
import { registersApi } from 'app/services/registers';
import { useFormik } from 'formik';
import qs from 'qs';
import styled from 'styled-components';

const TeacherNotesStyled = styled.div``;

export default function TeacherNotes(props: TeacherNotesProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { data: registerData, refetch } = registersApi.endpoints.getRegister.useQuery(
    {
      registerId: props.registerId,
      populate: ['issue_reasons']
    },
    { skip: !props.registerId || !isOpen }
  );

  const { data: issuesData } = issueReasonApi.endpoints.getIssueReasonsRegisterStudent.useQuery(
    {
      requestQuery: qs.stringify(
        {
          filters: {
            group: 'class'
          }
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    { skip: !props.registerId || !isOpen }
  );

  const [updateRegister] = registersApi.endpoints.updateRegister.useMutation();

  const formik = useFormik<FormikValues>({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      await updateRegister({
        registerId: props.registerId,
        body: {
          issue_reasons: values?.issuesIds?.map((e) => e?.value.toString()),
          issueDescription: values.description
        }
      });
      setIsOpen(false);
      formik.resetForm();
    }
  });

  React.useEffect(() => {
    if (!registerData || !isOpen) return;
    formik.setValues(
      {
        description: registerData?.data?.attributes?.issueDescription,
        issuesIds: registerData?.data?.attributes?.issue_reasons?.data?.map((e) => ({
          label: e.attributes?.name,
          value: e.id?.toString()
        }))
      },
      false
    );
  }, [registerData, isOpen]);

  React.useEffect(() => {
    if (!refetch || !isOpen) return;
    refetch();
  }, [isOpen]);

  return (
    <TeacherNotesStyled className="w-100">
      {props.portal == 'teacher' && (
        <button
          style={{ width: '150px' }}
          onClick={() => {
            setIsOpen(true);
          }}
          className="px-2 btn btn-outline-primary d-inline-flex align-items-center justify-content-center rounded-3"
        >
          <p className="m-0 fs-6 d-flex justify-content-center">Contact Support</p>
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        contentLabel="Example Modal"
        className="aside card card-body py-8 px-6 px-sm-8 border-0 mb-4 d-flex flex-column"
      >
        <div className="d-flex align-items-center mb-6">
          <ChevronLeft
            className="ms-n3 mt-2"
            style={{ cursor: 'pointer' }}
            width={30}
            height={30}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <h6 className="fs-4">Contact Support</h6>
        </div>
        <div className="bg-light bg-opacity-10 w-100 rounded-3 p-3 mb-4">
          <FormControl>
            <InputSelectMultiple
              label="Issue"
              value={formik?.values?.issuesIds as any}
              options={
                issuesData?.data?.map((e) => ({
                  label: e.attributes?.name,
                  value: e?.id?.toString()
                })) || []
              }
              onChange={(e) => {
                formik.setFieldValue('issuesIds', e);
              }}
            />
            {formik?.errors?.issuesIds && <Errors errors={{ required: [formik?.errors?.issuesIds] }} />}
          </FormControl>
          <FormControl className="mt-3">
            <TextArea
              style={{ minHeight: '100px' }}
              value={formik.values?.description}
              onChange={(e) => formik.setFieldValue('description', e)}
              name="Description"
            />
            {formik?.errors?.description && <Errors errors={{ required: [formik?.errors?.description] }} />}
          </FormControl>
        </div>
        <div className="d-flex w-100 justify-content-end">
          <Button
            buttonType="outlined"
            color="primary"
            className="width-140 fw-bolder me-2"
            onClick={async () => {
              await formik.resetForm();
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await formik.submitForm();
            }}
            buttonType="outlined"
            color="primary"
            className="width-140 fw-bolder"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </TeacherNotesStyled>
  );
}

type TeacherNotesProps = {
  registerId: string;
  portal?: string;
};

type FormikValues = {
  issuesIds?: Array<{ label: string; value: string }>;
  description?: string;
};
