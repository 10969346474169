import { Select as AntSelect, SelectProps, Tag } from 'antd';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

type TagRender = SelectProps['tagRender'];

const tagRender: TagRender = (props: any) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={props.tagColor ?? '#4c54a0'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4, borderRadius: '20px', padding: '5px 15px', display: 'flex' }}
    >
      {label}
    </Tag>
  );
};

const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  & > div {
    width: 100%;
    min-width: 80px;
  }
  .ant-select {
    width: 100%;
    // height: 48px;
    text-align: start;

    &.ant-select-disabled {
      .ant-select-selector {
        border-color: #d3d3d8 !important;
      }
    }
  }

  .ant-select-selector {
    // max-height: 50px;
    overflow: hidden;
    border-radius: 12px;
  }
  .ant-select-disabled {
    .ant-select-selector {
      background: transparent !important;
      border-color: #4c54a0 !important;
      box-shadow: none !important;
    }
  }
  .ant-tag {
    margin: 2px !important;
  }
`;

const LabelStyled = styled.label`
  position: absolute;
  background-color: white;
  border-radius: 14px;
  top: 12px;
  left: 14px;
  color: #b5b5be;
  transition: top 100ms ease-in-out, left 100ms ease-in-out;
  user-select: none;
  &.active {
    top: -8px;
    left: 0.875rem;
    font-size: 12px;
    color: #4c54a0;
    &.multiple {
      top: -12px;
    }
  }
  &.disabled {
    color: #b5b5be;
  }
`;

export function Select(
  props: React.PropsWithChildren<
    SelectProps & { name?: string; containerclass?: string; suffixIcon?: any; tagColor?: string }
  >
) {
  const [focused, setFocused] = useState<boolean>(false);

  const antSelectContainerRef = useRef<any>();

  const antSelectRef = useRef<any>();

  const inputId = useMemo(() => {
    return _.uniqueId();
  }, []);

  const isLabelActive = useMemo(() => {
    return focused || props.value;
  }, [props.value, focused, props.onBlur, props.onFocus, props.onChange]);

  const handleOnFocus = useCallback(
    (e: any) => {
      setFocused(true);
      props.onFocus && props.onFocus(e);
    },
    [props.onFocus, props.onChange]
  );

  const handleOnBlur = useCallback(
    (e: any) => {
      setFocused(false);
      props.onBlur && props.onBlur(e);
    },
    [props.onBlur, props.onChange]
  );

  const openSelectOnLabelClicked = useCallback(() => {
    // check https://github.com/ant-design/ant-design/issues/22074#issuecomment-603735566
    const antSelectSelectorElement: HTMLDivElement =
      antSelectContainerRef.current?.querySelector('.ant-select-selector');
    const clickEvent = document.createEvent('MouseEvents');
    clickEvent.initEvent('mousedown', true, true);
    antSelectSelectorElement.dispatchEvent(clickEvent);
    antSelectRef.current.focus();
  }, [antSelectContainerRef, antSelectRef]);

  return (
    <ContainerStyled>
      <div className={props.containerclass} ref={antSelectContainerRef}>
        <AntSelect
          ref={antSelectRef}
          allowClear
          popupMatchSelectWidth={false}
          {...props}
          suffixIcon={props.suffixIcon ?? null}
          className={`${props.mode === 'multiple' ? 'st-v1-multiple' : ''} ${props.className || ''} `}
          id={inputId}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          tagRender={tagRender ? (e) => tagRender({ ...e, tagColor: props.tagColor } as any) : null!}
        >
          {props.children}
        </AntSelect>
      </div>

      {props.name && (
        <LabelStyled
          className={`${isLabelActive ? 'active' : ''} ${props.mode === 'multiple' ? 'multiple' : ''} ${
            props.disabled ? 'disabled' : ''
          }`}
          onClick={openSelectOnLabelClicked}
        >
          {props.name}
        </LabelStyled>
      )}
    </ContainerStyled>
  );
}
