import styled from 'styled-components';

const DotStyled = styled.div`
  display: inline-block;
  margin: 0px 5px;
  color: currentColor;
  font-size: 14px;
  font-weight: 700;
  line-height: 0px;
  height: 2px;
`;

const Dot = (props: IProps) => {
  return <DotStyled className={props.className}>&bull;</DotStyled>;
};

interface IProps {
  className?: string;
}

export default Dot;
