import { humanize, toTitleCase, transform } from '@alduino/humanizer/string';
import { Modal, message } from 'antd';
import { ReactComponent as BackIcon } from 'app/assets/svgs/back-icon.svg';
import DateText from 'app/components/DateText/DateText';
import { Timezone } from 'app/components/DateText/Timezone';
import { PageHeader } from 'app/components/PageHeader';
import StatusComponent from 'app/components/StatusTitle/StatusComponent';
import Dash from 'app/components/Typography/Dash';
import Dot from 'app/components/Typography/Dot';
import Typography from 'app/components/Typography/Typography';
import { Button } from 'app/components/v1/Button';
import SubjectTabNavItem from 'app/features/subjects/components/SubjectTabNavItem';
import { registerStudentApi } from 'app/services/register-student';
import { UserType } from 'app/services/types';
import { MAIN_SELECTORS, lessonSlice } from 'app/store';
import { utcToLocalDateTime } from 'app/utils/utils';
import _ from 'lodash';
import { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SubmittingStateContext } from '..';
import TeacherNotes from './TeacherNotes';

const LessonHeaderStyled = styled.div``;
const SubmitButtonStyled = styled.div`
  .submit-lesson-button {
    font-size: 14px;
    font-weight: 500;
    font-family: Montserrat;
  }
`;
const SubmitAttendanceModalStyled = styled.div`
  .submitting-lesson-question {
    color: #171725;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  .submitting-lesson-description {
    margin: 0;
    padding: 0;
    margin-top: 30px;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    .cannot {
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
`;

const BackButton = styled(BackIcon)`
  margin: 20px 20px 0 0;
  padding: 0;
  cursor: pointer;
`;

export interface ILessonHeaderProps {
  isSubmittingLesson: boolean;
  setIsSubmittingLesson: React.Dispatch<React.SetStateAction<boolean>>;
  userType?: UserType;
  errors?: any;
  setErrors?: any;
}

export default function LessonHeader(props: ILessonHeaderProps) {
  const { lessonData, disabled, errors, values } = useContext(SubmittingStateContext);
  const navigate = useNavigate();
  const user = useSelector(MAIN_SELECTORS.user);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isSubmittingLesson, setIsSubmittingLesson } = props;
  const [updateRegisteredStudents, { isLoading: isUpdating }] =
    registerStudentApi.endpoints.updateRegisteredStudents.useMutation();

  const classData: any = lessonData?.data?.attributes?.class?.data?.attributes;
  const submitLesson = async () => {
    setSubmittingModelOpened(false);
    setIsSubmittingLesson(true);
    const data = values?.registeredStudents
      ?.filter(
        (e: any) =>
          lessonData?.data?.attributes?.register_students?.data?.find(
            (x: any) => x.id?.toString() === e.registerStudentId?.toString()
          ) //&& e.selected
      )
      .map((studentData: any) => {
        const fetchedRegister = lessonData?.data?.attributes?.register_students?.data?.find(
          (x: any) => x.id?.toString() === studentData.registerStudentId?.toString()
        );
        const mapped: any = {
          id: _.toNumber(studentData.registerStudentId),
          engagement: studentData.engagement,
          understanding: studentData.understanding,
          issueDescription: studentData.issueDescription,
          starStudent: studentData.starStudent,
          hasAttended:
            studentData.attendance === 'attended' ||
            studentData.attendance === 'late' ||
            studentData.attendance === 'leftEarly' ||
            studentData.attendance === 'leftEarly&Late',
          isLate: studentData.attendance === 'late' || studentData.attendance === 'leftEarly&Late',
          hasLeftEarly: studentData.attendance === 'leftEarly' || studentData.attendance === 'leftEarly&Late',
          issueSendToParent: studentData.issueSendToParent,
          issueStatus: fetchedRegister?.attributes?.issueStatus,
          issue_reasons: studentData.issueReasons?.map((issueReason: any) => issueReason.value.toString()) || [],
          attendanceMarkedByAdmin: user.userType == UserType.Admin.toLowerCase()
        };

        return mapped;
      });
    await updateRegisteredStudents({
      data,
      attendanceDetails: {
        registerId: _.toNumber(lessonData?.data?.id),
        teacherId:
          user.userType == UserType.Admin.toLowerCase()
            ? _.toNumber(lessonData?.data?.attributes?.teacher?.data?.id)
            : _.toNumber(user?.entity?.id)
      },
      classTopics: values.classTopics
    });

    dispatch(
      lessonSlice.actions.registerStudentsSaved({
        isSaved: true
      } as any)
    );
    setIsSubmittingLesson(false);

    message.success('Lesson is saved successfully');
  };
  const [submittingModalOpened, setSubmittingModelOpened] = useState<boolean>(false);

  return (
    <LessonHeaderStyled className="bg-light px-2 px-sm-4 pt-2">
      <PageHeader
        title={`${classData?.classId} ${classData?.year?.data?.attributes?.shortName} ${classData?.subject?.data?.attributes?.name} ${lessonData?.data.attributes.classDate} - Register`}
      />
      <div className="col-lg-12 pt-2">
        <div className="card position-relative border-0" style={{ borderRadius: '0', overflow: 'hidden' }}>
          {lessonData?.data.attributes.recordingLink ? (
            <ReactPlayer
              width={'100%'}
              style={{
                borderRadius: '0'
              }}
              url={lessonData?.data.attributes.recordingLink}
            />
          ) : (
            <div className="w-100 h-100 d-flex justify-content-center p-10 fs-2">No video</div>
          )}
          <div className="px-4 py-6" style={{ borderRadius: '0' }}>
            <div className="d-flex">
              <BackButton
                className="col-auto"
                onClick={() => {
                  window.history.back();
                }}
              />
              <div className="row justify-content-between" style={{ width: '100%' }}>
                <div className="d-flex flex-row col">
                  <div style={{ width: '90%' }}>
                    <h5 className="mb-0 fw-bolder fs-3 d-flex align-items-center">
                      {classData?.year?.data?.attributes?.shortName} {classData?.subject?.data?.attributes?.name}{' '}
                      <Dot />
                      {user.userType == UserType.Teacher.toLowerCase() ? (
                        classData?.lessonAbility ? (
                          <>
                            {transform(humanize(String(classData.lessonAbility)), toTitleCase)} <Dot />
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <DateText
                        date={utcToLocalDateTime(
                          lessonData?.data.attributes.classDate!,
                          lessonData?.data.attributes.startTime
                        ).toISOString()}
                        format={'DD_MMM_YYYY'}
                      />
                      <Dot />
                      <DateText
                        date={utcToLocalDateTime(
                          lessonData?.data.attributes.classDate!,
                          lessonData?.data.attributes.startTime
                        ).toISOString()}
                        format={'day'}
                      />
                    </h5>
                    <div className="mb-0 d-flex align-items-center gap-2">
                      {classData?.classId} <Dot />
                      <span className="text-muted d-flex align-items-center">
                        <DateText
                          date={utcToLocalDateTime(
                            lessonData?.data.attributes.classDate!,
                            lessonData?.data.attributes.startTime
                          ).toISOString()}
                          format={'time'}
                        />
                        <Dash />
                        <DateText
                          date={utcToLocalDateTime(
                            lessonData?.data.attributes.classDate!,
                            lessonData?.data.attributes.endTime
                          ).toISOString()}
                          format={'time'}
                        />{' '}
                        (<Timezone />) <Dot />
                      </span>
                      <Typography type="text" width="200px">
                        <span className="text-primary fw-bolder">
                          {lessonData?.data?.attributes?.teacher?.data?.attributes?.name}{' '}
                          {lessonData?.data?.attributes?.teacher?.data?.attributes?.lastName}
                        </span>
                      </Typography>
                    </div>
                    <StatusComponent status={lessonData?.data?.attributes?.status!} />
                  </div>
                </div>
                {/*start of second column*/}
                <div className="col-auto">
                  <div
                    className={`d-flex ${
                      user.userType == UserType.Admin.toLowerCase() ? 'justify-content-around' : ''
                    } align-items-center gap-2`}
                  >
                    <div>
                      <TeacherNotes portal={user.userType} registerId={lessonData?.data?.id?.toString()!} />
                    </div>

                    <SubmitButtonStyled>
                      <Button
                        type="button"
                        className="px-7 submit-lesson-button py-2 d-inline-block"
                        color="primary"
                        name={lessonData?.data?.attributes?.attendanceMarked ? 'Lesson Submitted' : 'Submit Lesson'}
                        onClick={() => {
                          setSubmittingModelOpened(true);
                        }}
                        loading={isSubmittingLesson || isUpdating}
                        disabled={
                          !!Object.keys(props?.errors || {}).length ||
                          disabled ||
                          !!Object.keys(errors || {}).length ||
                          isUpdating ||
                          isSubmittingLesson ||
                          submittingModalOpened
                        }
                      />
                    </SubmitButtonStyled>
                    <Modal
                      open={submittingModalOpened}
                      closable={false}
                      width={'535px'}
                      footer={
                        !lessonData?.data?.attributes?.teacher.data ? (
                          <div className="d-flex justify-content-end w-100">
                            <Button
                              className="w-25 ml-auto"
                              type="button"
                              color="primary"
                              name="ok"
                              onClick={() => {
                                setSubmittingModelOpened(false);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="d-flex flex-row">
                            <Button
                              className="col"
                              type="button"
                              color="secondary"
                              name="Cancel"
                              onClick={() => {
                                setSubmittingModelOpened(false);
                              }}
                            />
                            <Button
                              className="col"
                              type="button"
                              color="primary"
                              name="Submit"
                              onClick={submitLesson}
                              loading={isSubmittingLesson || isUpdating}
                              disabled={isSubmittingLesson || isUpdating}
                            />
                          </div>
                        )
                      }
                    >
                      {!lessonData?.data?.attributes?.teacher.data ? (
                        <SubmitAttendanceModalStyled>
                          <p className="submitting-lesson-question mb-3 mx-4">
                            You are not linked to the lesson, so you can not mark it as complete.
                          </p>
                        </SubmitAttendanceModalStyled>
                      ) : (
                        <SubmitAttendanceModalStyled>
                          <p className="submitting-lesson-question">Are you sure you want to submit the attendance?</p>
                          <p className="submitting-lesson-description">
                            Please confirm you have marked all the attendance and any other information needed. Once you
                            have submitted the attendance, you <span className="cannot">cannot</span> undo.
                          </p>
                        </SubmitAttendanceModalStyled>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            </div>

            {/*  end of second column*/}
          </div>
        </div>
        <ul className="nav bg-body nav-overflow nav-tabs">
          <SubjectTabNavItem link={'register'} title="Register" wrapperClasses="ps-2 ps-sm-4" />
          <SubjectTabNavItem link={`curriculum`} title="Curriculum" />
          <SubjectTabNavItem link={`material`} title="Material" />
        </ul>
      </div>
    </LessonHeaderStyled>
  );
}
