import { ReactComponent as CallIcon } from 'app/assets/svgs/phone-icon.svg';
import { Button } from 'app/components/v1/Button';
import { CallLogs, Student } from 'app/services/types';
import { useState } from 'react';
import { ContactParentModal } from './ContactParentModal';

interface ContactParentButtonProps {
  studentId: number;
  primaryParentId: number;
  relId?: number;
  callLogs: CallLogs[];
  registerId?: number;
  student: Student;
  className?: string;
  noteRequired?: boolean;
}

export const ContactParentButton = (props: ContactParentButtonProps) => {
  const [showCallModal, setShowCallModal] = useState(false);
  return (
    <>
      <Button
        type="button"
        style={{ backgroundColor: '#2096F3', marginInline: 'auto' }}
        onClick={() => setShowCallModal(true)}
        className={props?.className}
      >
        <CallIcon />
        Contact
      </Button>
      <ContactParentModal
        show={showCallModal}
        setShowCallModal={setShowCallModal}
        studentId={props.studentId}
        student={props.student}
        primaryParentId={props.primaryParentId}
        relId={props.relId}
        callLogs={props.callLogs}
        registerId={props.registerId}
        noteRequired={props.noteRequired}
      />
    </>
  );
};
