import api from './index';
import { Account, CreateAccountRequest, CreateAccountResponse, GetAccountByTokenResponse } from './types';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountByEmail: builder.mutation<Account, { email: string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (email) => ({
        url: `/api/account/getAccountByEmail`,
        method: 'POST',
        body: email
      })
    }),
    getAccountByToken: builder.query<GetAccountByTokenResponse, { signupToken: string }>({
      query: (token) => ({
        url: `/api/account/getAccountByToken`,
        method: 'POST',
        body: token
      })
    }),
    getAccountByPasswordResetToken: builder.mutation<any, { passwordResetToken: string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (passwordResetToken) => ({
        url: `/api/account/getAccountByPasswordResetToken`,
        method: 'POST',
        body: passwordResetToken
      })
    }),
    createAccount: builder.mutation<CreateAccountResponse, CreateAccountRequest>({
      invalidatesTags: ['Auth', 'User'],
      query: (body) => ({
        url: `/api/account/create`,
        method: 'POST',
        body: { ...body }
      })
    }),
    refreshToken: builder.mutation<any, string>({
      invalidatesTags: ['Auth', 'User'],
      query: (token) => ({
        url: `/api/token/refresh`,
        method: 'POST',
        body: { refreshToken: token }
      })
    }),
    passwordReset: builder.mutation<any, { email: string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (body) => ({
        url: `/api/account/passwordReset`,
        method: 'POST',
        body
      })
    }),
    createPassword: builder.mutation<any, { password: string; passwordResetToken: string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (body) => ({
        url: `/api/account/passwordChange`,
        method: 'POST',
        body
      })
    }),
    login: builder.mutation<any, { identifier: string; password: string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (body) => ({
        url: `/api/auth/local`,
        method: 'POST',
        body
      })
    }),
    verifyRecaptcha: builder.mutation<any, { userResponseToken: string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (body) => ({
        url: `/api/account/recaptchaVerification`,
        method: 'POST',
        body
      })
    }),
    sendEmailConfirmation: builder.mutation<any, { email: string, name:string, lastName?:string }>({
      invalidatesTags: ['Auth', 'User'],
      query: (body) => ({
        url: `/api/account/sendConfirmationEmail`,
        method: 'POST',
        body
      })
    }),
    confirmEmail: builder.mutation<any, { confirmation: string }>({ 
      invalidatesTags: ['Auth', 'User'],
      query: ({confirmation}) => ({
        url: `/api/auth/email-confirmation?confirmation=${confirmation}`, 
        method: 'GET',
      })
    }),
  })
});

export const {
  useGetAccountByEmailMutation,
  useGetAccountByPasswordResetTokenMutation,
  useCreateAccountMutation,
  usePasswordResetMutation,
  useCreatePasswordMutation,
  useLoginMutation,
  useVerifyRecaptchaMutation,
  useRefreshTokenMutation,
  useSendEmailConfirmationMutation,
  useConfirmEmailMutation
} = authApi;
