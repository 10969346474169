import { NavLink } from 'react-router-dom';

export interface ISubjectTabNavItemProps {
  title: string;
  link: string;
  wrapperClasses?: string;
  activeClass?: 'active' | 'activePrimary';
}

export default function SubjectTabNavItem(props: ISubjectTabNavItemProps) {
  const { title, link, wrapperClasses, activeClass = 'active' } = props;
  return (
    <li className={`nav-item d-flex ${wrapperClasses}`}>
      <NavLink
        to={link}
        replace
        className={({ isActive }) =>
          isActive
            ? `nav-link ${activeClass} text-truncate text-body fw-bolder px-4`
            : `nav-link text-truncate text-mute px-4`
        }
      >
        {title}
      </NavLink>
    </li>
  );
}
