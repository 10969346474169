import styled from 'styled-components';

const StatusDotComponentStyled = styled.div`
  display: inline-block;
  background: #b5b5be;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &.active {
    background: #82c43c;
  }
  &.active-2 {
    background: #4c54a0;
  }
  &.inprogress {
    background: orange;
  }
  &.inactive {
    background: #b5b5be;
  }
`;

export function StatusDotComponent(props: {
  className?: string;
  type: 'active' | 'active-2' | 'inprogress' | 'inactive';
}) {
  return <StatusDotComponentStyled className={`${props.className} ${props.type}`}></StatusDotComponentStyled>;
}
