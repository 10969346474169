import api from '.';
import { GetQueryPayload, GetSubjectsResponse } from './types';

export const subjectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubjects: builder.query<GetSubjectsResponse, GetQueryPayload>({
      providesTags: ['Subject'],
      query: (payload) => ({
        url: `/api/subjects?${payload.requestQuery || ''}`,
        method: 'GET'
      })
    })
  })
});
