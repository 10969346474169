import { Empty } from 'antd';
import { ReactComponent as DownArrow } from 'app/assets/svgs/ic_Chevron Down.svg';
import { ReactComponent as UpArrow } from 'app/assets/svgs/ic_Chevron Up.svg';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Column, HeaderGroup, useTable } from 'react-table';
import styled from 'styled-components';
import Container from '../Container/Container';
const TableStyled = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  .ct-table-headers {
    background: #fff;
    th {
      cursor: pointer;
      & > [role='columnheader'] {
        display: flex;
        color: #92929d;
        font-size: 14px;
        font-weight: 700;
        align-items: center;
      }
      .ct-table-header-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    th:first-child {
      justify-content: flex-start;
      text-align: start;
      padding-left: 30px;
    }
  }

  tr {
    padding: 10px;
    position: relative;
    td > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    td:first-child > div {
      justify-content: flex-start;
      padding-left: 30px;
    }
    ::before {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: black;
      opacity: 0;
      :hover {
        opacity: 0.1;
      }
    }
  }
`;
export function Table(props: PropsWithChildren<IProps<any>>) {
  const columns = useMemo<Column<any>[]>(
    () =>
      props.columns.map((e) => ({
        Cell: e.cell,
        id: e.id,
        accessor: e.rowDataAccessor,
        Header: e.name,
        sortable: e.sortable,
        headerClassName: e.headerClassName,
        sortAccessor: e.sortAccessor
      })),
    [props.columns, props.rows]
  );
  const { getTableProps, getTableBodyProps, rows, headers, prepareRow, headerGroups } = useTable({
    columns,
    data: props.rows
  });

  const [sortedByColumnId, setSortedByColumnId] = useState<{
    dir: 'asc' | 'desc' | undefined;
    column: TableColumn;
  }>();

  const onHeaderClicked = useCallback(
    (header: HeaderGroup<any>) => {
      const sortPayload: any = { ...sortedByColumnId };
      const column = props.columns.find((e) => e.id === header.id);
      if (!column?.sortable) return;
      if (column?.id === sortedByColumnId?.column?.id) {
        if (sortedByColumnId?.dir === 'asc') {
          sortPayload.dir = 'desc';
        } else {
          sortPayload.dir = 'asc';
        }
      } else {
        sortPayload.column = { ...column };
        sortPayload.dir = 'desc';
      }
      setSortedByColumnId(sortPayload);
      props.onSorting && props.onSorting(sortPayload.dir, sortPayload.column!);
    },
    [sortedByColumnId, props.onSorting, props.columns]
  );

  useEffect(() => {
    const column = props.columns.find((e) => e.id === props.defaultSortedColumnId)!;
    setSortedByColumnId({ column, dir: props.defaultSortDir });
  }, [props.defaultSortedColumnId, props.defaultSortDir]);

  if (props.rows?.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <Container className="overflow-auto" isLoading={props.isLoading}>
      <TableStyled {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup?.id}-${i}`} className="ct-table-headers">
              {headerGroup.headers.map((header, c: any) => (
                <th key={`${header.id}-${c}`} onClick={() => onHeaderClicked(header)} className="ct-table-header">
                  <div {...header.getHeaderProps()}>
                    <div className={`ct-table-header-container ${(header as any).headerClassName}`}>
                      <> {header.Header}</>
                      {(header as any).sortable && (
                        <div>
                          <div className="d-flex flex-column ">
                            <UpArrow
                              className={
                                header?.id === sortedByColumnId?.column?.id && sortedByColumnId?.dir == 'asc'
                                  ? 'up-arrow active-arrow'
                                  : 'up-arrow'
                              }
                            />
                            <DownArrow
                              className={
                                header?.id === sortedByColumnId?.column?.id && sortedByColumnId?.dir == 'desc'
                                  ? 'down-arrow active-arrow'
                                  : 'down-arrow'
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: any) => {
            prepareRow(row);

            return (
              <tr
                {...row.getRowProps()}
                key={`${row.id}-${i}`}
                className="ct-table-row"
                onClick={async (e) => {
                  await props.onRowClicked(row?.original?.id!, e);
                }}
              >
                {row.cells.map((cell: any, o: any) => {
                  return (
                    <td {...cell.getCellProps()} key={`${cell.id}-${o}`}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableStyled>
    </Container>
  );
}

export interface IProps<TRows extends object = {}> {
  columns: TableColumn[];
  rows: TRows[];
  defaultSortedColumnId: string;
  defaultSortDir: 'asc' | 'desc';
  className?: string;
  isLoading?: boolean;
  onSorting: (dir: 'asc' | 'desc', column: TableColumn) => Promise<void>;
  onRowClicked: (row: any, e: any) => Promise<void>;
}

export interface TableColumn {
  id: string;
  name: any;
  cell: (e: any) => any;
  rowDataAccessor: any;
  className?: string;
  sortAccessor?: string;
  sortable?: boolean;
  headerClassName?: string;
}
