import { DatePicker as AntdDatePacker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { CSSProperties, useMemo } from 'react';
import { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import { Formats } from '../DateText/DateText';
import { Errors } from './Input';
const LabelStyled = styled.label`
  position: absolute;
  background-color: white;
  border-radius: 14px;
  top: 12px;
  left: 14px;
  color: #b5b5be;
  transition: top 100ms ease-in-out, left 100ms ease-in-out;
  user-select: none;
  &.active {
    top: -8px;
    left: 0.875rem;
    font-size: 12px;
    color: #4c54a0;
  }
  &.disabled {
    top: -8px;
    left: 0.875rem;
    font-size: 12px;
    color: #b5b5be;
  }
`;

const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    width: 100%;
    min-width: 80px;
  }
  .ant-select {
    width: 100%;
    // height: 48px;
    text-align: start;

    &.ant-select-disabled {
      .ant-select-selector {
        border-color: #d3d3d8 !important;
      }
    }
  }

  .ant-select-selector {
    // max-height: 50px;
    overflow: hidden;
    border-radius: 12px;
  }
  .ant-select-disabled {
    .ant-select-selector {
      background: transparent !important;
      border-color: #4c54a0 !important;
      box-shadow: none !important;
    }
  }
`;
export function DatePicker(props: DatePickerProps) {
  const onDatePickerChanged = (value: Dayjs | null) => {
    if (!value) return props.onChange && props.onChange({ date: [], apiDate: [], time: [] });
    const dateFormatted: string[] = props.calender?.selectRange ? (value.format() as any) : [value.format()];

    const payload = {
      date: dateFormatted,
      time: dateFormatted?.map((e) => dayjs(e).format('HH:mm'))
    };
    props.onChange &&
      props.onChange({
        date: payload.date.map((e) => dayjs(e).toDate()),
        time: payload.time,
        apiDate: payload.date.map((e) => dayjs(e).utc(false).format())
      });
    if (
      (dateFormatted?.length === 2 && props.calender?.selectRange) ||
      (!props.calender?.selectRange && dateFormatted)
    ) {
    }
  };

  const handleRangePickerChanged = (value: [Dayjs, Dayjs], dateStrings: [string, string]) => {
    if (!value?.length) return props.onChange && props.onChange({ date: [], apiDate: [], time: [] });
    const dateFormatted: string[] = value?.map((e) => e.format());

    const payload = {
      date: dateFormatted,
      time: dateFormatted?.map((e) => dayjs(e).format('HH:mm'))
    };
    props.onChange &&
      props.onChange({
        date: payload.date.map((e) => dayjs(e).toDate()),
        time: payload.time,
        apiDate: payload.date.map((e) => dayjs(e).utc(false).format())
      });
    if (
      (dateFormatted?.length === 2 && props.calender?.selectRange) ||
      (!props.calender?.selectRange && dateFormatted)
    ) {
    }
  };

  const startDateTime: Dayjs | null = useMemo(() => {
    if (!props.date?.at(0)) return null;
    const date = moment(props.date?.at(0));
    if (props.time?.at(0)) {
      date.startOf('day').add(moment.duration(props.time?.at(0)!));
    }
    return dayjs(date.format());
  }, [props.date?.at(0), props.time?.at(0), props.allowClear]);

  const endDateTime: Dayjs | null = useMemo(() => {
    if (!props.date?.at(1)) return null;
    const date = moment(props.date?.at(1));
    if (props.time?.at(1)) {
      date.startOf('day').add(moment.duration(props.time?.at(1)!));
    }
    return dayjs(date.format());
  }, [props.date?.at(1), props.time?.at(1), props.allowClear]);

  const disabledDate: RangePickerProps['disabledDate'] =
    props.disablePast == true
      ? (current) => {
          return current < dayjs().startOf('day');
        }
      : (current) => {
          return (
            (!!props.calender?.minDate && current.add(1, 'd').isBefore(dayjs(props.calender?.minDate))) ||
            (!!props.calender?.maxDate && current.isAfter(dayjs(props.calender?.maxDate)))
          );
        };

  const disabledTime = (current: Dayjs) => {
    const currentDate = dayjs(current).startOf('D');
    const minDate = dayjs(props.calender?.minDate).startOf('D').format();
    const maxDate = dayjs(props.calender?.maxDate).startOf('D').format();
    return {
      disabledHours: () => {
        const disabledHours: number[] = [];
        if (currentDate.isSame(minDate) && props.calender?.minDate) {
          for (let i = 0; i < dayjs(props.calender?.minDate).hour(); i++) {
            disabledHours.push(i);
          }
        }
        if (currentDate.isSame(maxDate) && props.calender?.maxDate) {
          for (let i = dayjs(props.calender?.maxDate).hour(); i < 24; i++) {
            disabledHours.push(i);
          }
        }

        return disabledHours;
      },
      disabledMinutes: () => {
        const disabledMinutes: number[] = [];
        if (currentDate.isSame(minDate) && props.calender?.minDate) {
          for (let i = 0; i < dayjs(props.calender?.minDate).minute(); i++) {
            disabledMinutes.push(i);
          }
        }
        if (currentDate.isSame(maxDate) && props.calender?.maxDate) {
          for (let i = dayjs(props.calender?.maxDate).minute(); i < 60; i++) {
            disabledMinutes.push(i);
          }
        }

        return disabledMinutes;
      }
    };
  };

  if (props.calender?.selectRange) {
    return (
      <ContainerStyled className={props.className}>
        <AntdDatePacker.RangePicker
          value={[startDateTime, endDateTime]}
          showTime={props.selectTime && { use12Hours: false }}
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          placeholder={[
            `${props.placeholder?.[0] ? props.placeholder?.[0] : 'From Date'}`,
            `${props.placeholder?.[1] ? props.placeholder?.[1] : 'To Date'}`
          ]}
          status={props.errors && 'error'}
          disabled={props.disabled}
          format={props.selectTime ? 'DD MMM YYYY - hh:mm A' : 'DD MMM YYYY'}
          allowClear={props.allowClear}
          needConfirm={false}
          onChange={(value, e) => handleRangePickerChanged(value as any, e)}
        />
        {startDateTime && (
          <LabelStyled className={`${props.disabled ? 'disabled' : 'active'}`}>
            {props.name ? `${props.name} ${props.disableDateName ? '' : 'Date'}` : 'Date'}
          </LabelStyled>
        )}
        {props.errors && <Errors errors={props.errors} />}
      </ContainerStyled>
    );
  }

  return (
    <ContainerStyled className={props.className}>
      <AntdDatePacker
        value={startDateTime}
        disabled={props.disabled}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        placeholder={props.name}
        status={props.errors && 'error'}
        format={props.selectTime ? 'DD MMM YYYY - hh:mm A' : 'DD MMM YYYY'}
        showTime={props.selectTime && { use12Hours: false }}
        allowClear={props.allowClear}
        needConfirm={false}
        onChange={onDatePickerChanged}
      />
      {props.name && startDateTime && (
        <LabelStyled className={`${props.disabled ? 'disabled' : 'active'}`}>{props.name}</LabelStyled>
      )}
      {props.errors && <Errors errors={props.errors} />}
    </ContainerStyled>
  );
}

export type DatePickerProps = {
  name?: string;
  date?: Date[];
  time?: string[];
  timeInterval?: number;
  calender?: CalendarProps;
  selectTime?: boolean;
  errors?: { [key: string]: string[] };
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  dateFormat?: Formats;
  defaultTime?: string[];
  disableCloseOnSelect?: boolean;
  allowClear?: boolean;
  onChange?: (e: { date: Date[]; time: string[]; apiDate?: string[] }) => void;
  disablePast?: boolean;
  placeholder?: string | [string, string];
  disableDateName?: boolean;
};
