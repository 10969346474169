import { ConfigProvider } from 'antd';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import UTC from 'dayjs/plugin/utc';
import moment from 'moment';
import 'moment/locale/en-gb';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { onCLS, onINP, onLCP } from 'web-vitals';
import App from './App';
import './app/utils/storage';
import './instrument';

moment.locale('en-gb', {
  week: {
    dow: 1 //Monday is the first day of the week.
  }
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

dayjs.extend(updateLocale);
dayjs.extend(UTC);
dayjs.extend(customParseFormat);
dayjs.updateLocale('en', {
  weekStart: 1
});

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4c54a0',
          colorBgContainerDisabled: 'transparent'
        },
        components: {
          Input: {
            controlHeight: 50,
            borderRadius: 8
          },
          Select: {
            controlHeight: 50,
            borderRadius: 8
          },
          DatePicker: {
            controlHeight: 50,
            borderRadius: 8,
            zIndexPopupBase: 10012,
            zIndexPopup: 10012,
            zIndexBase: 10012
          }
        }
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

onCLS(console.log);
onINP(console.log);
onLCP(console.log);
