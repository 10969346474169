import { ReactComponent as ShieldIcon } from 'app/assets/sheild.svg';
import { ReactComponent as ShieldSelectedIcon } from 'app/assets/shield-selected.svg';
import { ReactComponent as ActiveIcon } from 'app/assets/svgs/active-icon.svg';
import { ReactComponent as AwaitingFeedbackIcon } from 'app/assets/svgs/awaiting-feedback-icon.svg';
import { ReactComponent as CancelledStudentClassIcon } from 'app/assets/svgs/cancelled-student-class-icon.svg';
import { ReactComponent as ClassStudentsIcon } from 'app/assets/svgs/class-students-icon.svg';
import { ReactComponent as ClassroomZoomLinkIcon } from 'app/assets/svgs/classroom-zoom-link.svg';
import { ReactComponent as CopyIcon } from 'app/assets/svgs/copy-icon.svg';
import { ReactComponent as FileUploaded } from 'app/assets/svgs/file-uploaded.svg';
import { ReactComponent as FileIcon } from 'app/assets/svgs/file.svg';
import { ReactComponent as FirstPaymentPendingIcon } from 'app/assets/svgs/first-payment-pending-icon.svg';
import { ReactComponent as SortArrowDownIcon } from 'app/assets/svgs/ic_Chevron Down.svg';
import { ReactComponent as ChevronLeftIcon } from 'app/assets/svgs/ic_Chevron Left.svg';
import { ReactComponent as ChevronRightIcon } from 'app/assets/svgs/ic_Chevron Right.svg';
import { ReactComponent as SortArrowUpIcon } from 'app/assets/svgs/ic_Chevron Up.svg';
import { ReactComponent as CloseIcon } from 'app/assets/svgs/ic_Close-2.svg';
import { ReactComponent as AddSubjectIcon } from 'app/assets/svgs/ic_Close_Circle.svg';
import { ReactComponent as DownloadIcon } from 'app/assets/svgs/ic_Download.svg';
import { ReactComponent as EditIcon } from 'app/assets/svgs/ic_Edit.svg';
import { ReactComponent as FilterIcon } from 'app/assets/svgs/ic_Filter.svg';
import { ReactComponent as TooltipIcon } from 'app/assets/svgs/ic_Information.svg';
import { ReactComponent as NoteIcon } from 'app/assets/svgs/ic_Notes.svg';
import { ReactComponent as TrashIcon } from 'app/assets/svgs/ic_Trash.svg';
import { ReactComponent as SearchIcon } from 'app/assets/svgs/icons/search.svg';
import { ReactComponent as InformationIcon } from 'app/assets/svgs/information-icon.svg';
import { ReactComponent as LinkIcon } from 'app/assets/svgs/link.svg';
import { ReactComponent as LoadingIcon } from 'app/assets/svgs/loading.svg';
import { ReactComponent as PendingIcon } from 'app/assets/svgs/pending-icon.svg';
import { ReactComponent as PlusIcon } from 'app/assets/svgs/plus-icon-3.svg';
import { ReactComponent as IncompleteIcon } from 'app/assets/svgs/record-incomplete.svg';
import { ReactComponent as ScheduleIcon } from 'app/assets/svgs/schedule.svg';
import { ReactComponent as StudentClassCancelIcon } from 'app/assets/svgs/student-class-cancel.svg';
import { ReactComponent as SuccessIcon } from 'app/assets/svgs/success-icon.svg';
import { ReactComponent as SupplyTeacherIcon } from 'app/assets/svgs/supply-teacher.svg';
import { ReactComponent as DeleteIcon } from 'app/assets/svgs/trash-icon-3.svg';
import { ReactComponent as UploadIcon } from 'app/assets/svgs/upload.svg';
import { ReactComponent as ZoomInIcon } from 'app/assets/svgs/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from 'app/assets/svgs/zoom-out.svg';
import STYLE_DEFAULTS from 'app/scss/style';
import styled from 'styled-components';

const IconContainerStyled = styled.div<{ color: string }>`
  line-height: 0px !important;
  .ct-svg {
    fill: none;
    .ct-svg-path {
      stroke: ${(props) => {
        if (props.color === 'primary') {
          return STYLE_DEFAULTS.MAIN_COLORS.primary;
        } else if (props.color === 'secondary') {
          return STYLE_DEFAULTS.MAIN_COLORS.secondary;
        } else if (props.color === 'danger') {
          return STYLE_DEFAULTS.MAIN_COLORS.danger;
        } else if (props.color === 'white') {
          return STYLE_DEFAULTS.MAIN_COLORS.white;
        } else if (props.color === 'info') {
          return STYLE_DEFAULTS.MAIN_COLORS.info;
        } else return STYLE_DEFAULTS.MAIN_COLORS.primary;
      }};
    }
  }
`;

const PendingIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <PendingIcon {...props.svg} />
  </IconContainerStyled>
);

const SuccessIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <SuccessIcon {...props.svg} />
  </IconContainerStyled>
);

const ShieldSelectedIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ShieldSelectedIcon {...props.svg} />
  </IconContainerStyled>
);

const ShieldIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ShieldIcon {...props.svg} />
  </IconContainerStyled>
);

const SortArrowUpIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <SortArrowUpIcon {...props.svg} />
  </IconContainerStyled>
);

const SortArrowDownIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <SortArrowDownIcon {...props.svg} />
  </IconContainerStyled>
);

const CloseIconComponent = (props: React.PropsWithChildren<IconProps>) => <CloseIcon {...props} />;

const DownloadIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <DownloadIcon {...props.svg} />
  </IconContainerStyled>
);

const ChevronLeftIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ChevronLeftIcon {...props.svg} />
  </IconContainerStyled>
);

const ChevronRightIconIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ChevronRightIcon {...props.svg} />
  </IconContainerStyled>
);

const CopyIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <CopyIcon {...props.svg} />
  </IconContainerStyled>
);

const LoadingIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <LoadingIcon className={`fa fa-spin ${props.svg?.className}`} {...props.svg} />
  </IconContainerStyled>
);

const UploadIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <UploadIcon {...props.svg} />
  </IconContainerStyled>
);

const FileIconComponent = (props: React.PropsWithChildren<IconProps>) => <FileIcon {...props} />;

const FilterIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <FilterIcon {...props.svg} />
  </IconContainerStyled>
);

const EditIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <EditIcon {...props.svg} />
  </IconContainerStyled>
);

const DeleteIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <DeleteIcon {...props.svg} />
  </IconContainerStyled>
);

const ZoomInIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ZoomInIcon {...props.svg} />
  </IconContainerStyled>
);

const ZoomOutIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ZoomOutIcon {...props.svg} />
  </IconContainerStyled>
);

const PlusIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <PlusIcon {...props.svg} />
  </IconContainerStyled>
);

const TrashIconComponent = (props: React.PropsWithChildren<IconProps>) => <TrashIcon {...props} />;

const TooltipIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <TooltipIcon {...props.svg} />
  </IconContainerStyled>
);

const SearchIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <SearchIcon {...props.svg} />
  </IconContainerStyled>
);

const NotificationQuestionIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <i {...props} className={`fa-solid fa-circle-question fa-xl ${props?.className}`} style={{ color: '#87ADBD' }}></i>
);

const NotificationInfoIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <i {...props} className={`fa-solid fa-circle-info fa-xl ${props?.className}`} style={{ color: '#1677FF' }}></i>
);

const NotificationErrorIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <i {...props} className={`fa-solid fa-circle-xmark fa-xl ${props?.className}`} style={{ color: '#FF4D4F' }}></i>
);

const NotificationSuccessIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <i {...props} className={`fa-solid fa-circle-check fa-xl ${props?.className}`} style={{ color: '#52C41A' }}></i>
);

const NotificationWarningIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <i {...props} className={`fa-solid fa-circle-exclamation fa-xl ${props?.className}`} style={{ color: '#FAAD14' }}></i>
);

const CancelledStudentClassIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <CancelledStudentClassIcon {...props.svg} />
  </IconContainerStyled>
);

const AwaitingFeedbackIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <AwaitingFeedbackIcon {...props.svg} />
  </IconContainerStyled>
);

const FirstPaymentPendingIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <FirstPaymentPendingIcon {...props.svg} />
  </IconContainerStyled>
);

const ActiveIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <ActiveIcon {...props.svg} />
  </IconContainerStyled>
);

const InformationIconComponent = (props: React.PropsWithChildren<IconProps>) => (
  <IconContainerStyled color={props.color} {...props}>
    <InformationIcon {...props.svg} />
  </IconContainerStyled>
);

const ScheduleIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <ScheduleIcon {...props.svg} />
  </IconContainerStyled>
);

const LinkIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <LinkIcon {...props.svg} />
  </IconContainerStyled>
);

const FileUploadedIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <FileUploaded {...props.svg} />
  </IconContainerStyled>
);

const SupplyTeacherIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <SupplyTeacherIcon {...props.svg} />
  </IconContainerStyled>
);

const IncompleteIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <IncompleteIcon {...props.svg} />
  </IconContainerStyled>
);

const AddSubjectIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <AddSubjectIcon {...props.svg} />
  </IconContainerStyled>
);

const MeetingRecordingStuckIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <circle cx="12.5" cy="12.5" r="12.5" fill="currentColor" />
      <path
        d="M10.8333 5.83366V14.167C10.8333 15.0875 11.5794 15.8337 12.4999 15.8337C13.4204 15.8337 14.1666 15.0875 14.1666 14.167V5.83366C14.1666 4.91318 13.4204 4.16699 12.4999 4.16699C11.5794 4.16699 10.8333 4.91318 10.8333 5.83366Z"
        fill="white"
      />
      <path
        d="M14.1666 19.167C14.1666 20.0875 13.4204 20.8337 12.4999 20.8337C11.5794 20.8337 10.8333 20.0875 10.8333 19.167C10.8333 18.2465 11.5794 17.5003 12.4999 17.5003C13.4204 17.5003 14.1666 18.2465 14.1666 19.167Z"
        fill="white"
      />
    </svg>
  </IconContainerStyled>
);

const MeetingRecordingInCompleteIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <circle cx="12.5" cy="12.5" r="12.5" fill="currentCOlor" />
      <path
        d="M10.9004 15.7207V14.9258C10.9004 14.4388 10.9684 14.0091 11.1045 13.6367C11.2406 13.2572 11.459 12.8991 11.7598 12.5625C12.0605 12.2188 12.458 11.8678 12.9521 11.5098C13.3962 11.1947 13.7507 10.9118 14.0156 10.6611C14.2878 10.4105 14.4847 10.1634 14.6064 9.91992C14.7354 9.66927 14.7998 9.38639 14.7998 9.07129C14.7998 8.60579 14.6279 8.2513 14.2842 8.00781C13.9404 7.76432 13.4606 7.64258 12.8447 7.64258C12.2288 7.64258 11.6165 7.73926 11.0078 7.93262C10.4062 8.12598 9.79395 8.38021 9.1709 8.69531L8 6.34277C8.71615 5.94173 9.49316 5.61947 10.3311 5.37598C11.1689 5.12533 12.0856 5 13.0811 5C14.6136 5 15.7988 5.36882 16.6367 6.10645C17.4818 6.83691 17.9043 7.7679 17.9043 8.89941C17.9043 9.50098 17.8076 10.0238 17.6143 10.4678C17.4281 10.9046 17.1416 11.3128 16.7549 11.6924C16.3753 12.0648 15.8991 12.4587 15.3262 12.874C14.8965 13.1891 14.5671 13.4541 14.3379 13.6689C14.1087 13.8838 13.9512 14.0986 13.8652 14.3135C13.7865 14.5212 13.7471 14.7754 13.7471 15.0762V15.7207H10.9004ZM10.5566 19.3945C10.5566 18.7214 10.7393 18.2487 11.1045 17.9766C11.4769 17.7044 11.9245 17.5684 12.4473 17.5684C12.9557 17.5684 13.3926 17.7044 13.7578 17.9766C14.1302 18.2487 14.3164 18.7214 14.3164 19.3945C14.3164 20.0391 14.1302 20.5046 13.7578 20.791C13.3926 21.0775 12.9557 21.2207 12.4473 21.2207C11.9245 21.2207 11.4769 21.0775 11.1045 20.791C10.7393 20.5046 10.5566 20.0391 10.5566 19.3945Z"
        fill="white"
      />
    </svg>
  </IconContainerStyled>
);

const MeetingRecordingCompleteIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06936 13.2146C6.85179 12.992 6.5008 12.9938 6.28541 13.2187C6.07001 13.4436 6.07177 13.8065 6.28933 14.0291L9.5186 17.3342C9.74658 17.5675 10.118 17.5527 10.3279 17.3018L18.7399 8.448C18.9402 8.20863 18.9149 7.84673 18.6833 7.63967C18.4518 7.43261 18.1017 7.45879 17.9014 7.69816L9.87727 16.0884L7.06936 13.2146Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  </IconContainerStyled>
);

const LessonMissedIconComponent = (props: any) => {
  return (
    <IconContainerStyled color={props.color} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="#45B1B3" />
        <mask id="a" width="48" height="48" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
          <circle cx="24" cy="24" r="24" fill="currentColor" />
        </mask>
        <g mask="url(#a)">
          <path
            fill="currentColor"
            d="M25.273 26.455a3.589 3.589 0 0 1-2.553-1.06l-5.968-5.964 2.315-2.316 5.964 5.969a.348.348 0 0 0 .48 0l7.604-7.605 2.315 2.315-7.604 7.601a3.59 3.59 0 0 1-2.553 1.06Z"
          />
          <path
            fill="currentColor"
            d="M17.909 24.818h-3.272v-8.182A1.637 1.637 0 0 1 16.273 15h8.182v3.273h-6.546v6.545ZM13 29.727h22.91v3.272H13v-3.272Z"
          />
        </g>
      </svg>
    </IconContainerStyled>
  );
};

const ReviewLessonIconComponent = (props: any) => {
  return (
    <IconContainerStyled color={props.color} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="#45B1B3" />
        <mask id="a" width="48" height="48" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
          <circle cx="24" cy="24" r="24" fill="#fff" />
        </mask>
        <g mask="url(#a)">
          <path
            fill="#fff"
            d="m33.276 22.94-12.443-7.78A1.228 1.228 0 0 0 19 16.194v15.577a1.217 1.217 0 0 0 .632 1.072 1.2 1.2 0 0 0 1.246-.037l12.398-7.798a1.219 1.219 0 0 0 0-2.07Z"
          />
        </g>
      </svg>
    </IconContainerStyled>
  );
};

const StarStudentIconComponent = (props: any) => {
  return (
    <IconContainerStyled color={props.color} {...props}>
      <svg width="54" height="50" viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3846_49104)">
          <path
            d="M29.3022 3.63922L34.1684 13.6054L44.1332 14.6329C44.6237 14.6993 45.0843 14.901 45.46 15.214C45.8357 15.5271 46.1107 15.9383 46.2523 16.3987C46.3939 16.8591 46.396 17.3492 46.2585 17.8108C46.121 18.2723 45.8497 18.6858 45.4767 19.002L37.5531 25.3426L40.0077 35.9937C40.1022 36.4645 40.0555 36.9518 39.873 37.3976C39.6906 37.8434 39.38 38.229 38.9783 38.5088C38.5766 38.7885 38.1005 38.9505 37.6067 38.9756C37.1129 39.0006 36.622 38.8877 36.1923 38.6503L26.994 33.2286L17.8044 38.6503C17.3747 38.8877 16.8838 39.0006 16.39 38.9756C15.8962 38.9505 15.4201 38.7885 15.0184 38.5088C14.6167 38.229 14.3061 37.8434 14.1236 37.3976C13.9412 36.9518 13.8944 36.4645 13.989 35.9937L16.4436 25.3426L8.51133 19.002C8.13822 18.6853 7.86694 18.2711 7.72986 17.8089C7.59278 17.3468 7.59569 16.8561 7.73825 16.3955C7.8808 15.9349 8.15698 15.5238 8.53382 15.2112C8.91067 14.8987 9.37226 14.6979 9.86352 14.6329L19.8283 13.6054L24.6945 3.63922C24.9105 3.22654 25.2401 2.87994 25.6467 2.63784C26.0534 2.39573 26.5212 2.26758 26.9983 2.26758C27.4755 2.26758 27.9433 2.39573 28.35 2.63784C28.7566 2.87994 29.0862 3.22654 29.3022 3.63922Z"
            fill="#FFC542"
          />
        </g>
        <path d="M10.0337 1L15.5241 7.5991" stroke="#FFC542" strokeWidth="2" strokeLinecap="round" />
        <path d="M43.7302 1L38.2398 7.5991" stroke="#FFC542" strokeWidth="2" strokeLinecap="round" />
        <path d="M1 31.7598L9.28302 28.9099" stroke="#FFC542" strokeWidth="2" strokeLinecap="round" />
        <path d="M52.1492 32.0098L44.3714 27.9803" stroke="#FFC542" strokeWidth="2" strokeLinecap="round" />
        <path d="M25.9763 49L26.2107 40.2436" stroke="#FFC542" strokeWidth="2" strokeLinecap="round" />
        <defs>
          <clipPath id="clip0_3846_49104">
            <rect width="38.8343" height="36.7904" fill="white" transform="translate(7.58105 2.22656)" />
          </clipPath>
        </defs>
      </svg>
    </IconContainerStyled>
  );
};

const BehaviorIssueIconComponent = (props: any) => {
  return (
    <IconContainerStyled color={props.color} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="#4C54A0" />
        <mask id="a" width="48" height="48" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
          <circle cx="24" cy="24" r="24" fill="currentColor" />
        </mask>
        <g mask="url(#a)">
          <path
            fill="currentColor"
            d="m14.934 17.394 8.657-2.937 9.637 3.388v6.625l-1.506 4.517-2.409 3.011-5.722 2.108-2.258-.602-3.614-2.409-2.183-2.108-.602-3.915v-7.678Z"
          />
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M23.517 13.223a1 1 0 0 1 .68 0l4.5 1.625 4.5 1.626a1 1 0 0 1 .66.94v6.75c0 5.5-3.33 9.198-9.735 10.964a1 1 0 0 1-.525.002c-6.41-1.72-9.74-5.421-9.74-10.966v-6.75a1 1 0 0 1 .66-.94l9-3.25Zm.34 2.004-8 2.89v6.047c0 4.497 2.585 7.44 7.996 8.962 5.416-1.562 8.003-4.507 8.003-8.962v-6.048l-8-2.889Zm-.95 3.867a1 1 0 1 1 2 0v6.613a1 1 0 1 1-2 0v-6.613Zm.95 11.07a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
            clipRule="evenodd"
          />
          <path
            fill="#4C54A0"
            d="M23.881 18.129a1 1 0 0 0-1 1v6.612a1 1 0 1 0 2 0V19.13a1 1 0 0 0-1-1Zm-1.05 11.07a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
          />
        </g>
      </svg>
    </IconContainerStyled>
  );
};

const MissingHomeworkIconComponent = (props: any) => {
  return (
    <IconContainerStyled color={props.color} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="#4C54A0" />
        <g clipPath="url(#a)">
          <path
            fill="currentColor"
            d="M30.857 15H19.286a2.143 2.143 0 0 0-2.143 2.143V18h-.857a1.286 1.286 0 0 0 0 2.571h.857v2.143h-.857a1.285 1.285 0 1 0 0 2.572h.857v2.143h-.857a1.286 1.286 0 1 0 0 2.571h.857v.857A2.143 2.143 0 0 0 19.286 33h11.571A2.143 2.143 0 0 0 33 30.857V17.143A2.143 2.143 0 0 0 30.857 15Zm-5.143 11.786h-3.428a.857.857 0 0 1 0-1.715h3.428a.857.857 0 0 1 0 1.715Zm2.143-3.857h-5.571a.857.857 0 0 1 0-1.715h5.571a.857.857 0 1 1 0 1.715Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="currentColor" d="M15 15h18v18H15z" />
          </clipPath>
        </defs>
      </svg>
    </IconContainerStyled>
  );
};

const ClassStudentsIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <ClassStudentsIcon {...props.svg} />
  </IconContainerStyled>
);

const ClassroomZoomLinkIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <ClassroomZoomLinkIcon {...props.svg} />
  </IconContainerStyled>
);

const NoteIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <NoteIcon {...props.svg} />
  </IconContainerStyled>
);

const StudentClassCancelIconComponent = (props: any) => (
  <IconContainerStyled color={props.color} {...props}>
    <StudentClassCancelIcon {...props.svg} />
  </IconContainerStyled>
);

const LinkNoteIconComponent = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0ZM4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H4ZM5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H5ZM5 11C4.44772 11 4 10.5523 4 10C4 9.44771 4.44772 9 5 9H15C15.5523 9 16 9.44771 16 10C16 10.5523 15.5523 11 15 11H5ZM5 16C4.44772 16 4 15.5523 4 15C4 14.4477 4.44772 14 5 14H10C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16H5Z"
        fill="currentColor"
      />
    </svg>
  );
};

const OpenTabIconComponent = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.0002 2H4.2002C3.08009 2 2.51962 2 2.0918 2.21799C1.71547 2.40973 1.40973 2.71547 1.21799 3.0918C1 3.51962 1 4.08009 1 5.2002V12.8002C1 13.9203 1 14.4801 1.21799 14.9079C1.40973 15.2842 1.71547 15.5905 2.0918 15.7822C2.5192 16 3.07899 16 4.19691 16H11.8031C12.921 16 13.48 16 13.9074 15.7822C14.2837 15.5905 14.5905 15.2839 14.7822 14.9076C15 14.4802 15 13.921 15 12.8031V11M16 6V1M16 1H11M16 1L9 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconComponents = {
  OpenTabIconComponent,
  LinkNoteIconComponent,
  NoteIconComponent,
  StudentClassCancelIconComponent,
  ClassroomZoomLinkIconComponent,
  ClassStudentsIconComponent,
  MissingHomeworkIconComponent,
  BehaviorIssueIconComponent,
  StarStudentIconComponent,
  ReviewLessonIconComponent,
  LessonMissedIconComponent,
  ScheduleIconComponent,
  PendingIconComponent,
  SuccessIconComponent,
  InformationIconComponent,
  ShieldSelectedIconComponent,
  ShieldIconComponent,
  SortArrowDownIconComponent,
  SortArrowUpIconComponent,
  CancelledStudentClassIconComponent,
  AwaitingFeedbackIconComponent,
  FirstPaymentPendingIconComponent,
  ActiveIconComponent,
  EditIconComponent,
  DeleteIconComponent,
  ZoomInIconComponent,
  ZoomOutIconComponent,
  PlusIconComponent,
  TrashIconComponent,
  NotificationInfoIconComponent,
  NotificationErrorIconComponent,
  NotificationSuccessIconComponent,
  NotificationQuestionIconComponent,
  NotificationWarningIconComponent,
  LoadingIconComponent,
  UploadIconComponent,
  FileIconComponent,
  DownloadIconComponent,
  ChevronLeftIconComponent,
  ChevronRightIconIconComponent,
  SearchIconComponent,
  FilterIconComponent,
  TooltipIconComponent,
  CloseIconComponent,
  LinkIconComponent,
  IncompleteIconComponent,
  MeetingRecordingStuckIconComponent,
  MeetingRecordingInCompleteIconComponent,
  MeetingRecordingCompleteIconComponent,
  FileUploadedIconComponent,
  SupplyTeacherIconComponent,
  AddSubjectIconComponent,
  CopyIconComponent
};

interface IProp {
  color?: 'primary' | 'danger' | 'success' | string;
}

type IconProps = IProp | any;
