import { Tooltip } from 'antd';
import nextIcon from 'app/assets/next.png';
import prevIcon from 'app/assets/prev.png';
import { utcToLocalDateTime } from 'app/utils/utils';
import moment from 'moment';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import DateText from '../DateText/DateText';
import * as Styles from './style';

const CustomToolbar = ({ date, label, onNavigate, views, onView, view }: ToolbarProps) => {
  return (
    <Styles.ToolbarHeader>
      <Styles.ToolbarLabel>
        {' '}
        {<DateText date={utcToLocalDateTime(moment(date).format()).toISOString()} format="DD_MMM_YYYY" />}{' '}
        {utcToLocalDateTime(moment(date).format()).startOf('day').isSame(moment().startOf('day')) && '(Today)'}
      </Styles.ToolbarLabel>
      <Styles.ToolbarViewButtonsContainer></Styles.ToolbarViewButtonsContainer>
      <Styles.ToolbarButtons>
        <Tooltip title={`Previous ${view}`}>
          <Styles.ToolbarButton onClick={() => onNavigate(Navigate.PREVIOUS)}>
            <Styles.ToolbarButtonIcon src={prevIcon} />
          </Styles.ToolbarButton>
        </Tooltip>
        <Tooltip title={` Next ${view}`}>
          <Styles.ToolbarButton onClick={() => onNavigate(Navigate.NEXT)}>
            <Styles.ToolbarButtonIcon src={nextIcon} />
          </Styles.ToolbarButton>
        </Tooltip>
      </Styles.ToolbarButtons>
    </Styles.ToolbarHeader>
  );
};
export default CustomToolbar;
