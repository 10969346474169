import { Link, useLocation } from 'react-router-dom';

export interface IUserTypeCardProps {
  icon: any;
  type: string;
  onClick?: any;
  url?: string;
}

export default function UserTypeCard(props: IUserTypeCardProps) {
  const { icon, type, onClick } = props;
  const location = useLocation();
  const linkPath = location.pathname.includes('signup') ? `/signup}` : `/${type.toLocaleLowerCase()}`;
  return (
    <Link
      onClick={onClick}
      to={props.url || linkPath}
      className="card card-body bg-white border-0 flex justify-content-center align-content-between align-items-center mx-2 mb-4"
      style={{ minWidth: '10px', height: '250px', flex: '1' , maxWidth:"220px"}}
    >
      <div style={{marginBottom:"30px"}}>{icon}</div>
      <div className="fw-bolder text-body">{type}</div>
    </Link>
  );
}
