import { ReactComponent as AddIcon } from 'app/assets/svgs/circular-add-icon.svg';
import { DatePicker } from 'app/components/v1/Date-Picker';
import { studentApi } from 'app/services/student';
import moment from 'moment';
import { useState } from 'react';

interface LeavePeriodComponentProps {
  leavePeriod: {
    start: Date;
    end: Date;
    studentId: number;
  };
}

export const LeavePeriodComponent = (props: LeavePeriodComponentProps) => {
  const [showTemp, setShowTemp] = useState(false);
  const [updateStudent, { isLoading: isLoadingUpdateStudent }] = studentApi.useUpdateStudentMutation();

  const handleChange = async (e: any) => {
    await updateStudent({
      id: props.leavePeriod.studentId.toString(),
      profileData: {
        startLeavePeriod: e.date[0] ? moment(e.date[0]).format('YYYY-MM-DD') : null,
        endLeavePeriod: e.date[1] ? moment(e.date[1]).format('YYYY-MM-DD') : null
      }
    });
    setShowTemp(false);
  };
  return (
    <div>
      {(props.leavePeriod.end && props.leavePeriod.start) || showTemp ? (
        <DatePicker
          allowClear
          calender={{
            selectRange: true
          }}
          onChange={(e) => handleChange(e)}
          date={[props.leavePeriod.start, props.leavePeriod.end]}
          disabled={isLoadingUpdateStudent}
        />
      ) : (
        <AddIcon onClick={() => setShowTemp(true)} />
      )}
    </div>
  );
};
