import { Tooltip } from 'antd';
import avatars from 'app/utils/avatars';
import { ImgHTMLAttributes, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';

const AvatarStyled = styled.div`
  svg,
  img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
`;
export default function Avatar(props: PropsWithChildren<AvatarProps>) {
  const SelectedAvatar = useMemo<any>(() => {
    const selectedAvatar =
      avatars.find((avatar) => avatar.name.toLowerCase() === props.name?.toLowerCase()) || avatars.at(0);
    return selectedAvatar?.component;
  }, [props.name]);

  return props.title ? (
    <Tooltip title={props.title}>
      <AvatarStyled>{props.url ? <img {...props} src={props.url} /> : <SelectedAvatar {...props} />}</AvatarStyled>
    </Tooltip>
  ) : (
    <AvatarStyled>{props.url ? <img {...props} src={props.url} /> : <SelectedAvatar {...props} />}</AvatarStyled>
  );
}
type AvatarProps = {
  /** (optional) show an image that has this URL */
  url?: string;
  /** (optional) display the standard avatars by name */
  name?: string;
  /** (optional) if it's provided will display a tooltip when they hovering on the avatar */
  title?: string;
} & ImgHTMLAttributes<any>;
