export const STORAGE_PREFIX = 'classtutor-';
export let STORAGE_KEYS: { [key: string]: string } = {
  TOKEN: `${STORAGE_PREFIX}token`,
  REFRESH_TOKEN: `${STORAGE_PREFIX}refreshToken`,
  USER_ID: `${STORAGE_PREFIX}userId`,
  STUDENT_REGISTERS: `${STORAGE_PREFIX}registeredStudents`,
  USER: 'user'
};

function initialize(storageKeys: { [key: string]: string }) {
  STORAGE_KEYS = storageKeys;
}
function addKey(prefix: string) {
  return STORAGE_KEYS[prefix];
}
function removeKey(prefix: string) {
  delete STORAGE_KEYS[prefix];
}

function setItem(key: string, value: string) {
  localStorage.setItem(key, value);
}
function removeItem(key: string) {
  localStorage.removeItem(key);
}
function getItem(key: string) {
  return localStorage.getItem(key);
}
function clear() {
  sessionStorage.clear();
  localStorage.clear();
}

export default {
  initialize,
  addKey,
  removeKey,
  setItem,
  getItem,
  removeItem,
  clear
};
