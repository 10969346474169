import api from './index';

export const uploadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, FormData>({
      query: (body) => ({
        url: `/api/upload`,
        method: 'POST',
        body
      })
    }),
    deleteFile: builder.mutation<any, string>({
      query: (fileId) => ({
        url: `/api/upload/files/${fileId}`,
        method: 'delete'
      })
    })
  })
});

export const { useUploadFileMutation, useDeleteFileMutation } = uploadApi;
