import React from 'react';
import useTogglePassword from './useTogglePassword';
import { ReactComponent as LockSVG } from 'app/assets/svgs/ic_secure Outline.svg';
import './styles/input.styles.scss';

export interface IInputProps {
  type: string;
  required?: boolean;
  label: string;
  feedback?: string;
  classes?: string;
  pattern?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
  icon?: any;
  maxChars?: number;
  minChars?: number;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Input(props: IInputProps) {
  const {
    type,
    required = false,
    label,
    feedback = '',
    classes = '',
    pattern,
    value,
    onChange,
    name,
    disabled,
    isInvalid,
    readOnly,
    icon,
    maxChars,
    minChars,
    onBlur,
    ...rest
  } = props;

  const { togglePassword, passwordShown, EyeIcon } = useTogglePassword();

  const element =
    type === 'textarea' ? (
      <textarea minLength={minChars} className="text-area" maxLength={maxChars} rows={6} />
    ) : (
      <input
        style={{
          border: '1px solid #E2E2EA'
        }}
      />
    );

  return (
    <>
      <div className={`form-floating position-relative ${classes}`}>
        <element.type
          readOnly={readOnly}
          {...element.props}
          {...rest}
          type={passwordShown ? 'text' : type}
          className={`form-control form-floating-element ${
            type === 'textarea' ? 'textarea' : ''
          }  ${isInvalid ? 'is-invalid' : ''}`}
          required={required}
          id="floatingInput"
          placeholder=" "
          pattern={pattern}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          disabled={disabled || readOnly}
        />
        {type === 'password' && (
          <>
            <EyeIcon
              onClick={togglePassword}
              className="position-absolute top-50 translate-middle form-floating-eyeIcon"
            />
          </>
        )}
        <label htmlFor="floatingInput">{label}</label>
        <span className="invalid-feedback">{feedback}</span>
        {disabled && <LockSVG className="position-absolute top-50 translate-middle form-icon" />}
        {icon && <div className="position-absolute top-50 translate-middle pb-lg-3  form-icon">{icon}</div>}
      </div>
    </>
  );
}
