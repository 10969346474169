import { humanize, toTitleCase, transform } from '@alduino/humanizer/string';
import { Button } from 'app/components/v1/Button';
import { RegisterStudentHomeworkStatuses, UserType } from 'app/services/types';
import { MAIN_SELECTORS } from 'app/store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PercentageButtonStyled = styled(Button)`
  background: rgba(61, 213, 152, 0.2);
  color: #3dd598;
  width: 160px;
  height: 45px;
  font-size: 1rem;
  text-wrap: nowrap;

  &.red {
    background-color: rgba(252, 90, 90, 0.2);
    color: rgba(252, 90, 90, 1);
  }

  &.yellow {
    background-color: rgba(255, 197, 66, 0.2);
    color: rgba(255, 197, 66, 1);
  }

  &.green {
    background: rgba(61, 213, 152, 0.2);
    color: rgba(61, 213, 152, 1);
  }

  &.red:hover {
    background-color: rgba(252, 90, 90, 0.2) !important;
  }

  &.yellow:hover {
    background-color: rgba(255, 197, 66, 0.2) !important;
  }

  &.green:hover {
    background: rgba(61, 213, 152, 0.2) !important;
  }
`;

const SubmitButtonStyled = styled(Button)`
  width: 160px;
  height: 45px;
  font-size: 1rem;
  &.na {
    background: #c4c4c4;
    color: #ffffff;
  }
  &.na:hover {
    background: #c4c4c4 !important;
  }
`;

const LateBadgeStyled = styled.div`
  background: #fc5a5a;
  color: #ffffff;
  padding: 0 10px;
  border-radius: 10px;
`;

export default function HomeworkButton(props: HomeworkButtonProps) {
  const user = useSelector(MAIN_SELECTORS.user);
  return [
    RegisterStudentHomeworkStatuses.COMPLETED,
    RegisterStudentHomeworkStatuses.MARKED,
    RegisterStudentHomeworkStatuses.LATE,
    RegisterStudentHomeworkStatuses.MARKED_LATE
  ].includes(props.status as string) ? (
    <PercentageButtonStyled
      className={`${
        !props.homeworkMarksPercentage && props.homeworkMarksPercentage != 0
          ? props.homeworkMarksPercentage == null
            ? 'red'
            : ''
          : parseInt(props.homeworkMarksPercentage.toString()) < 50
            ? 'red'
            : parseInt(props.homeworkMarksPercentage.toString()) < 90
              ? 'yellow'
              : parseInt(props.homeworkMarksPercentage.toString()) >= 90
                ? 'green'
                : ''
      }`}
      name={`${!props.homeworkMarksPercentage ? (props.status as string) : `${props.homeworkMarksPercentage} %`} `}
      style={
        RegisterStudentHomeworkStatuses.BLOCKED == props.status
          ? {
              backgroundColor: '#ffc542',
              color: '#ffffff'
            }
          : {}
      }
      onClick={props.onClick}
    >
      {props.status === RegisterStudentHomeworkStatuses.LATE && <LateBadgeStyled>L</LateBadgeStyled>}
      {props.status === RegisterStudentHomeworkStatuses.MARKED_LATE && (
        <>
          {`${props.homeworkMarksPercentage ?? 0} %`}
          <LateBadgeStyled>L</LateBadgeStyled>
        </>
      )}
      {props.status === RegisterStudentHomeworkStatuses.MARKED && <>{`${props.homeworkMarksPercentage ?? 0} %`}</>}
    </PercentageButtonStyled>
  ) : (
    <SubmitButtonStyled
      className={`st-v1-button st-v1-button-primary ${props.status == 'na' ? 'na' : ''}`}
      name={
        user?.userType == UserType.Student.toLowerCase() || user?.userType == UserType.Parent.toLowerCase()
          ? props.submitButtonTitle || props.homeworkSubmitted
            ? 'View'
            : 'Submit'
          : transform(humanize(String(props.status == 'na' ? 'N/a' : props.status)), toTitleCase)
      }
      disabled={props.status == 'na'}
      onClick={props.onClick}
    />
  );
}

type HomeworkButtonProps = {
  homeworkSubmitted: boolean;
  status: RegisterStudentHomeworkStatuses;
  homeworkMarksPercentage: number;
  submitButtonTitle?: string;
  onClick: () => void;
};
