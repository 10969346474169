import styled from 'styled-components';

const DashStyled = styled.div`
  display: inline-block;
  margin: 0 5px;
  color: currentColor;
  font-size: 14px;
  font-style: normal;
  line-height: 0px;
  height: 2px;
`;

const Dash = () => {
  return <DashStyled>&#45;</DashStyled>;
};

export default Dash;
