import { AppContext } from 'AppContext';
import { useWSSocket } from 'app/services/web-socket/ws-socket.service';
import { useFormValidation } from 'app/utils/hooks/useFormValidation';
import { STORAGE_KEYS } from 'app/utils/storage';
import { PropsWithChildren, useEffect } from 'react';

export const AppLayout = ({ children }: PropsWithChildren) => {
  const { disconnect, connect, joinRoom, leaveRoom, socket } = useWSSocket();
  useEffect(() => {
    onInitializing();
    async function onInitializing() {
      await import('./app/js/theme');
      // logout in the other tabs if they logged out from any
      window.addEventListener(
        'storage',
        (e) => (e.key === STORAGE_KEYS['USER_ID'] || !e.key) && document.hidden && (location.href = '/login')
      );
    }
  }, []);

  useFormValidation();

  return (
    <AppContext.Provider value={{ connect, disconnect, joinRoom, leaveRoom, socket }}>{children}</AppContext.Provider>
  );
};
