import DefaultImage from 'app/assets/default-avatar.png';
import Container from 'app/components/Container/Container';
import { classApi } from 'app/services/class';
import { ClassStatues } from 'app/services/types';
import { getFileUrl, timeFormat, utcTimeToLocalTime } from 'app/utils/utils';
import _ from 'lodash';
import qs from 'qs';
import { useMemo } from 'react';
import styled from 'styled-components';
import { StatusDotComponent } from './StatusDotComponent';

const ClassIDStyled = styled.div`
  position: relative;

  width: fit-content;

  .class-button {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    border: 1px solid #696974;
    border-radius: 25px;
    padding: 4px 14px;
    width: fit-content;
    font-weight: 400;
    font-size: 12px;
    color: #696974;
    outline: none;
    gap: 5px;
    line-height: initial;
    &:disabled {
      background: #d8d8d8;
      border: 1px solid #d8d8d8;
      color: white;
    }
  }

  .class-tooltip {
    display: none;
    flex-direction: column;
    width: 248px;
    padding: 10px 15px 22px 15px;
    background: #ffffff;
    border: 0.5px solid #d8d8d8;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: absolute;
    margin-top: 0.5px;
    z-index: 1000;
    & > .year-subject-details {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 16px;
      color: #171725;
      line-height: 36px;
    }
    & > .class-date {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 12px;
      color: #92929d;
    }
    & > .class-status-details {
      margin-top: 9px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      align-items: center;
      & > .class-button {
        margin-top: 0;
        padding: 0px 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      & > .class-status-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }

    & > .teacher-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 14px;
      gap: 10px;
      .avatar {
        width: 32px;
        height: 32px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
      .teacher-name {
        font-weight: 600;
        font-size: 14px;
        color: #44444f;
      }
    }
  }

  &.active {
    .class-button {
      background: rgba(76, 84, 160, 0.1);
      border: 1px solid #4c54a0;
      color: #4c54a0;
    }
  }

  &.selected {
    .class-button {
      background: rgba(76, 84, 160, 0.1);
      border: 1px solid #4c54a0;
      color: #4c54a0;
    }
  }

  &:hover {
    .class-tooltip {
      display: flex;
    }
  }
`;

export default function ClassID(props: ClassIDProps) {
  return (
    <ClassIDStyled className={`${props.selected ? 'selected' : ''}`}>
      <button
        className="class-button"
        disabled={props.disabled}
        onClick={(e) => {
          props.onClick && props.onClick(e);
        }}
      >
        {props.data.classId}
        {props.active && <StatusDotComponent type="active-2" />}
      </button>
      {!props.readonly && <ClassIDDetailsTooltip className="class-tooltip" classId={props.data.id} />}
    </ClassIDStyled>
  );
}

function ClassIDDetailsTooltip(props: { className?: string; classId: string }) {
  const { data: classData, isLoading: isClassDetailsLoading } = classApi.useGetClasseroomsQuery(
    {
      requestQuery: qs.stringify(
        {
          filters: {
            id: {
              $eq: props.classId
            }
          },
          populate: ['subject', 'year', 'teacher', 'teacher.photo']
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !props.classId
    }
  );

  const viewData = useMemo<ClassIDDetailsTooltipViewData>(() => {
    const teacherPhoto = classData?.data?.at(0)?.attributes?.teacher?.data?.attributes?.photo?.data?.at(0);
    return {
      class: {
        id: classData?.data?.at(0)?.id.toString()!,
        classId: classData?.data?.at(0)?.attributes?.classId! as any,
        startDate: classData?.data?.at(0)?.attributes?.dayOfWeek
          ? _.capitalize(classData?.data?.at(0)?.attributes?.dayOfWeek)!
          : undefined,
        startTime: classData?.data?.at(0)?.attributes?.startTime
          ? utcTimeToLocalTime(classData?.data?.at(0)?.attributes?.startTime!).format(timeFormat())!
          : undefined,
        endTime: classData?.data?.at(0)?.attributes?.endTime
          ? utcTimeToLocalTime(classData?.data?.at(0)?.attributes?.endTime!).format(timeFormat())!
          : undefined,
        status: classData?.data?.at(0)?.attributes?.status as any
      },
      subject: {
        id: classData?.data?.at(0)?.attributes?.subject?.data?.id.toString()!,
        name: classData?.data?.at(0)?.attributes?.subject?.data?.attributes?.name!
      },
      year: {
        id: classData?.data?.at(0)?.attributes?.year?.data?.id.toString()!,
        name: classData?.data?.at(0)?.attributes?.year?.data?.attributes?.shortName!
      },
      teacher: {
        id: classData?.data?.at(0)?.attributes?.teacher?.data?.id.toString()!,
        name: classData?.data?.at(0)?.attributes?.teacher?.data?.attributes?.name!,
        photo: teacherPhoto ? getFileUrl(teacherPhoto.attributes) : DefaultImage
      }
    };
  }, [classData]);

  return (
    <Container isLoading={isClassDetailsLoading || !viewData}>
      <div className={props.className}>
        <div className="year-subject-details">
          {viewData.year.name} . {viewData.subject.name}
        </div>
        {classData?.data?.at(0)?.attributes?.dayOfWeek &&
        viewData.class.startDate &&
        viewData.class.startTime &&
        viewData.class.endTime ? (
          <div className="class-date">
            {viewData.class.startDate} {viewData.class.startTime} to {viewData.class.endTime}
          </div>
        ) : (
          <></>
        )}
        <div className="class-status-details">
          <div className="class-button">{viewData.class.classId} </div>
          <ClassStatusComponent data={{ id: viewData.class.id, status: viewData.class.status }} />
        </div>
        <div className="teacher-details">
          <img className="avatar" src={viewData.teacher.photo} />{' '}
          <span className="teacher-name">{viewData.teacher.name}</span>
        </div>
      </div>
    </Container>
  );
}

function ClassStatusComponent(props: { data: { id: string; status: ClassStatues } }) {
  if (props.data.status === ClassStatues.ACTIVE) {
    return (
      <div className="class-status-container">
        <StatusDotComponent type="active-2" /> <span>Active</span>
      </div>
    );
  } else {
    return (
      <div className="class-status-container">
        <StatusDotComponent type="inactive" /> <span>Inactive</span>
      </div>
    );
  }
}

export type ClassIDProps = {
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  data: {
    id: string;
    classId: string;
    status: ClassStatues;
  };
  onClick?: Function;
};

type ClassIDDetailsTooltipViewData = {
  class: {
    id: string;
    classId: string;
    startDate?: string;
    startTime?: string;
    endTime?: string;
    status: ClassStatues;
  };
  year: {
    id: string;
    name: string;
  };
  subject: {
    id: string;
    name: string;
  };
  teacher: {
    id: string;
    name: string;
    photo: string;
  };
};
