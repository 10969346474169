import qs from 'qs';
import api from './index';
import { ApiResponse, GetQueryPayload, GetRegisterStudents, IUpdateRegisteredStudentsPayload, Register } from './types';

export const registerStudentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateRegisterStudent: builder.mutation<any, { registerStudent: any; id: string }>({
      query: (body) => ({
        url: `/api/register-students/${body.id}`,
        method: 'PUT',
        body: { data: body.registerStudent }
      }),
      invalidatesTags: ['RegistersMarkings', 'RegisterStudent', 'Register']
    }),
    updateRegisteredStudents: builder.mutation<any, any>({
      query: (payload) => {
        return {
          url: `/api/register-students/bulk`,
          method: 'PUT',
          body: payload
        };
      },
      invalidatesTags: ['RegisterStudent']
    }),
    getLessonHomeworks: builder.query<
      ApiResponse<{ id: string; attributes: Register }>,
      { filters: any; populate?: { [key: string]: any }; fields?: string[] }
    >({
      providesTags: ['RegisterStudent'],
      query: (payload) => {
        const query = qs.stringify(
          {
            ...payload
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/register-students?${query}`,
          method: 'GET'
        };
      }
    }),
    getRegisterStudents: builder.query<GetRegisterStudents, GetQueryPayload>({
      providesTags: ['RegisterStudent'],
      query: (payload) => ({
        url: `/api/register-students?${payload.requestQuery}`,
        method: 'GET'
      })
    }),
    getRegisterStudentById: builder.query<any, GetQueryPayload & { trialId: string }>({
      providesTags: ['RegisterStudent'],
      query: (payload) => {
        return {
          url: `/api/register-students/${payload.trialId}?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useUpdateRegisterStudentMutation,
  useUpdateRegisteredStudentsMutation,
  useGetRegisterStudentsQuery,
  useLazyGetRegisterStudentsQuery,
  useGetRegisterStudentByIdQuery,
  useLazyGetRegisterStudentByIdQuery
} = registerStudentApi;
