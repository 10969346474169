import { message } from 'antd';
import { HTTPErrorResponse } from 'app/services/types';

export const useErrorHandler = () => {
  const handle = async (
    callback: Function,
    onError?: (error: HTTPErrorResponse | any) => Promise<void>,
    onFinally?: Function,
    options?: { silent?: boolean }
  ) => {
    if (callback && typeof callback !== 'function') {
      throw 'callback should be a function';
    }
    try {
      callback && (await callback());
    } catch (error) {
      onError && (await onError(error));
      if (!options?.silent) {
        console.error(error);
        message.error('something wrong please contact with admin');
      }
    } finally {
      onFinally && (await onFinally());
    }
  };

  return {
    handle
  };
};
