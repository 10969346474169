export interface ApiResponse<TData = any> {
  data: TData;
  meta: ApiResponseMeta;
  error: HTTPErrorResponse;
}

export type ApiResponseData<TAttributes> = {
  id: number;
  attributes: TAttributes;
};

export interface ApiResponseMeta {
  pagination: ApiResponseMetaPagination;
}

export interface ApiResponseMetaPagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

export type GetStudentFeedsResponse = {
  results: IStudentFeed[];
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
};

export type GetQueryPayload = {
  requestQuery?: string;
};
export type GetStudentClassesResponse = ApiResponse<ApiResponseData<StudentClass>[]>;
export type GetRegisterStudents = ApiResponse<ApiResponseData<RegisterStudent>[]>;
export type GetStudentClassResponse = ApiResponse<ApiResponseData<StudentClass>>;
export type GetStudentNotesResponse = ApiResponse<
  ApiResponseData<{
    text: string;
    createdAt: string;
    updatedAt: string;
    agent?: {
      data: ApiResponseData<{
        name: string;
        user: ApiResponseData<User>;
        createdAt: string;
        updatedAt: string;
      }>;
    };
  }>[]
>;
export type CreateStudentSalesManagementNotesResponse = ApiResponse<
  ApiResponseData<{
    text: string;
    createdAt: string;
    updatedAt: string;
  }>
>;
export type GetClassYearsResponse = ApiResponse<ApiResponseData<ClassYear>[]>;

export type GetClassSubjectsResponse = ApiResponse<ApiResponseData<Subject>[]>;
export type GetNoticesResponse = ApiResponse<ApiResponseData<Notice>[]>;
export type GetNoticeResponse = ApiResponse<ApiResponseData<Notice>>;

export type GetAvailableClassesRequestPayload = {
  studentId?: string;
  subjectId: string;
  yearId: string;
  classType: ClassTypes;
  classId?: string;
  statuses?: StudentClassStatus[];
  options?: {
    includePreviousLessons: boolean;
  };
};

export type GetAvailableClassesRequestResponse = {
  classData: AvailableClassesClassData[];
  previousTrials: any[];
};

export type AvailableClassesClassData = {
  id: number;
  classId: string;
  teacherName: string;
  teacherPhoto: Array<Photo>;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  zoomLink?: any;
  dayOfWeek: string;
  status: ClassStatues;
  classDates: AvailableClassesClassDate[];
  classStudents: AvailableClassClassStudents[];
  students: AvailableClassClassStudents[];
  isManual: boolean;
  statusCounts: StatusCount[];
};

export type StatusCount = {
  id: string;
  total: number;
  name: string;
  displayName: string;
};

export type AvailableClassesClassDate = {
  date: string;
  teacherName: string;
  teacherPhoto: Photo[];
  isCancelled: boolean;
  startTime: string;
  endTime: string;
};

export type AvailableClass = {
  id: number;
  classId: string;
  teacherName: string;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  zoomLink?: any;
  dayOfWeek: string;
  classDates: { date: string; teacherName: string }[];
  classStudents: AvailableClassClassStudents[];
};

export type AvailableClassClassStudents = {
  id: string;
  name: string;
  avatarImageName: string;
  classStatus: ClassStudentClassStatuses;
  adminStatus: StudentClassStatus;
  studentId: string;
  studentDbId: string;
};

export type GetStudentsResponse = ApiResponse<ApiResponseData<Student>[]>;

export type GetSubjectsResponse = ApiResponse<ApiResponseData<Subject>[]>;

export type GetTeacherResponse = ApiResponse<ApiResponseData<Teacher>>;

export type GetTeacherPaymentsResponse = ApiResponse<ApiResponseData<TeacherPayment>[]>;

export type GetWeeklyPaymentsResponse = ApiResponse<ApiResponseData<WeeklyPayment>[]>;
export type GetTeacherPaymentsGroupResponse = ApiResponse<ApiResponseData<TeacherPaymentGroup>[]>;

export type GetTeacherPaymentResponse = ApiResponse<ApiResponseData<TeacherPayment>>;

export type GetTeacherCapabilitiesResponse = {
  subject: string;
  educationLevels: {
    name: string;
    type: string;
  }[];
}[];

export type UpdateTeacherCapabilityRequest = {
  subject: string;
  educationLevels: {
    name: string;
    type: string;
  }[];
}[];

export type UpdateTeacherRequest = CreateTeacherRequest;
export type CreateTeacherRequest = {
  teacherId?: string;
  name?: string;
  lastName?: string;
  status?: TeacherStatues;
  country?: string;
  email?: string;
  email2?: string;
  phoneCountryCode?: string;
  phone?: string;
  phoneCountryCode2?: string;
  phone2?: string;
  cv?: string[];
  coverLetter?: string[];
  photo?: string[];
  bio?: string;
  linkedInProfile?: string;
  interviewRecording?: string;
  qts?: string;
  dbsCertification?: string[];
  university?: string;
  degree?: string;
  degreeGrade?: string;
  signedContract?: string;
  passport?: string[];
  niNumber?: string;
  address1?: string;
  proofOfAddress?: string[];
  bankAccountNumber?: string;
  bankSortCode?: string;
  joinedDate?: string;
  utr?: string;
  rate?: number;
  degreeCertificate?: string[];
  contractor?: string;
  user?: string;
  subjects?: string;
  city?: string;
  postcode?: string;
  dbsCode?: string;
  gender?: string;
  shortBio?: string;
  dbsDate?: string;
  resume?: any;
};

export type CreateStudentScholarshipRequest = {
  name: string;
  status: string;
  amount: string;
  lastFormSentDate?: string;
  student: string;
  scholarship: string;
};

export type UpdateStudentScholarshipRequest = Omit<Partial<CreateStudentScholarshipRequest>, 'student'> & {
  id: string;
};

export enum UserType {
  Admin = 'Admin',
  Teacher = 'Teacher',
  Parent = 'Parent',
  Student = 'Student',
  Marker = 'Marker'
}

export type EntityType = {
  id?: number;
  name: string;
};

export interface Year {
  id?: number;
  name: string;
  shortName: string;
  otherName: string;
  order: number;
  yearNumber: string;
  createdAt: string;
  updatedAt: string;
  isALevels?: any;
}

export type SubjectRequest = {
  name: string;
  type?: any;
  createdAt: string;
  updatedAt: string;
  colour?: any;
  code: string;
  order?: any;
};

export type Subject = {
  isPrimarySubject: number;
  name: string;
  type?: any;
  createdAt: string;
  updatedAt: string;
  colour?: any;
  code: string;
  order?: any;
  parent_subject?: {
    data: ApiResponseData<Subject>;
  };
};

export type User = {
  id?: number;
  username?: string;
  IsParent?: boolean;
  email?: string;
  provider?: string;
  confirmed?: boolean;
  blocked?: boolean;
  source?: any;
  createdAt?: string;
  updatedAt?: string;
  user_types?: {
    id: string;
    name: string;
  }[];
  admin?: Admin;
  student?: Student & { id?: string; address?: string };
  parent?: Parent & { id?: string };
  teacher?: Teacher & { id?: string };
  created_at?: string;
  updated_at?: string;
  additionalPermission: AdditionalPermissions;
  /**
   * @todo: will be used later after implementing the authorization system (roles and permissions)
   */
  roles?: UserRole[];
};

/**
 * @todo: will be used later after implementing the authorization system (roles and permissions)
 */
export type UserRole = {
  name: string;
  permissions: UserRolePermission[];
};

/**
 * @todo: will be used later after implementing the authorization system (roles and permissions)
 */
export type UserRolePermission = {
  name: string;
  key: string;
};

export class TeacherStatues {
  public static readonly NEW = 'new';
  public static readonly INTERVIEW = 'interviewee';
  public static readonly RE_APPLY = 'reapplied';
  public static readonly OFFERED = 'offered';
  public static readonly ACTIVE = 'active';
  public static readonly IN_ACTIVE = 'inactive';
  public static readonly REJECTED = 'rejected';
  public static readonly BARRED = 'barred';
  public static readonly OFFER_REJECTED = 'offerRejected';
  public static readonly OFFER_ACCEPTED = 'offerAccepted';
}

export type GetScholarshipsResponse = ApiResponse<ApiResponseData<Scholarship>[]>;
export type GetRegistersResponse = ApiResponse<ApiResponseData<Register>[]>;
export type GetTopicsResponse = ApiResponse<ApiResponseData<Topic>[]>;
export type GetClassTopicsResponse = ApiResponse<ApiResponseData<ClassTopic>[]>;
export type Scholarship = {
  name: string;
  createdAt: string;
  updatedAt: string;
  discount: string;
};

export type GetStudentScheduleResponse = {
  classId: string;
  subject: string;
  date: string;
  startTime: string;
  endTime: string;
  year: string;
  subjectColour: string;
}[];
export type GetStudentScheduleRequest = GetQueryPayload & {
  studentId: string;
  startDate: string;
  endDate: string;
};

export type GetStudentResponse = ApiResponse<ApiResponseData<Student>>;
export type GetParentResponse = ApiResponse<ApiResponseData<Parent>>;
export type GetStudentScholarshipsResponse = ApiResponse<ApiResponseData<StudentScholarship>[]>;
export type GetStudentScholarshipResponse = ApiResponse<ApiResponseData<StudentScholarship>>;
export type StudentScholarship = {
  name: string;
  status: string;
  amount: string;
  lastFormSentDate: string;
  createdAt: string;
  updatedAt: string;
  scholarship?: {
    data: ApiResponseData<Scholarship>;
  };
};

export enum StudentScholarshipStatues {
  INACTIVE = 'inactive',
  AWAITING_SUBMISSION = 'awaitingSubmission',
  AWAITING_APPROVAL = 'awaitingApproval',
  ELIGIBLE = 'eligible',
  INELIGIBLE = 'ineligible'
}

export enum ParentStatuses {
  ACTIVE = 'active',
  IN_ACTIVE = 'inactive',
  ARCHIVED = 'archived',
  ACTION_REQUIRED = 'actionRequired',
  IN_PROCESS = 'inProcess',
  POSTPONED = 'postponed',
  INVALID = 'invalid',
  NEW = 'invalid'
}

export type GetTeachersResponse = ApiResponse<ApiResponseData<Teacher>[]>;

export type GetSubjectTopicsResponse = ApiResponse<ApiResponseData<SubjectTopics>[]>;

export type GetAdminSettingResponse = ApiResponse<ApiResponseData<AdminSetting>>;

export type GetUserResponse = User;

export type CreateAccountRequest = {
  // to figure out if the user came of invitation of a new signup
  signupToken?: string;
  signupAs: 'student' | 'parent' | 'teacher';
  //
  parentsToAdd?: Parent[];
  studentsToAdd?: Student[];
  parentsToInvite?: Parent[];
  teacherToAdd?: Teacher;
  // use this to link students to parent
  studentIdsToLink?: string[];
};

export type CreateAccountResponse = {
  parentsAdded: CreateAccountResponseAccount[];
  studentsAdded: CreateAccountResponseAccount[];
  teacherAdded: CreateAccountResponseAccount;
  parentsInvited: CreateAccountResponseAccount[];
};

export type CreateAccountResponseAccount = {
  avatarImageName: string;
  email: string;
  lastName: string;
  name: string;
  parentId: string;
  username: string;
};

export type GetAccountByTokenResponse = {
  linkedStudents: Student[];
  linkedParents: Parent[];
  parentToAdd: Parent & { isUsingFreeTrialForm: boolean };
  studentsToAdd: Student[];
  studentToAdd: Student;
  parentsToAdd: Parent[];
  teacherToAdd: {
    email: string;
    lastName: string;
    name: string;
    phone: string;
    phoneCountryCode: string;
    teacherId: string;
    linkedInProfile?: string;
    cv?: UploadedFile[];
    photo?: UploadedFile[];
    coverLetter?: UploadedFile[];
    country?: string;
    gender?: string;
    capabilities?: GetTeacherCapabilitiesResponse;
  };
};

export type GetParentsResponse = ApiResponse<ApiResponseData<Parent>[]>;
export type GetClasseroomsResponse = ApiResponse<ApiResponseData<Class>[]>;
export type GetMeetingsRecordingResponse = ApiResponse<ApiResponseData<MeetingRecord>[]>;
export type GetClasseroomResponse = ApiResponse<ApiResponseData<Class>>;
export type GetAdminsResponse = ApiResponse<
  ApiResponseData<{
    name: string;
    createdAt: string;
    updatedAt: string;
  }>[]
>;
export type ClassDatesResponse = ApiResponse<
  ApiResponseData<{
    classDate: string;
  }>[]
>;
export type classSummaryResponse = {
  class: {
    id: number | string;
    classId: string;
    teacherName: string;
    teacherPhoto?: any;
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
    zoomLink?: string;
    dayOfWeek: string;
    classStudents: ClassStudent[];
  };
};

export type RegisterStudent = {
  issue_reasons: any;
  id?: string;
  student?: {
    data: ApiResponseData<Student>;
  };
  student_class?: {
    data: ApiResponseData<StudentClass>;
  };
  register?: {
    data: ApiResponseData<Register>;
  };
  freeTrial: boolean;
  engagement: string;
  understanding?: 'mastered' | 'understood' | 'struggled' | 'absent';
  issueDescription?: string;
  starStudent?: boolean;
  feedbackTodaysLesson?: number;
  feedbackBeforeLessonTopic?: number;
  feedbackAfterLessonTopic?: number;
  feedbackTodayTeacher?: number;
  internalNotes?: string;
  parentNotes?: string;
  homeworkMarkingStatus?: HomeworkMarkingStatuses;
  homeworkCompletionDate?: string;
  homeworkComment?: string;
  homeworkMark?: string;
  homeworkUnderstanding?: HomeworkUnderstandingTypes;
  markerPaid?: boolean;
  hasAttended?: boolean;
  hasLeftEarly?: boolean;
  isLate?: boolean;
  sendIssueToParent?: boolean;
  issueApprovedByAdmin?: boolean;
  issueSentToParent?: boolean;
  createdAt: string;
  updatedAt: string;
  name: string;
  homeworkErrorReasonTeacher?: string;
  homeworkErrorReasonStudent?: string;
  homeworkSubmitted?: boolean;
  homeworkSubmittedLate?: boolean;
  homeworkMarkedFiles: any;
  homeworkSubmissionFiles: any;
  homework_marking_teacher: any;
  homeworkMarksColour: any;
  homeworkMarksPercentage: any;
  homeworkErrorReason?: string;
  issueSendToParent?: boolean;
  issueStatus?: string;
  homeworkStatus?: string;
};
export type UploadedFile = {
  id?: string;
  name: string;
  alternativeText?: any;
  caption?: any;
  width: number;
  height: number;
  formats: {
    small: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path?: any;
      size: number;
      width: number;
      height: number;
    };
    thumbnail: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path?: any;
      size: number;
      width: number;
      height: number;
    };
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: any;
  provider: string;
  provider_metadata?: any;
  createdAt: string;
  updatedAt: string;
};
export type Register = {
  homeworkNotRequired?: boolean;
  notes?: string;
  materialNotProvided: boolean;
  coverSameAsOwner: boolean;
  completedAt?: string;
  id: string;
  classDate: string;
  parentNotes: string;
  homeworkDeadline: string;
  homeworkMarks?: any;
  recordingLink?: any;
  payAmount?: any;
  teacherPaymentStatus: string;
  recordingChat?: any;
  recordingTranscript?: any;
  status: string;
  issueDescription?: any;
  issueApprovedByAdmin?: any;
  createdAt: string;
  updatedAt: string;
  name: string;
  homeworkLockStartTime: string;
  homeworkLockEndTime: string;
  homeworkErrorReason?: string;
  startTime?: string;
  endTime?: string;
  attendanceMarked?: boolean;
  attendanceMarkedTime?: string;
  attendanceLateDays?: number;
  hasHomework?: boolean;
  classTime?: string;
  pendingReschedule?: boolean;
  attendance_marked_by?: {
    data: ApiResponseData<Teacher>;
  };
  class: {
    data: ApiResponseData<Class>;
  };
  teacher: {
    data: ApiResponseData<Teacher>;
  };
  lessonFiles: {
    data: ApiResponseData<UploadedFile[]>[];
  };
  register_students: {
    data: ApiResponseData<RegisterStudent>[];
  };
  curriculums: {
    data: any[];
  };
  issue_reasons: {
    data: ApiResponseData<IssueReason>[];
  };
  homeworkFiles: {
    data: ApiResponseData<UploadedFile[]>[];
  };
  homeworkSolutions: {
    data: ApiResponseData<UploadedFile[]>[];
  };
  homework_locked_by_teacher: {
    data: ApiResponseData<Teacher>;
  };
  lesson_material: {
    data: ApiResponseData<LessonMaterial>;
  };
  homeworkType?: HomeworkTypes;
  breakMinutes?: number;
};

export type LessonMaterial = {
  name: string;
  subject: {
    data: ApiResponseData<Subject>;
  };
  year: {
    data: ApiResponseData<Teacher>;
  };
  homeworkReference: string;
  lessonFiles?: {
    data: ApiResponseData<UploadedFile>[];
  };
  isArchived?: boolean;
};

export type HomeworkMarkingStatuses = 'marked' | 'error' | undefined;
export type HomeworkUnderstandingTypes = 'struggled' | 'understood' | 'mastered';

export type CheckinLogs = {
  timestamp: Date;
  admin: Admin;
};

export type CallLogs = {
  timestamp: Date;
  admin: { data: { attributes: Admin } };
  note: String;
  parent?: { data: { attributes: Parent } };
  reason: string;
  response: string;
  student?: { data: { attributes: Student } };
};

export type UpdateRegisterRequestBody = {
  homeworkErrorReason?: string;
  homeworkMarkingBlocked?: boolean;
  homeworkMarks?: string;
  lessonFiles?: string[];
  homeworkSolutions?: string[];
  homeworkFiles?: string[];
  homeworkDeadline?: string;
  homework_error_by?: string | string[];
  homeworkErrorStatus?: RegisterHomeworkErrorStatuses;
  homeworkErrorDate?: string;
  issue_reasons?: string[];
  issueDescription?: string;
  status?: string;
  startTime?: string;
  endTime?: string;
  payAmount?: string;
  classDate?: string;
  hasHomework?: boolean;
  checkinLogs?: CheckinLogs[];
  lesson_material?: number;
  homeworkType?: HomeworkTypes;
  homeworkNotRequired?: boolean;
};

export class StudentClassStatus {
  public static readonly NEW: string = 'new';
  public static readonly TRAIL: string = 'trial';
  public static readonly AWAITING_FEEDBACK: string = 'awaitingFeedback';
  public static readonly FIRST_PAYMENT_PENDING: string = 'firstPaymentPending';
  public static readonly ACTIVE: string = 'active';
  public static readonly PAYMENT_OVERDUE: string = 'paymentOverdue';
  public static readonly PAUSED: string = 'paused';
  public static readonly CANCELLED: string = 'cancelled';
  public static readonly INACTIVE: string = 'inactive';
  public static readonly POSTPONE: string = 'postpone';
  public static readonly PAYMENT_FAILED: string = 'paymentFailed';
  public static readonly CHANGE_REQUESTED: string = 'changeRequested';
  public static readonly PAYMENT_WRITE_OFF: string = 'paymentWriteOff';
  public static readonly CANCELLATION_PENDING: string = 'cancellationPending';
  public static readonly CANCELLATION_REQUESTED: string = 'cancellationRequested';
  public static readonly AWAITING_LESSON: string = 'awaitingLesson';
}
export type GetYearsResponse = ApiResponse<ApiResponseData<Year>[]>;

export type GetGroupsResponse = ApiResponse<ApiResponseData<Group>[]>;

export type RequestQuery = {
  populate?: { [key: string]: any } | string;
  fields?: string[];
  filters?: { [key: string]: any };
  sort?: string[];
  pagination?: Record<string, any>;
};

export class RegisterStudentStatues {
  public static readonly PROACTIVE = 'proactive';
  public static readonly ATTENTIVE = 'attentive';
  public static readonly DISTRACTED = 'distracted';
  public static readonly UNKNOWN = 'unknown';
  public static readonly ABSENT = 'absent';
}

export class RegisterStudentUnderstanding {
  public static readonly MASTERED = 'mastered';
  public static readonly UNDERSTOOD = 'understood';
  public static readonly STRUGGLED = 'struggled';
  public static readonly ABSENT = 'absent';
}

export class RegisterStudentHomeworkStatuses {
  public static readonly MARKED = 'marked';
  public static readonly ERROR = 'error';
  public static readonly WAITING = 'waiting';
  public static readonly MISSING = 'missing';
  public static readonly SUBMITTED = 'submitted';
  public static readonly BLOCKED = 'blocked';
  public static readonly MARKED_LATE = 'marked_late';
  public static readonly LATE = 'late';
  public static readonly MARK = 'mark';
  public static readonly COMPLETED = 'completed';
  public static readonly INPROGRESS = 'inprogress';
}

export class RegisterStudentIssueStatuses {
  public static readonly NOT_APPLICABLE = 'not_applicable';
  public static readonly PENDING = 'pending';
  public static readonly SENT = 'sent';
  public static readonly REJECTED = 'rejected';
}

export class RegisterHomeworkErrorStatuses {
  public static readonly NOT_APPLICABLE = 'not_applicable';
  public static readonly PENDING = 'pending';
  public static readonly RESOLVED = 'resolved';
  public static readonly STUCK = 'stuck';
}

export type Invoice = {
  id?: string;
  paymentIdentifier: string;
  invoiceIdentifier: string;
  amount: number;
  refundAmount: number;
  status: InvoiceStatues;
  createdAt: string;
  updatedAt: string;
  student?: { data: ApiResponseData<Student> };
  invoiceSentDate?: string;
  notes?: string;
  failedReason?: string;
  paidDate?: string;
  dueDate?: string;
  invoice_items?: {
    data: ApiResponseData<InvoiceItem>[];
  };
};

export type InvoiceItem = {
  id?: string;
  name: string;
  multiClassDiscount: number;
  scholarshipDiscount: number;
  managementDiscount: number;
  classRate: number;
  total: number;
  student_class?: {
    data: ApiResponseData<Student>;
  };
};

export type PaymentSubscription = {
  id?: string;
  name: string;
  /**
   * any month day number is 1-31 allowed
   */
  paymentDate: number;
  invoiceDate: Date;
  unitAmount: number;
  totalAmount: number;
  quantity: number;
  payment_subscription_items?: {
    data: ApiResponseData<PaymentSubscriptionItem>[];
  };
  student?: {
    data: ApiResponseData<Student>;
  };
  dueLaterDate: Date;
  paymentStatus: string;
  paymentFrequency: any;
};

export type PaymentSubscriptionItem = {
  id?: string;
  name: string;
  multiClassDiscount?: number;
  scholarshipDiscount?: number;
  managementDiscount?: number;
  classRate?: number;
  total?: number;
  student_class?: {
    data: ApiResponseData<StudentClass>;
  };
  payment_subscription?: {
    data: ApiResponseData<PaymentSubscription>;
  };
};

export type FamilyLink = ApiResponseData<{
  parents: { data: ApiResponseData<Parent>[] };
  students: { data: ApiResponseData<Student>[] };
}>;

export interface StudentSchedule {
  studentId: string;
  studentName: string;
  studentAvatarImageName: null;
  classId: string;
  startTime: string;
  endTime: string;
  date: string;
  year: string;
  subject: string;
  subjectColour: string;
  forEntityType: 'teacher' | 'class' | 'meetingAccount' | 'student';
  entityName: string;
  registerData: Register;
  teacherId: string;
  classTeacherId: string;
  classType?: string;
}

export type Photo = {
  name: string;
  alternativeText: null;
  caption: null;
  width: number;
  height: number;
  formats: {
    small: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path: null;
      size: number;
      width: number;
      height: number;
    };
    thumbnail: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path: null;
      size: number;
      width: number;
      height: number;
    };
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: Date;
  updatedAt: Date;
};

export type Class = {
  breakMinutes?: number;
  classStream?: string | null;
  id?: string;
  classId: string;
  type: ClassTypes;
  startDate: string;
  endDate: string;
  zoomAccount?: null;
  zoomPassword?: null;
  zoomLink?: null;
  zoomMeetingId?: null;
  zoomMeetingPasscode?: null;
  startTime: string;
  endTime: string;
  nextLesson: Date;
  rate?: null;
  status: string;
  progress: string;
  dayOfWeek: DayOfWeek;
  yearDisplay: string;
  createdAt: Date;
  updatedAt: Date;
  year: any;
  subject: any;
  solutionRequired?: boolean;
  yearGroup: string;
  meeting_account?: {
    data: ApiResponseData<MeetingAccount>;
  };
  teacher?: {
    data: ApiResponseData<Teacher>;
  };
  meetingTopic?: string;
  isSummerClass?: boolean;
  exam_boards?: ApiResponseData<ExamBoards>;
  subject_level?: any;
  resit?: boolean;
  isManual?: boolean;
};

export type ExternalContact = {
  name: string;
  address?: string;
  companyName: string;
  email?: string;
  jobRole: string;
  note?: string;
  phone?: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum ClassStatues {
  ACTIVE = 'active',
  ARCHIVED = 'archived'
}

export enum StudentClassTypes {
  TRIAL = 'trial',
  BOOKING = 'booking',
  CREDIT = 'credit'
}

export type Admin = {
  id: any;
  name: string;
  createdAt: string;
  updatedAt: string;
  isOwner: Boolean;
};

export type Student = {
  startLeavePeriod?: Date;
  endLeavePeriod?: Date;
  prime_agents: {
    data: Admin[];
  };
  homeschooled: string | null;
  id?: any;
  studentId: string;
  name: string;
  lastName: string;
  email?: string;
  phoneCountryCode?: string;
  phone?: string;
  phoneCountryCode2?: string;
  phone2?: string;
  y11GraduationYear?: string;
  schoolName?: string;
  learningEducationAuthority?: any;
  subjectsIntro?: string;
  isSen?: any;
  senRequirements?: string;
  gender?: string;
  postponeDate?: string;
  postponeReason: string;
  isEal?: boolean;
  isFsm?: boolean;
  isPupilPremium?: boolean;
  dateOfBirth?: string;
  avatarImageName?: string;
  createdAt: string;
  updatedAt: string;
  status?: string;
  reports?: {
    data: ApiResponseData<UploadedFile>[];
  };
  howDidYouHearAboutUsQuestion?: string;
  how_did_you_hear_agent?: { data: ApiResponseData<Parent> };
  how_did_you_hear_parent?: { data: ApiResponseData<Parent> };
  howDidYouHearAboutUsAnswer?: string;
  subjects?: {
    data: ApiResponseData<Subject>[];
  };
  year?: {
    data: ApiResponseData<Year>;
  };
  agents: {
    data: ApiResponseData<any>[];
  };
  user?: {
    data: ApiResponseData<User>;
  };
  parents?: {
    data: ApiResponseData<Parent>[];
  };
  group?: {
    data: ApiResponseData<Group>;
  };
  primary_parent?: { data: ApiResponseData<Parent> };
  isPhonePrimary1?: boolean;
  isPhonePrimary2?: boolean;
  lastContactedAt?: string;
  lastUpdatedAt?: string;
  callLogs?: CallLogs[];
  credits?: number;
};

export type Parent = {
  marketingPreference: string;
  id?: any;
  parentId?: string;
  name?: string;
  lastName?: string;
  status?: string;
  email?: string;
  phoneCountryCode?: string;
  phone?: string;
  phoneCountryCode2?: string;
  phone2?: string;
  stripeCustomerId?: string;
  gender?: string;
  avatarImageName?: string;
  createdAt?: string;
  updatedAt?: string;
  postcode?: string;
  students: {
    data: ApiResponseData<Student>[];
  };
  user?: {
    data: ApiResponseData<User>;
  };
  addressLine1?: string;
  city?: string;
  country?: string;
  contractStatus?: string;
  signedDate?: Date;
  isPhonePrimary2?: boolean;
  isPhonePrimary1?: boolean;
};

export type Teacher = {
  id?: any;
  teacherId?: string;
  name?: string;
  lastName?: string;
  supplyTeacher?: boolean;
  gender?: string;
  email?: string;
  phone?: string;
  phoneCountryCode?: string;
  linkedInProfile?: string;
  country?: string;
  currentCountry?: string;
  password?: string;
  avatarImageName?: string;
  cv?: any;
  coverLetter?: any;
  capabilities?: any;
  photo?: { data: ModelFile[] } | any;
  status?: string;
  email2?: any;
  phoneCountryCode2?: any;
  phone2?: any;
  bio?: any;
  interviewRecording?: any;
  qts?: any;
  university?: any;
  degree?: any;
  degreeGrade?: any;
  signedContract?: any;
  niNumber?: any;
  address1?: any;
  bankAccountNumber?: any;
  bankSortCode?: any;
  joinedDate?: any;
  utr?: any;
  rate?: any;
  city?: any;
  postcode?: any;
  dbsCode?: any;
  shortBio?: any;
  dbsDate?: any;
  passport?: any;
  degreeCertificate?: any;
  proofOfAddress?: any;
  dbsCertification?: any;
  createdAt?: string;
  updatedAt?: string;
  user?: any;
  // allowed status values
  statusOptions?: string[];
  contractLink?: string;
  canReapply?: string;
  isProfileComplete?: boolean;
  //
  subjects?: {
    data: ApiResponseData<Subject>[];
  };
  isContractSigned?: boolean;
  offerAcceptedDate?: string;
  hmrcStatus?: string;
  companiesHouseStatus?: string;
  leaveDate?: string;
  payScale?: string;
  isPhonePrimary2?: boolean;
  isPhonePrimary1?: boolean;
};

export type ModelFile = ApiResponseData<{
  name: string;
  alternativeText?: any;
  caption?: any;
  width: number;
  height: number;
  formats: {
    large: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path?: any;
      size: number;
      width: number;
      height: number;
    };
    small: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path?: any;
      size: number;
      width: number;
      height: number;
    };
    medium: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path?: any;
      size: number;
      width: number;
      height: number;
    };
    thumbnail: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path?: any;
      size: number;
      width: number;
      height: number;
    };
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: any;
  provider: string;
  provider_metadata?: any;
  createdAt: string;
  updatedAt: string;
}>;

export type TeacherPayment = {
  teacher_payment_group: { data: ApiResponseData<TeacherPaymentGroup> };
  name: string;
  teacher: {
    data: ApiResponseData<Teacher>;
  };
  class: {
    data: ApiResponseData<Class>;
  };
  register: {
    data: ApiResponseData<Register>;
  };
  date: string;
  dueDate: string;
  paidDate: string;
  totalAmount: string;
  status: TeacherPaymentStatuses;
  transactions: TeacherPaymentTransaction[];
  type: TeacherPaymentTypes;
  notes: string;
  systemGenerated: boolean;
};

export type WeeklyPayment = {
  name: string;
  payDate: string;
  weekStartDate: string;
  weekEndDate: string;
  totalAmount: string;
  remainingAmount: string;
  adjustmentAmount: number;
  totalPayments: number;
  totalPaid: number;
  totalDue: number;
  totalBlocked: number;
  status: string;
};

export type TeacherPaymentGroup = {
  teacher_payment_batch: { data: ApiResponseData<WeeklyPayment> };
  name: string;
  fineDays: number;
  teacher: { data: ApiResponseData<Teacher> };
  weekStartDate: string;
  weekEndDate: string;
  totalAmount: string;
  remainingAmount: string;
  adjustmentAmount: number;
  totalPayments: number;
  totalPaid: number;
  totalDue: number;
  totalBlocked: number;
};

export enum TeacherPaymentTypes {
  LESSON = 'lesson',
  BONUS = 'bonus'
}

export enum TeacherPaymentStatuses {
  PENDING = 'pending',
  PAID = 'paid',
  DUE = 'due',
  BLOCKED = 'blocked'
}

export enum WeeklyPaymentStatuses {
  DUE = 'due',
  NOT_DUE = 'not_due',
  BLOCKED = 'blocked'
}

export type TeacherPaymentTransaction = {
  id: string;
  type: TeacherPaymentTransactionTypes;
  amount: string | number;
  systemGenerated?: boolean;
};

export enum TeacherPaymentTransactionTypes {
  LESSON = 'lesson',
  MARKING = 'marking',
  BONUS = 'bonus',
  PENALTY = 'penalty',
  COMMISSION = 'commission',
  ADJUSTMENT = 'adjustment'
}

export type AdminSetting = {
  teacherInterviewLink: string;
  maxFileSize: number;
};

export type ClassYear = {
  name: string;
  shortName: string;
  otherName: string;
  order: number;
  yearNumber: string;
  createdAt: string;
  updatedAt: string;
  isALevels?: boolean;
};

export type Notice = {
  title: string;
  description: string;
  fromDate: string;
  toDate: string;
  subTitle?: string;
  iconImage?: string;
  backgroundColour?: string;
  foregroundColour?: string;
  allClasses?: boolean;
  enabled?: boolean;
  createdAt?: string;
  updatedAt?: string;
  targetTeacher?: boolean;
  targetParent?: boolean;
  targetStudent?: boolean;
  classes?: any;
};

export type StudentClass = {
  id?: string | number;
  name?: string;
  status?: string;
  trialDate?: string;
  classDate?: any;
  cancelReason?: string;
  payment?: number;
  trialMissedReason?: any;
  scholarshipDiscount?: any;
  createdAt?: string;
  updatedAt?: string;
  source?: string;
  type?: string;
  postponeReason?: string;
  postponeDate?: string;
  changeRequestDate?: any;
  changedDate?: any;
  cancellationRequestDate?: string;
  cancelledDate?: any;
  paymentFailed?: any;
  paymentWriteOff?: any;
  invalid?: any;
  firstPaymentDate?: string;
  invoiceSentDate?: string;
  restartDate?: any;
  cancellationPending?: any;
  joinedDate?: any;
  paymentOverdueDate?: any;
  paymentFailedDate?: any;
  ignoreEmailOnCancel?: boolean;
  changeReason?: string;
  student?: {
    data?: ApiResponseData<Student>;
  };
  class?: {
    data?: ApiResponseData<Class>;
  };
  billing_parent?: {
    data?: ApiResponseData<Parent>;
  };
  agents?: {
    data: any[];
  };
  subject?: {
    data: ApiResponseData<Subject>;
  };
  year?: {
    data?: ApiResponseData<ClassYear>;
  };
  student_scholarship?: {
    data?: any;
  };
  adminStatus?: string;
  classStatus?: string;
  /**
   * Uses for status finding out instead on `adminStatus`.
   * And `adminStatus` still has correct value so no problem to use both for your purpose
   */
  displayDate?: {
    type: string;
    date?: string;
  };
  // view property
  mode?: string;
};

export type MeetingRecord = {
  name: string;
  meetingType: MeetingRecordTypes;
  date: string;
  createdAt: string;
  updatedAt: string;
  recordingLink?: string;
  account?: string;
  noRecording?: boolean;
  status: MeetingRecordingStatues;
  meetingChat: string;
  meetingTranscript: string;
  note: string;
  visible: boolean;
  systemGenerated: boolean;
  transcriptFiles?: {
    data: ApiResponseData<UploadedFile>[];
  };
  chatFiles?: {
    data: ApiResponseData<UploadedFile>[];
  };
  students?: {
    data: ApiResponseData<Student>[];
  };
  teachers?: {
    data: ApiResponseData<Teacher>[];
  };
  agents?: {
    data: ApiResponseData<Admin>[];
  };
  register?: {
    data: ApiResponseData<Register>;
  };
  class?: {
    data: ApiResponseData<Class>;
  };
  reason?: string;
  external_contacts?: { data: ApiResponseData<ExternalContact>[] };
};

export type UpdateMeetingRecord = Omit<
  Partial<MeetingRecord>,
  'createdAt' | 'updatedAt' | 'students' | 'teachers' | 'agents'
> & {
  students?: string[];
  teachers?: string[];
  agents?: string[];
  register?: string;
  class?: string;
  transcriptFiles?: File[];
  chatFiles?: File[];
};

export enum MeetingRecordingStatues {
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
  STUCK = 'stuck'
}

export enum MeetingRecordTypes {
  LESSON = 'lesson',
  COACHING = 'coaching',
  MEETING = 'meeting',
  INTERVIEW = 'interview',
  CLIENT_CALL = 'client_call',
  TUTORIAL = 'tutorial',
  OTHER = 'other'
}

export interface Country {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  image?: string;
}

export type Member = any & { id?: string; key?: string };

export interface ParentAccount {
  User: User;
  Parent: Parent;
}

export type Account = ParentAccount;

export type TeacherCapability = {
  type: string;
  createdAt: string;
  updatedAt: string;
  teacher: Teacher;
  education_level: EducationLevel;
  subject: Subject;
};

export enum StudentStatuses {
  new = 'new',
  archived = 'archived',
  invalid = 'invalid',
  actionRequired = 'actionRequired',
  inProcess = 'inProcess',
  postponed = 'postponed',
  active = 'active',
  inactive = 'inactive'
}

export type EducationLevel = {
  id: number;
  name: string;
  otherName: string;
  order: number;
  createdAt: string;
  updatedAt: string;
};

export type ConfirmPaymentRequestPayload = {
  studentId: string;
  payments: ConfirmPaymentRequestPayloadPayment[];
  options?: ConfirmPaymentOptions;
};

export type ConfirmPaymentRequestPayloadPayment = {
  parentId: string;
  firstPaymentDate: string;
  invoiceDate: Date;
  dueLaterDate: Date;
  paymentStatus: string;
  paymentFrequency: any;
  items: ConfirmPaymentRequestPayloadPaymentItem[];
};

export type ConfirmPaymentRequestPayloadPaymentItem = {
  type: 'booking' | 'trial';
  paymentSubscriptionItemId?: string; // if exists then update it's values and don't create a new subscription.
  studentClassId?: string; // if null that means it's a new student class
  yearId: string;
  subjectId: string;
  scholarshipId: string;
  multiClassDiscount: string;
  scholarshipDiscount: string;
  managementDiscount: string;
  totalRate: string;
  reasonToChange?: string;
  monthly: string;
  startAt: string; // trial date or start date
  classId: string;
  date?: string; // required if changed the status to be cancelled.
  statusReason: string; // if changed the class id  or cancelled for any reason here you can add the reason
  status?: StudentClassStatus | 'updated'; // set as updated to update the values for subscription
  postponeDate?: string;
  postponeReason?: string;
  agents?: string[];
  classType?: ClassTypes;
  cancellationRequestDate?: string;
  cancellationPending?: boolean;
  paymentFrequency?: PaymentFrequencies;
  effectiveDay?: string;
};

export class InvoiceStatues {
  public static readonly NEW: string = 'new';
  public static readonly SENT: string = 'sent';
  public static readonly PAID: string = 'paid';
  public static readonly CANCELLED: string = 'cancelled';
  public static readonly FAILED: string = 'failed';
}

export type IssueReason = {
  name: string;
  group: 'student' | 'class';
};

export enum AdditionalPermissions {
  MEETING_RECORDING = 'meeting-recording'
}

export type EventMessage = {
  eventCode: string;
  receiverId: string;
  message: string;
  template: string;
  entity: string;
  entityId: string;
  eventType: EventMessageEventTypes;
  persist: boolean;
  messageValues: Record<string, any>;
  createdAt: string;
  updatedAt: string;
};

enum EventMessageEventTypes {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  QUESTION = 'question'
}
export type HTTPErrorResponse = {
  data: {
    data: any;
    error: {
      details: {
        errors: {
          status: string;
          source: { pointer: string };
          title: string;
          detail: string;
          code: ErrorCodes;
        }[];
      };
      message: string;
      name: string;
      status: number;
    };
  };
  status: number;
};

export type ClassStudent = {
  id: number;
  name: string;
  avatarImageName?: any;
  classStatus: ClassStudentClassStatuses;
  adminStatus: string;
};

export type ClassStudentClassStatuses = 'trial' | 'cancelling' | 'active' | 'pending' | 'credit';

export type GetTeacherClassesResponse = ApiResponse<ApiResponseData<TeacherClass>[]>;

export type TeacherClass = {
  class: { data: ApiResponseData<Class> };
  classId: string;
  createdAt: string;
  type: TeacherClassTypes;
  teacher: {
    data: ApiResponseData<Teacher>;
  };
  updatedAt: string;
};

export class TeacherClassTypes {
  public static readonly ACTIVE: string = 'active';
  public static readonly COVER: string = 'cover';
  public static readonly ARCHIVE: string = 'archive';
}

export class RegisterStatues {
  public static readonly UPCOMING: string = 'upcoming';
  public static readonly OPEN: string = 'open';
  public static readonly MISSING: string = 'missing';
  public static readonly COMPLETED: string = 'completed';
  public static readonly ABSENT: string = 'absent';
  public static readonly CANCELLED: string = 'cancelled';
}

export interface IUpdateRegisteredStudentsPayload {
  data?: RegisterStudent[];
  attendanceDetails?: { registerId: number; teacherId: number };
  classTopics?: any;
}

export type EntityId = string | number;

export interface IStudentFeed {
  id: string;
  name: string;
  title: string;
  type: FeedTypes;
  register?: any;
  register_student?: any;
  class?: any;
  student?: any;
  createdAt: string;
  updatedAt: string;
}

export enum FeedTypes {
  homeworkMarked = 'homeworkMarked',
  newHomework = 'newHomework',
  missingHomework = 'missingHomework',
  lessonMissed = 'lessonMissed',
  reviewLesson = 'reviewLesson',
  starStudent = 'starStudent',
  behaviourIssue = 'behaviourIssue'
}
export interface IUpcomingLesson {
  type: StudentClassStatus;
  classDate: string;
  classStartTime: string;
  classEndTime: string;
  classId: string;
  subjectName: string;
  subjectColour: string;
  subjectCode: string;
  zoomLink: string;
  zoomMeetingId: string;
  zoomMeetingPasscode: string;
  teacherName: string;
  studentId: string;
  studentName: string;
  registerId: string;
}

export interface IUpcomingLessonsRequestPayload {
  studentsIds: string[];
  classIds?: string[];
}

export interface IGetFeedsRequestPayload {
  studentIds: string[];
  classIds?: string[];
  populate: Record<string, any>;
  fields: string[];
  pagination: any;
}

export enum DayOfWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

export type FindByEntityIdResponse = ApiResponse<{
  familyLinkId: number;
  studentIds: number[];
  parentIds: number[];
}>;

export type FindByEntityIdPRequestQuery = {
  type: 'student' | 'parent' | string;
  entityId: string;
};

export type LinkRequestQuery = {
  parentIds: string[];
  studentIds: string[];
  entityId: string;
  entityType: string;
  mergeFamilyLinks?: boolean;
};

export type LinkResponse = {};

export type UnLinkByEntityIdPayload = {
  type: 'parent' | 'student';
  entityId: string;
  entityTypeToUnlink: 'parent' | 'student';
  entityIdsToUnlink: string[];
};

export enum EntityTypes {
  TEACHER = 'teacher',
  STUDENT = 'student',
  AGENT = 'admin'
}

export enum ClassTypes {
  GROUP = 'group',
  PRIVATE = 'private'
}

export type MeetingAccount = {
  id: string;
  name: string;
  password: string;
};

export type ExamBoards = {
  id: string;
  name: string;
};

export type SubjectLevel = {
  id: string;
  name: string;
};

export type SubjectTopics = {
  id: string;
  name: string;
  parent_topic: { data: { attributes: SubjectTopics } };
};

export type Topic = {
  id: string;
  topicId: string;
  name: string;
  category: string;
  description?: string;
  subject?: {
    data: ApiResponseData<Subject>;
  };
  years?: {
    data: ApiResponseData<Year>[];
  };
  exam_boards?: {
    data: ApiResponseData<any>[];
  };

  topic_links?: {
    data: ApiResponseData<any>[];
  };
};

export type ClassTopic = {
  id: string;
  taught: boolean;
  disabled: boolean;
  topic?: {
    data: ApiResponseData<Topic>;
  };
  class?: {
    data: ApiResponseData<Class>;
  };
  register?: {
    data: ApiResponseData<Register>;
  };
};

export type CreateClassTopicRequestBody = {};

export type UpdateTopicRequestBody = {};

export enum PaymentFrequencies {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  WEEKLY4 = 'weekly4',
  WEEKLY5 = 'weekly5',
  WEEKLY2 = 'weekly2',
  MONTHLY3 = 'monthly3'
}

export enum ErrorCodes {
  EMAIL_EXISTS = 41114
}

export type StudentClassLog = {
  name: string;
  status: string;
  /**
   * current student class state data encoded
   */
  rawData: string;
  /**
   * previous student class state data encoded
   */
  previousRawData: string;
  student_class: {
    data: ApiResponseData<StudentClass>;
  };
};

export type Group = {
  id: string;
  name: string;
  type: 'student';
  order: number;
};

export type Ability = {
  subject: string;
  studentAbilityGroup: { name: string; type: string }[];
};

export type StudentAbilityGroup = {
  id: number;
  name: string;
  order: number;
  createdAt: string;
  updatedAt: string;
};

export enum HomeworkTypes {
  FILE = 'file',
  MATERIAL = 'material'
}

export type ConfirmPaymentOptions = {
  ignoreSendEmails?: boolean;
};
