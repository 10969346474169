import { createApi } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import qs from 'qs';
import { staggeredBaseQueryWithBailOut } from './interceptors/fetchBaseQuery';
import {
  ApiResponse,
  ApiResponseData,
  Class,
  ClassDatesResponse,
  ConfirmPaymentRequestPayload,
  GetAdminsResponse,
  GetAvailableClassesRequestPayload,
  GetAvailableClassesRequestResponse,
  GetClassSubjectsResponse,
  GetClassYearsResponse,
  GetParentsResponse,
  GetQueryPayload,
  GetScholarshipsResponse,
  GetStudentClassesResponse,
  GetStudentClassResponse,
  GetStudentScholarshipResponse,
  GetStudentScholarshipsResponse,
  GetStudentsResponse,
  GetTeacherPaymentsResponse,
  Invoice,
  InvoiceItem,
  PaymentSubscription,
  PaymentSubscriptionItem,
  RequestQuery,
  Subject
} from './types';

export const adminApi = createApi({
  reducerPath: 'admin-api',
  baseQuery: staggeredBaseQueryWithBailOut(`${process.env.REACT_APP_API_URL}`),
  tagTypes: ['User', 'RegistersMarkings', 'StudentClass', 'PaymentSubscription'],
  endpoints: (builder) => ({
    getStudents: builder.query<GetStudentsResponse, GetQueryPayload>({
      query: (payload) => ({
        url: `/api/students?${payload.requestQuery}`,
        method: 'GET'
      })
    }),
    getStudentClasses: builder.query<GetStudentClassesResponse, GetQueryPayload>({
      providesTags: ['StudentClass', 'PaymentSubscription'],
      query: (payload) => {
        return {
          url: `/api/student-classes?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    studentClassMarkAsPaid: builder.mutation<void, { paymentSubscriptionId: string }>({
      invalidatesTags: ['PaymentSubscription', 'StudentClass'],
      query: (body) => {
        return {
          url: `/api/student-classes/paid`,
          method: 'POST',
          body
        };
      }
    }),
    getClassYears: builder.query<GetClassYearsResponse, GetQueryPayload>({
      query: (payload) => {
        const reqQuery = payload.requestQuery ? qs.parse(payload.requestQuery) : {};

        return {
          url: `/api/years?${qs.stringify({ sort: ['order:asc'], ...reqQuery }) || ''}`,
          method: 'GET'
        };
      }
    }),
    getClassSubjects: builder.query<GetClassSubjectsResponse, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/subjects?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    getClassesForSales: builder.query<GetAvailableClassesRequestResponse, GetAvailableClassesRequestPayload>({
      query: (payload) => {
        const query = qs.stringify(
          {
            studentId: payload.studentId,
            subjectId: payload.subjectId,
            yearId: payload.yearId,
            classType: payload.classType,
            classId: payload.classId,
            options: payload.options
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/student-classes/getClassesForSales?${query}`,
          method: 'GET'
        };
      }
    }),

    getScholarships: builder.query<GetScholarshipsResponse, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/scholarships?${payload.requestQuery || ''}`,
          method: 'GET'
        };
      }
    }),

    createStudentClass: builder.mutation<GetStudentClassResponse, { studentClassData: any }>({
      query: (body) => ({
        url: `/api/student-classes`,
        method: 'POST',
        body: { data: body.studentClassData }
      })
    }),

    updateStudentClass: builder.mutation<GetStudentClassResponse, { data: any; id: string }>({
      query: (body) => ({
        url: `/api/student-classes/${body.id}`,
        method: 'PUT',
        body: { data: body.data }
      })
    }),
    createStudentScholarship: builder.mutation<GetStudentScholarshipResponse, { data: any }>({
      query: (body) => ({
        url: `/api/student-scholarships`,
        method: 'POST',
        body: { data: body.data }
      })
    }),
    resendScholarShipRequest: builder.mutation<any, string>({
      query: (id) => ({
        url: `/api/student-scholarships/${id}/resend`,
        method: 'PUT'
      })
    }),
    GetStudentScholarship: builder.query<GetStudentScholarshipsResponse, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/student-scholarships?${payload.requestQuery}`,
          method: 'GET'
        };
      },
      transformResponse: (response: any) => {
        if (response.data.length > 0) {
          return {
            id: response.data[0].id,
            ...response.data[0].attributes
          };
        } else return { status: null };
      }
    }),

    getAdmins: builder.query<GetAdminsResponse, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/admins?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    getClassDates: builder.query<ClassDatesResponse, string>({
      query: (id) => {
        const query = qs.stringify({
          filters: {
            classDate: {
              $gte: moment().format('YYYY-MM-DD')
            },
            class: {
              id: {
                $eq: id
              }
            }
          },
          fields: ['classDate']
        });
        return {
          url: `/api/registers?${query}`,
          method: 'GET'
        };
      }
    }),
    getSubjectRate: builder.query<
      {
        hourlyRate: number;
        weeklyRate: number;
        monthlyRate: number;
      },
      { studentId: string; subjectId: string; yearId: string }
    >({
      query: (payload) => {
        return {
          url: `/api/helper/getSubjectRate?${qs.stringify(payload)}`,
          method: 'GET'
        };
      }
    }),
    getInvoices: builder.query<ApiResponse<ApiResponseData<Invoice>[]>, RequestQuery>({
      query: (payload) => {
        const requestQuery = qs.stringify(payload, {
          encodeValuesOnly: true
        });
        return {
          url: `/api/invoices?${requestQuery}`,
          method: 'GET'
        };
      }
    }),
    createInvoice: builder.mutation<ApiResponse<ApiResponseData<Invoice>>, Invoice>({
      query: (payload) => {
        return {
          url: '/api/invoices',
          method: 'POST',
          body: { data: payload }
        };
      }
    }),
    updateInvoice: builder.mutation<ApiResponse<ApiResponseData<Invoice>>, Invoice>({
      query: (payload) => {
        return {
          url: `/api/invoices/${payload.id}`,
          method: 'PUT',
          body: { data: payload }
        };
      }
    }),
    getInvoiceItems: builder.query<ApiResponse<ApiResponseData<InvoiceItem>[]>, RequestQuery>({
      query: (payload) => {
        const requestQuery = qs.stringify(payload, {
          encodeValuesOnly: true
        });
        return {
          url: `/api/invoice-items?${requestQuery}`,
          method: 'GET'
        };
      }
    }),
    createInvoiceItems: builder.mutation<ApiResponse<ApiResponseData<InvoiceItem>>, InvoiceItem>({
      query: (payload) => {
        return {
          url: '/api/invoice-items',
          method: 'POST',
          body: { data: payload }
        };
      }
    }),
    updateInvoiceItems: builder.mutation<ApiResponse<ApiResponseData<InvoiceItem>>, InvoiceItem>({
      query: (payload) => {
        return {
          url: `/api/invoice-items/${payload.id}`,
          method: 'PUT',
          body: { data: payload }
        };
      }
    }),
    payInvoice: builder.mutation<{}, { studentId: string; invoiceId: string }>({
      query: (payload) => {
        return {
          url: `/api/helper/${payload.invoiceId}/paid`,
          method: 'PUT',
          body: { data: payload }
        };
      }
    }),
    cancelInvoice: builder.mutation<{}, { studentId: string; invoiceId: string }>({
      query: (payload) => {
        return {
          url: `/api/helper/${payload.invoiceId}/cancel`,
          method: 'PUT',
          body: { data: payload }
        };
      }
    }),
    sendInvoice: builder.mutation<{}, { studentId: string; invoiceId: string }>({
      query: (payload) => {
        return {
          url: `/api/helper/${payload.invoiceId}/send`,
          method: 'PUT',
          body: { data: payload }
        };
      }
    }),
    getSubjects: builder.query<ApiResponse<ApiResponseData<Subject>[]>, GetQueryPayload>({
      query: (payload) => ({
        url: `/api/subjects?${payload.requestQuery || ''}`,
        method: 'GET'
      })
    }),
    getSingleClass: builder.query<any, string>({
      query: (classId) => {
        const query = qs.stringify(
          {
            populate: '*'
          },
          {
            encodeValuesOnly: true
          }
        );

        return {
          url: `/api/classes/${classId}?${query}`,
          method: 'GET'
        };
      },
      transformResponse: (response: any) => ({
        ...response?.data?.attributes,
        subject: response?.data?.attributes.subject?.data?.attributes?.name,
        yearGroup: response?.data?.attributes.year?.data?.attributes?.shortName,
        progress: `${response?.data?.attributes.progress}%`,
        teacher: response?.data?.attributes?.teacher?.data?.attributes
      })
    }),
    getPaymentSubscriptions: builder.query<ApiResponse<ApiResponseData<PaymentSubscription>[]>, RequestQuery>({
      query: (payload) => {
        const requestQuery = qs.stringify(payload, {
          encodeValuesOnly: true
        });
        return {
          url: `/api/payment-subscriptions?${requestQuery}`,
          method: 'GET'
        };
      }
    }),
    createPaymentSubscription: builder.mutation<ApiResponse<ApiResponseData<PaymentSubscription>>, PaymentSubscription>(
      {
        query: (payload) => {
          return {
            url: '/api/payment-subscriptions',
            method: 'POST',
            body: { data: payload }
          };
        }
      }
    ),
    updatePaymentSubscription: builder.mutation<ApiResponse<ApiResponseData<PaymentSubscription>>, PaymentSubscription>(
      {
        query: (payload) => {
          return {
            url: `/api/payment-subscriptions/${payload.id}`,
            method: 'PUT',
            body: { data: payload }
          };
        }
      }
    ),
    deletePaymentSubscription: builder.mutation<
      ApiResponse<ApiResponseData<PaymentSubscription>>,
      Pick<PaymentSubscription, 'id'>
    >({
      query: (payload) => {
        return {
          url: `/api/payment-subscriptions/${payload.id}`,
          method: 'DELETE'
        };
      }
    }),
    getPaymentSubscriptionItems: builder.query<ApiResponse<ApiResponseData<PaymentSubscriptionItem>[]>, RequestQuery>({
      query: (payload) => {
        const requestQuery = qs.stringify(payload, {
          encodeValuesOnly: true
        });
        return {
          url: `/api/payment-subscription-items?${requestQuery}`,
          method: 'GET'
        };
      }
    }),
    createPaymentSubscriptionItem: builder.mutation<
      ApiResponse<ApiResponseData<PaymentSubscriptionItem>>,
      PaymentSubscriptionItem
    >({
      query: (payload) => {
        return {
          url: '/api/payment-subscription-items',
          method: 'POST',
          body: { data: payload }
        };
      }
    }),
    updatePaymentSubscriptionLine: builder.mutation<
      ApiResponse<ApiResponseData<PaymentSubscriptionItem>>,
      PaymentSubscriptionItem
    >({
      query: (payload) => {
        return {
          url: `/api/payment-subscription-items/${payload.id}`,
          method: 'PUT',
          body: { data: payload }
        };
      }
    }),
    deletePaymentSubscriptionLine: builder.mutation<
      ApiResponse<ApiResponseData<PaymentSubscriptionItem>>,
      Pick<PaymentSubscriptionItem, 'id'>
    >({
      query: (payload) => {
        return {
          url: `/api/payment-subscription-items/${payload.id}`,
          method: 'DELETE'
        };
      }
    }),
    confirmPayment: builder.mutation<any, { payload: ConfirmPaymentRequestPayload }>({
      query: (payload) => {
        return {
          url: `/api/student-classes/confirm-payment`,
          method: 'POST',
          body: payload
        };
      }
    }),
    getClasses: builder.query<ApiResponse<ApiResponseData<Class>[]>, GetQueryPayload>({
      query: (payload) => ({
        url: `/api/classes?${payload.requestQuery}`,
        method: 'GET'
      })
    }),
    getParents: builder.query<GetParentsResponse, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/parents?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    getTeacherPayments: builder.query<GetTeacherPaymentsResponse, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/teacher-payments?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useLazyGetStudentClassesQuery,
  useGetClassYearsQuery,
  useGetClassSubjectsQuery,
  useLazyGetClassSubjectsQuery,
  useGetClassesForSalesQuery,
  useLazyGetClassesForSalesQuery,
  useCreateStudentClassMutation,
  useUpdateStudentClassMutation,
  useLazyGetScholarshipsQuery,
  useGetScholarshipsQuery,
  useCreateStudentScholarshipMutation,
  useResendScholarShipRequestMutation,
  useGetStudentScholarshipQuery,
  useLazyGetStudentScholarshipQuery,
  useGetAdminsQuery,
  useLazyGetAdminsQuery,
  useGetClassDatesQuery,
  useLazyGetClassDatesQuery
} = adminApi;
