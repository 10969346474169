import { createApi } from '@reduxjs/toolkit/query/react';
import { staggeredBaseQueryWithBailOut } from 'app/services/interceptors/fetchBaseQuery';

const api = createApi({
  reducerPath: 'api',
  baseQuery: staggeredBaseQueryWithBailOut(`${process.env.REACT_APP_API_URL}`),
  tagTypes: [
    'User',
    'RegistersMarkings',
    'Lesson',
    'FamilyLinks',
    'Student',
    'Teacher',
    'TeacherPayment',
    'Subject',
    'StudentFeed',
    'Auth',
    'Class',
    'EducationLevel',
    'EventMessage',
    'IssueReason',
    'Meeting',
    'Notice',
    'Parent',
    'Register',
    'RegisterStudent',
    'StudentScholarship',
    'Years',
    'MeetingAccount',
    'Topic',
    'All',
    'StudentClass',
    'PaymentSubscription',
    'Note',
    'AbilityGroup',
    'LessonMaterial'
  ],
  endpoints: (builder) => ({})
});

export default api;
