import styled from 'styled-components';
import Spinner from '../Spinner';

const ContainerStyled = styled.div`
  position: relative;
  display: block;
`;

const SpinnerContainerStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(222, 222, 222, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100010;
  top: 0;
  left: 0;
`;

const Container = (props: React.PropsWithChildren<IProps>) => {
  return (
    <ContainerStyled className={props.className}>
      {props.children}
      {props.isLoading && <SpinnerContainer />}
    </ContainerStyled>
  );
};

const SpinnerContainer = () => {
  return (
    <SpinnerContainerStyled>
      <Spinner />
    </SpinnerContainerStyled>
  );
};

export default Container;

interface IProps {
  isLoading?: boolean;
  className?: string;
}
