import { AppLayout } from 'AppLayout';
import { message } from 'antd';
import { ClassCheckersDetailsPage } from 'app/features/admin-dashboard/school-management/class-checkers/utils/details-page/ClassCheckersDetailsPage';
import { ClassCheckersRegister } from 'app/features/admin-dashboard/school-management/class-checkers/utils/details-page/ClassCheckersRegister';
import ResetPasswordPage from 'app/features/authentication/reset-password';
import { SelectUserType } from 'app/features/authentication/signup';
import { LessonSchedule } from 'app/features/lesson-schedule/lesson-schedule';
import { AdditionalPermissions } from 'app/services/types';
import GuestAuth from 'app/utils/GuestAuth';
import RequireAuth from 'app/utils/RequireAuth';
import _ from 'lodash';
import { Suspense, lazy } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactModal from 'react-modal';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import { MAIN_SELECTORS, store } from './app/store';
import ExternalContacts from 'app/features/admin-dashboard/databases/external-contacts/list';

const MeetingAccounts = lazy(() =>
  import('app/features/meeting-account/meeting-accounts').then((e) => ({
    default: e.MeetingAccounts
  }))
);

const StudentHomeworkErrorsList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.HomeworkErrorsList
  }))
);
const AdminTeacherParentMessagesList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.MessagesList
  }))
);
const AdminFreeTrialsList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.FreeTrialsList
  }))
);
const AdminRegistersList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.RegistersList
  }))
);
const AdminRegisterDetails = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.RegisterDetails
  }))
);
const AdminNoticeList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.NoticeList
  }))
);
const AdminMeetingRecordingsList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.MeetingList
  }))
);

const AdminClassroomslist = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.ClassList
  }))
);
const AdminViewTeacher = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.ViewTeacher
  }))
);

const AdminTeacherList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.TeacherList
  }))
);
const AdminParentsList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.ParentList
  }))
);

const AdminStudentsList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.StudentList
  }))
);

const AdminWeeklyPayments = lazy(() =>
  import('app/features/admin-dashboard/payments/WeeklyPayments').then((e) => ({
    default: e.WeeklyPayments
  }))
);

const AdminTeacherPaymentsGroup = lazy(() =>
  import('app/features/admin-dashboard/payments/TeacherPaymentGroup').then((e) => ({
    default: e.TeacherPaymentGroup
  }))
);

const AdminTeacherPaymentsClasses = lazy(() =>
  import('app/features/admin-dashboard/payments/TeacherClasses').then((e) => ({
    default: e.TeacherClasses
  }))
);

const AdminClassCheckersList = lazy(() =>
  import('app/features/admin-dashboard/public-api').then((e) => ({
    default: e.ClassCheckersList
  }))
);

const UiKit = lazy(() => import('app/components/v1/UiKit'));
const Signin = lazy(() => import('./app/features/authentication/signin'));
const AuthenticationLayout = lazy(() => import('./app/layouts/Authentication'));

const SignupAsParent = lazy(() => import('app/features/authentication/createAccountAsParent'));
const StudentSubject = lazy(() => import('app/features/student-dashboard/subject/show-subject'));
const ParentStudentSubject = lazy(() => import('app/features/parent-dashboard/subject/show-subject'));
const StudentFeed = lazy(() => import('app/features/student-dashboard/subject/show-subject/feed'));
const ParentStudentFeed = lazy(() => import('app/features/parent-dashboard/subject/show-subject/feed'));

const StudentHomeworks = lazy(() => import('app/features/student-dashboard/subject/show-subject/homeworks'));
const ParentStudentHomeworks = lazy(() => import('app/features/parent-dashboard/subject/show-subject/homeworks'));

const StudentLessons = lazy(() => import('app/features/student-dashboard/subject/show-subject/lessons'));
const ParentStudentLessons = lazy(() => import('app/features/parent-dashboard/subject/show-subject/lessons'));

const SubjectPerformance = lazy(() => import('app/features/student-dashboard/subject/show-subject/performance'));
const ParentStudentSubjectPerformance = lazy(
  () => import('app/features/parent-dashboard/subject/show-subject/performance')
);
const Timetable = lazy(() => import('app/features/timetable'));

const LessonRegister = lazy(() => import('app/features/shared/lesson/register'));
const LessonHomeworks = lazy(() => import('app/features/shared/lesson/homeworks'));

const LessonResources = lazy(() => import('app/features/shared/lesson/resources'));
const TeacherLessonPage = lazy(() => import('app/features/teacher-dashboard/lesson'));
const MarkerLessonPage = lazy(() => import('app/features/marker-dashboard/lesson'));

const StudentLessonPage = lazy(() => import('app/features/student-dashboard/lesson-page'));
const ParentStudentLesson = lazy(() => import('app/features/parent-dashboard/lesson-page'));

const VerifyEmail = lazy(() => import('app/features/authentication/verify-email'));

const AccountConfirmation = lazy(() => import('app/features/authentication/account-confirmation'));

const SignupAsStudent = lazy(() => import('app/features/authentication/createAccountAsStudent'));

const ForgotPassword = lazy(() => import('app/features/authentication/forgot-password'));

const SignupAsTeacher = lazy(() => import('app/features/authentication/createAccountAsTeacher'));

const AdminSalesDashboard = lazy(() => import('app/features/admin-dashboard/sales'));

const TeacherDashboard = lazy(() => import('app/features/teacher-dashboard'));
const TeacherLayout = lazy(() => import('app/layouts/TeacherLayout'));
const StudentLayout = lazy(() => import('./app/layouts/StudentLayout'));
const StudentDashboard = lazy(() => import('./app/features/student-dashboard'));
const ParentLayout = lazy(() => import('app/layouts/ParentLayout'));
const ParentDashboard = lazy(() => import('app/features/parent-dashboard'));
const MarkerDashboard = lazy(() => import('app/features/marker-dashboard'));
const AllClasses = lazy(() => import('app/features/classes'));
const LessonCurriculum = lazy(() => import('app/features/shared/lesson/curriculum'));
const TeacherProfile = lazy(() => import('app/features/teacher-dashboard/profile'));
const ProfileDetails = lazy(() => import('app/features/teacher-dashboard/profile/profile-details'));
const TeacherClasses = lazy(() => import('app/features/teacher-dashboard/classes'));
const PersonalDetails = lazy(() => import('app/features/teacher-dashboard/profile/personal-details'));
const HomeworkMarkingsTable = lazy(() => import('app/features/markers/homeworks-markings/HomeworkMarkingsTable'));
const HomeworkMarkingsView = lazy(
  () => import('app/features/parent-dashboard/markers/homeworks-markings/HomeworkMarkingsTable')
);
const StudentProfile = lazy(() => import('app/features/student-dashboard/profile'));

const ParentProfile = lazy(() => import('app/features/parent-dashboard/profile'));
const ParentProfileDetails = lazy(() => import('app/features/parent-dashboard/profile/profile'));
const ParentStudentProfileDetails = lazy(() => import('app/features/parent-dashboard/profile/student'));
const ParentParentProfileDetails = lazy(() => import('app/features/parent-dashboard/profile/parent'));
const BillingDetails = lazy(() => import('app/features/parent-dashboard/billing-details'));
const AdminViewStudent = lazy(() => import('app/features/admin-dashboard/databases/students/student/AdminViewStudent'));
const AdminViewParent = lazy(() => import('app/features/admin-dashboard/databases/parents/parent/AdminViewParent'));
const AdminViewClassroom = lazy(
  () => import('app/features/admin-dashboard/databases/classrooms/classroom/AdminViewClassRoom')
);
const NotFound = lazy(() => import('app/features/not-found/NotFound'));
const AdminLayout = lazy(() => import('app/layouts/AdminLayout'));
const AdminDashboard = lazy(() => import('app/features/admin-dashboard'));
const ClassPage = lazy(() => import('app/features/single-class'));
const TeacherCoverage = lazy(() => import('app/features/teacher-coverage/List'));
const ParentPayments = lazy(() => import('app/features/parent-payments/List'));

ReactModal.setAppElement('#root');
message.config({
  prefixCls: 'ant-message'
});
const ProtectedRoute = ({ children, path }: any) => {
  const user = useSelector(MAIN_SELECTORS.user);

  if (!user?.admin?.isOwner && path.includes('/payments')) {
    return <NotFound />;
  }

  if (_.toLower(user?.userType) === 'teacher' && path.includes('/markers')) {
    return <NotFound />;
  }

  if (
    _.toLower(user?.userType) === 'admin' &&
    user.additionalPermission === AdditionalPermissions.MEETING_RECORDING &&
    !path.includes('/meeting-recordings')
  ) {
    return <NotFound />;
  }

  return children;
};

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <CookiesProvider>
          <AppLayout>
            <Routes>
              {/* Admin */}
              <Route
                element={
                  <RequireAuth>
                    <Suspense fallback={<div></div>}>
                      <AdminLayout />
                    </Suspense>
                  </RequireAuth>
                }
              >
                {/* <Route path="/" element={<Navigate replace to="/admin" />} /> */}
                <Route
                  path="/admin"
                  element={
                    <Suspense fallback={<div></div>}>
                      <AdminDashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/admin/class"
                  element={
                    <ProtectedRoute path="/admin/class">
                      <Suspense fallback={<div></div>}>
                        <ClassPage />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/students"
                  element={
                    <ProtectedRoute path="/admin/students">
                      <Suspense fallback={<div></div>}>
                        <AdminStudentsList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/admin/students/:studentId"
                  element={
                    <ProtectedRoute path="/admin/students/:studentId">
                      <Suspense fallback={<div></div>}>
                        <AdminViewStudent />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/students/sales/:studentId"
                  element={
                    <ProtectedRoute path="/admin/students/sales/:studentId">
                      <Suspense fallback={<div></div>}>
                        <AdminSalesDashboard />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/parents"
                  element={
                    <ProtectedRoute path="/admin/parents">
                      <Suspense fallback={<div></div>}>
                        <AdminParentsList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/parents/:parentId"
                  element={
                    <ProtectedRoute path="/admin/parents/:parentId">
                      <Suspense fallback={<div></div>}>
                        <AdminViewParent />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/teachers"
                  element={
                    <ProtectedRoute path="/admin/teachers">
                      <Suspense fallback={<div></div>}>
                        <AdminTeacherList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/teachers/new"
                  element={
                    <ProtectedRoute path="/admin/teachers/new">
                      <Suspense fallback={<div></div>}>
                        <AdminViewTeacher />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/teachers/:teacherId"
                  element={
                    <ProtectedRoute path="/admin/teachers/:teacherId">
                      <Suspense fallback={<div></div>}>
                        <AdminViewTeacher />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/classrooms"
                  element={
                    <ProtectedRoute path="/admin/classrooms">
                      <Suspense fallback={<div></div>}>
                        <AdminClassroomslist />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/external-contacts"
                  element={
                    <ProtectedRoute path="/admin/external-contacts">
                      <Suspense fallback={<div></div>}>
                        <ExternalContacts />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/classrooms/:classId"
                  element={
                    <ProtectedRoute path="/admin/classrooms/:classId">
                      <Suspense fallback={<div></div>}>
                        <AdminViewClassroom />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                {/** Class Checkers */}
                <Route
                  path="/admin/class-checkers"
                  element={
                    <ProtectedRoute path="/admin/class-checkers">
                      <Suspense fallback={<div></div>}>
                        <AdminClassCheckersList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/class-checkers/:registerId"
                  element={
                    <ProtectedRoute path="/admin/class-checkers/:registerId">
                      <Suspense fallback={<div></div>}>
                        <ClassCheckersDetailsPage />
                      </Suspense>
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="register"
                    element={
                      <ProtectedRoute path="register">
                        <Suspense fallback={<div></div>}>
                          <ClassCheckersRegister />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                </Route>
                {/** Meeting Records */}
                <Route
                  path="/admin/meeting-recordings"
                  element={
                    <ProtectedRoute path="/admin/meeting-recordings">
                      <Suspense fallback={<div></div>}>
                        <AdminMeetingRecordingsList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                {/** Notices */}
                <Route
                  path="/admin/notices"
                  element={
                    <ProtectedRoute path="/admin/notices">
                      <Suspense fallback={<div></div>}>
                        <AdminNoticeList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                {/** Free trials */}
                <Route
                  path="/admin/trial-attendance"
                  element={
                    <ProtectedRoute path="/admin/trial-attendance">
                      <Suspense fallback={<div></div>}>
                        <AdminFreeTrialsList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                {/** Registers */}
                <Route
                  path="/admin/registers"
                  element={
                    <ProtectedRoute path="/admin/registers">
                      <Suspense fallback={<div></div>}>
                        <AdminRegistersList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                {/* Lesson Schedule */}
                <Route
                  path="/admin/lesson-schedule"
                  element={
                    <ProtectedRoute path="/admin/lesson-schedule">
                      <Suspense fallback={<div></div>}>
                        <LessonSchedule />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/registers/:lessonId"
                  element={
                    <ProtectedRoute path="/admin/registers/:lessonId">
                      <Suspense fallback={<div></div>}>
                        <TeacherLessonPage />
                      </Suspense>
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="register"
                    element={
                      <ProtectedRoute path="register">
                        <Suspense fallback={<div></div>}>
                          <LessonRegister />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="curriculum"
                    element={
                      <ProtectedRoute path="curriculum">
                        <Suspense fallback={<div></div>}>
                          <LessonCurriculum />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="material"
                    element={
                      <ProtectedRoute path="material">
                        <Suspense fallback={<div></div>}>
                          <LessonResources />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                </Route>
                {/** teacher parent messages */}
                <Route
                  path="/admin/teacher-feedback"
                  element={
                    <ProtectedRoute path="/admin/teacher-feedback">
                      <Suspense fallback={<div></div>}>
                        <AdminTeacherParentMessagesList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/teacher-feedback/:feedbackId"
                  element={
                    <ProtectedRoute path="/admin/teacher-feedback/:feedbackId">
                      <Suspense fallback={<div></div>}>
                        <AdminTeacherParentMessagesList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/payments/weekly-payments"
                  element={
                    <ProtectedRoute path="/admin/payments/weekly-payments">
                      <Suspense fallback={<div></div>}>
                        <AdminWeeklyPayments />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/payments/weekly-payments/batch/:weeklyPaymentId"
                  element={
                    <ProtectedRoute path="/admin/payments/weekly-payments/batch/:weeklyPaymentId">
                      <Suspense fallback={<div></div>}>
                        <AdminTeacherPaymentsGroup />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/payments/weekly-payments/batch/:weeklyPaymentId/group/:paymentGroupId"
                  element={
                    <ProtectedRoute path="/admin/payments/weekly-payments/batch/:weeklyPaymentId/group/:paymentGroupId">
                      <Suspense fallback={<div></div>}>
                        <AdminTeacherPaymentsClasses />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                {/** homework errors */}
                {/* <Route
                  path="/admin/homework-errors"
                  element={
                    <ProtectedRoute path="/admin/homework-errors">
                      <Suspense fallback={<div></div>}>
                        <StudentHomeworkErrorsList />
                      </Suspense>
                    </ProtectedRoute>
                  }
                /> */}
                <Route
                  path="/admin/meeting-accounts"
                  element={
                    <ProtectedRoute path="/admin/meeting-accounts">
                      <Suspense fallback={<div></div>}>
                        <MeetingAccounts />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/teacher-coverage"
                  element={
                    <ProtectedRoute path="/admin//teacher-coverage">
                      <Suspense fallback={<div></div>}>
                        <TeacherCoverage />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/payments/parent-payments"
                  element={
                    <ProtectedRoute path="/admin/payments/parent-payments">
                      <Suspense fallback={<div></div>}>
                        <ParentPayments />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* Teacher */}
              <Route
                element={
                  <RequireAuth>
                    <Suspense fallback={<div></div>}>
                      <TeacherLayout />
                    </Suspense>
                  </RequireAuth>
                }
              >
                <Route
                  path="/teacher"
                  element={
                    <Suspense fallback={<div></div>}>
                      <TeacherDashboard />
                    </Suspense>
                  }
                />
                <Route path="/teacher/profile" element={<TeacherProfile />} />
                <Route
                  path="/teacher/classes"
                  element={
                    <Suspense fallback={<div></div>}>
                      <TeacherClasses />
                    </Suspense>
                  }
                />
                <Route
                  path="/teacher/classes/:classId"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ClassPage />
                    </Suspense>
                  }
                />
                {/* HIDDEN TEMPORARILY */}
                {/* <Route
              path="/teacher/payments"
              element={
                <Suspense fallback={<div></div>}>
                  <Payments />
                </Suspense>
              }
            /> */}
                <Route
                  path="/teacher/timetable"
                  element={
                    <Suspense fallback={<div></div>}>
                      <Timetable />
                    </Suspense>
                  }
                />
                <Route
                  path="/teacher/classes/:classId/lessons/:lessonId"
                  element={
                    <Suspense fallback={<div></div>}>
                      <TeacherLessonPage />
                    </Suspense>
                  }
                >
                  <Route
                    path="register"
                    element={
                      <Suspense fallback={<div></div>}>
                        <LessonRegister />
                      </Suspense>
                    }
                  />
                  <Route
                    path="curriculum"
                    element={
                      <Suspense fallback={<div></div>}>
                        <LessonCurriculum />
                      </Suspense>
                    }
                  />
                  <Route
                    path="material"
                    element={
                      <Suspense fallback={<div></div>}>
                        <LessonResources />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/teacher/markers"
                  element={
                    <ProtectedRoute path="/teacher/markers">
                      <Suspense fallback={<div></div>}>
                        <HomeworkMarkingsTable />
                      </Suspense>
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* Marker */}
              <Route
                element={
                  <RequireAuth>
                    <Suspense fallback={<div></div>}>
                      <TeacherLayout />
                    </Suspense>
                  </RequireAuth>
                }
              >
                <Route
                  path="/marker"
                  element={
                    <Suspense fallback={<div></div>}>
                      <MarkerDashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/marker/class"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ClassPage />
                    </Suspense>
                  }
                />
                <Route
                  path="/marker/classes"
                  element={
                    <Suspense fallback={<div></div>}>
                      <AllClasses />
                    </Suspense>
                  }
                />

                <Route
                  path="/marker/lesson"
                  element={
                    <Suspense fallback={<div></div>}>
                      <MarkerLessonPage />
                    </Suspense>
                  }
                >
                  <Route path="/marker/lesson" element={<Navigate replace to="register" />} />
                  <Route
                    path="register"
                    element={
                      <Suspense fallback={<div></div>}>
                        <LessonRegister />
                      </Suspense>
                    }
                  />
                  <Route
                    path="homeworks"
                    element={
                      <Suspense fallback={<div></div>}>
                        <LessonHomeworks />
                      </Suspense>
                    }
                  />
                  <Route
                    path="resources"
                    element={
                      <Suspense fallback={<div></div>}>
                        <LessonResources />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              {/* Student */}
              <Route
                element={
                  <RequireAuth>
                    <Suspense fallback={<div></div>}>
                      <StudentLayout />
                    </Suspense>
                  </RequireAuth>
                }
              >
                <Route
                  path="/student"
                  element={
                    <Suspense fallback={<div></div>}>
                      <StudentDashboard />
                    </Suspense>
                  }
                />

                <Route
                  path="/student/profile"
                  element={
                    <Suspense fallback={<div></div>}>
                      <StudentProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="/student/classes/archive"
                  element={
                    <Suspense fallback={<div></div>}>
                      <AllClasses />
                    </Suspense>
                  }
                />
                <Route
                  path="/student/class"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ClassPage />
                    </Suspense>
                  }
                />
                {/* <Route
              path="/student/payments"
              element={
                <Suspense fallback={<div></div>}>
                  <Payments />
                </Suspense>
              }
            /> */}
                <Route
                  path="/student/subject/:subjectId/year/:yearId"
                  element={
                    <Suspense fallback={<div></div>}>
                      <StudentSubject />
                    </Suspense>
                  }
                >
                  <Route
                    path="feed"
                    element={
                      <Suspense fallback={<div></div>}>
                        <StudentFeed />
                      </Suspense>
                    }
                  />
                  <Route
                    path="homeworks"
                    element={
                      <Suspense fallback={<div></div>}>
                        <StudentHomeworks />
                      </Suspense>
                    }
                  />
                  <Route
                    path="lessons"
                    element={
                      <Suspense fallback={<div></div>}>
                        <StudentLessons />
                      </Suspense>
                    }
                  />
                  <Route
                    path="performance"
                    element={
                      <Suspense fallback={<div></div>}>
                        <SubjectPerformance />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path="/student/subject/:subjectId/year/:yearId/lessons/:lessonId"
                  element={
                    <Suspense fallback={<div></div>}>
                      <StudentLessonPage />
                    </Suspense>
                  }
                />
                <Route
                  path="/student/timetable"
                  element={
                    <Suspense fallback={<div></div>}>
                      <Timetable />
                    </Suspense>
                  }
                />
              </Route>

              {/* Parent */}
              <Route
                element={
                  <RequireAuth>
                    <Suspense fallback={<div></div>}>
                      <ParentLayout />
                    </Suspense>
                  </RequireAuth>
                }
              >
                <Route
                  path="/parent"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ParentDashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/parent/classes/archive"
                  element={
                    <Suspense fallback={<div></div>}>
                      <AllClasses />
                    </Suspense>
                  }
                />
                <Route
                  path="/parent/class"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ClassPage />
                    </Suspense>
                  }
                />
                {/* <Route
              path="/parent/payments"
              element={
                <Suspense fallback={<div></div>}>
                  <Payments />
                </Suspense>
              }
            /> */}
                <Route
                  path="/parent/student/:studentId/subject/:subjectId/year/:yearId"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ParentStudentSubject />
                    </Suspense>
                  }
                >
                  <Route
                    path="feed"
                    element={
                      <Suspense fallback={<div></div>}>
                        <ParentStudentFeed />
                      </Suspense>
                    }
                  />
                  <Route
                    path="homeworks"
                    element={
                      <Suspense fallback={<div></div>}>
                        <ParentStudentHomeworks />
                      </Suspense>
                    }
                  />
                  <Route
                    path="lessons"
                    element={
                      <Suspense fallback={<div></div>}>
                        <ParentStudentLessons />
                      </Suspense>
                    }
                  />
                  <Route
                    path="performance"
                    element={
                      <Suspense fallback={<div></div>}>
                        <ParentStudentSubjectPerformance />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/parent/student/:studentId/subject/:subjectId/year/:yearId/lessons/:lessonId"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ParentStudentLesson />
                    </Suspense>
                  }
                />
                <Route
                  path="/parent/profile"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ParentProfile />
                    </Suspense>
                  }
                >
                  <Route path="/parent/profile">
                    <Route
                      path=""
                      element={
                        <Suspense fallback={<div></div>}>
                          <ParentProfileDetails />
                        </Suspense>
                      }
                    />
                    <Route
                      path="student/:studentId"
                      element={
                        <Suspense fallback={<div></div>}>
                          <ParentStudentProfileDetails />
                        </Suspense>
                      }
                    />
                    <Route
                      path="parent/:parentId"
                      element={
                        <Suspense fallback={<div></div>}>
                          <ParentParentProfileDetails />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="/parent/timetable"
                  element={
                    <Suspense fallback={<div></div>}>
                      <Timetable />
                    </Suspense>
                  }
                />
                <Route
                  path="/parent/billing-details-temp"
                  element={
                    <Suspense fallback={<div></div>}>
                      <BillingDetails />
                    </Suspense>
                  }
                />
                <Route
                  path="/parent/markers"
                  element={
                    <Suspense fallback={<div></div>}>
                      <HomeworkMarkingsView />
                    </Suspense>
                  }
                />
              </Route>

              {/* Authentication */}
              <Route
                element={
                  <GuestAuth>
                    <Suspense fallback={<div></div>}>
                      <AuthenticationLayout />
                    </Suspense>
                  </GuestAuth>
                }
              >
                <Route path="/" element={<Navigate to={'/login'} />} />
                <Route path="/signup" element={<SelectUserType type={'sign-up'} />} />
                <Route
                  path="/signup/parent"
                  element={
                    <Suspense fallback={<div></div>}>
                      <SignupAsParent />
                    </Suspense>
                  }
                />
                <Route
                  path="/signup/student"
                  element={
                    <Suspense fallback={<div></div>}>
                      <SignupAsStudent />
                    </Suspense>
                  }
                />
                <Route
                  path="/signup/teacher"
                  element={
                    <Suspense fallback={<div></div>}>
                      <SignupAsTeacher />
                    </Suspense>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Suspense fallback={<div></div>}>
                      <Signin />
                    </Suspense>
                  }
                />
                <Route
                  path="/verify-email"
                  element={
                    <Suspense fallback={<div></div>}>
                      <VerifyEmail />
                    </Suspense>
                  }
                />
                <Route
                  path="/forgot-password"
                  element={
                    <Suspense fallback={<div></div>}>
                      <ForgotPassword />
                    </Suspense>
                  }
                />
                <Route path="/create-password" element={<ResetPasswordPage title="Create new Password" />} />
                <Route path="/reset-password" element={<ResetPasswordPage title="Reset Password" />} />
              </Route>
              <Route
                element={
                  <Suspense fallback={<div></div>}>
                    <AuthenticationLayout />
                  </Suspense>
                }
              >
                <Route
                  path="/account-confirmation"
                  element={
                    <Suspense fallback={<div></div>}>
                      <AccountConfirmation />
                    </Suspense>
                  }
                />
              </Route>

              <Route>
                <Route
                  path="/ui-kits/v1"
                  element={
                    <Suspense fallback={<div></div>}>
                      <UiKit />
                    </Suspense>
                  }
                ></Route>
              </Route>
              <Route
                path="*"
                element={
                  <Suspense fallback={<div></div>}>
                    <NotFound />
                  </Suspense>
                }
              />
            </Routes>
          </AppLayout>
        </CookiesProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
