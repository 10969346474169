import qs from 'qs';
import api from '.';

export const externalContactApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExternalContacts: builder.query<any, any>({
      providesTags: ['ExternalContact'],
      query: (payload) => {
        const query = qs.stringify(payload, { encodeValuesOnly: true }) || '';
        return {
          url: `/api/external-contacts${query ? `?${query}` : ''}`,
          method: 'GET'
        };
      }
    }),
    getExternalContactById: builder.query<any, string>({
      providesTags: ['ExternalContact'],
      query: (id) => ({
        url: `/api/external-contacts/${id}`,
        method: 'GET'
      })
    }),
    createExternalContact: builder.mutation<any, { profileData: any }>({
      invalidatesTags: ['ExternalContact'],
      query: (body) => ({
        url: '/api/external-contacts',
        method: 'POST',
        body: { data: body.profileData }
      })
    }),
    updateExternalContact: builder.mutation<any, { profileData: any; id: string }>({
      invalidatesTags: ['ExternalContact'],
      query: (body) => ({
        url: `/api/external-contacts/${body.id}`,
        method: 'PUT',
        body: { data: body.profileData }
      })
    }),
    deleteExternalContact: builder.mutation<any, string>({
      invalidatesTags: ['ExternalContact'],
      query: (id) => ({
        url: `/api/external-contacts/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetExternalContactsQuery,
  useLazyGetExternalContactsQuery,
  useGetExternalContactByIdQuery,
  useLazyGetExternalContactByIdQuery,
  useCreateExternalContactMutation,
  useUpdateExternalContactMutation,
  useDeleteExternalContactMutation
} = externalContactApi;
