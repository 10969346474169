import Container from 'app/components/Container/Container';
import { Button } from 'app/components/v1/Button';
import { Errors, FormControl, Input, TextArea } from 'app/components/v1/Input';

export const ExternalContactDetails = (props: { formik: any; isLoading: boolean }) => {
  const { formik, isLoading } = props;
  return (
    <Container className="h-100 d-flex flex-column" isLoading={isLoading}>
      <div className="flex-grow-1">
        <FormControl className="mt-3">
          <Input
            type="text"
            name="Name"
            // disabled={isLoading}
            value={formik.values?.name}
            onChange={(value) => {
              formik.setFieldValue('name', value);
            }}
          />
          {formik?.errors?.name && <Errors errors={{ required: [formik?.errors?.name as any] }} />}
        </FormControl>

        <FormControl className="mt-3">
          <Input
            type="text"
            name="Email"
            // disabled={isLoading}
            value={formik.values?.email}
            onChange={(value) => {
              formik.setFieldValue('email', value);
            }}
          />
          {formik?.errors?.email && <Errors errors={{ required: [formik?.errors?.email as any] }} />}
        </FormControl>
        <FormControl className="mt-3">
          <Input
            type="text"
            name="Company Name"
            // disabled={isLoading}
            value={formik.values?.companyName}
            onChange={(value) => {
              formik.setFieldValue('companyName', value);
            }}
          />
          {formik?.errors?.companyName && <Errors errors={{ required: [formik?.errors?.companyName as any] }} />}
        </FormControl>
        <FormControl className="mt-3">
          <Input
            type="text"
            name="Phone"
            // disabled={isLoading}
            value={formik.values?.phone}
            onChange={(value) => {
              formik.setFieldValue('phone', value);
            }}
          />
          {formik?.errors?.phone && <Errors errors={{ required: [formik?.errors?.phone as any] }} />}
        </FormControl>
        <FormControl className="mt-3">
          <Input
            type="text"
            name="Job Role"
            // disabled={isLoading}
            value={formik.values?.jobRole}
            onChange={(value) => {
              formik.setFieldValue('jobRole', value);
            }}
          />
          {formik?.errors?.jobRole && <Errors errors={{ required: [formik?.errors?.jobRole as any] }} />}
        </FormControl>
        <FormControl className="mt-3">
          <Input
            type="text"
            name="Address"
            // disabled={isLoading}
            value={formik.values?.address}
            onChange={(value) => {
              formik.setFieldValue('address', value);
            }}
          />
          {formik?.errors?.address && <Errors errors={{ required: [formik?.errors?.address as any] }} />}
        </FormControl>
        <FormControl className="mt-5">
          <TextArea
            name="Note"
            placeholder="Note"
            value={formik.values.note}
            onChange={(value) => {
              formik.setFieldValue('note', value);
            }}
            style={{ height: '150px' }}
          />
        </FormControl>
      </div>
      <div className="d-flex flex-row gap-3">
        <Button
          className="col"
          name="Close"
          color="secondary"
          // disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            formik.resetForm();
          }}
        />
        <Button
          className="col"
          name="Save"
          color="primary"
          // disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            formik.submitForm();
          }}
        />
      </div>
    </Container>
  );
};
