import { Drawer as AntdDrawer, DrawerProps } from 'antd';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { IconComponents } from '../Icons';

const DrawerStyled = styled(AntdDrawer)`
  .ant-drawer-header-title {
    display: flex;
    flex-direction: column;
    align-items: unset;
    margin: 0;
    padding: 0;
    .ant-drawer-title {
      /* display: block; */
      display: flex;
      flex-direction: column;
    }
  }
  .ant-drawer-close {
    width: 0px !important;
    margin-bottom: 14px;
    transform: translateX(-13px);
  }
`;

export const Drawer = (props: PropsWithChildren<DrawerProps>) => {
  return (
    <DrawerStyled
      destroyOnClose={true}
      closeIcon={<IconComponents.CloseIconComponent />}
      placement="right"
      width={'700px'}
      closable={false}
      maskClosable={false}
      {...props}
    >
      {props.children}
    </DrawerStyled>
  );
};
