import { Checkbox, message } from 'antd';

import { ReactComponent as ChevronLeft } from 'app/assets/svgs/ic_Chevron Left.svg';
import { Drawer } from 'app/components/Drawer/Drawer';
import InputSelectMultiple from 'app/components/Form/InputSelectMultiple';
import { IconComponents } from 'app/components/Icons';
import Avatar from 'app/components/Icons/Avatar';
import { Button } from 'app/components/v1/Button';
import { Errors, FormControl, TextArea } from 'app/components/v1/Input';
import { useGetIssueReasonsQuery } from 'app/services/registers';
import { RegisterStudentIssueStatuses, UserType } from 'app/services/types';
import { MAIN_SELECTORS } from 'app/store';
import { useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import { SubmittingStateContext } from '..';

const StudentTeacherNotesStyled = styled.div`
  padding: 0 25px;
  .input-label {
    display: inline-block;
    color: #44444f;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 11px;
  }
  .student-name {
    display: inline-block;
    color: #171725;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
  }
  .username {
    color: #92929d;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    .dot {
      margin: 0px 4px;
      display: flex;
      height: 20px;
      width: fit-content;
    }
  }
  .form-body {
    border-radius: 10px;
    background: #f7f9fc;
    padding: 25px;
  }

  .send-parent {
    color: #44444f;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
  .alert-message,
  .pending-message,
  .sent-message {
    margin-top: 15px;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
  }
  .alert-message {
    background: rgba(252, 90, 90, 0.1);
    color: #000;
  }
  .pending-message {
    color: #44444f;
    background: rgba(255, 197, 66, 0.1);
  }
  .sent-message {
    color: #44444f;
    background: rgba(61, 213, 152, 0.1);
  }
`;

export default function StudentTeacherNotes(props: StudentTeacherNotesProps) {
  const { disabled } = React.useContext(SubmittingStateContext);
  const { student, cellData } = props;
  const user = useSelector(MAIN_SELECTORS.user);

  const { data: issuesReasonsData } = useGetIssueReasonsQuery('student');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const formik = useFormik<FormValues>({
    initialValues: {
      issueDescription: cellData?.issueDescription,
      issueReasons: cellData?.issueReasons
    },
    validationSchema: yup.object({
      issueReasons: yup
        .array(
          yup.object({
            label: yup.string().nullable(),
            value: yup.string().nullable()
          })
        ).label('reason')
        .nullable()
        .required("reasons are required")
        .min(1,"please enter at least ${min} ${label}")
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      props.onChange({
        issueDescription: formik.values.issueDescription!,
        issueReasons: formik.values.issueReasons!,
        issueSendToParent: formik.values.issueSendToParent
      });
      setIsOpen(false);
    },
    onReset: () => {
      setIsOpen(false);
    }
  });

  React.useEffect(() => {
    formik.setValues({
      issueDescription: props.cellData.issueDescription,
      issueReasons: props.cellData.issueReasons,
      issueSendToParent: props.cellData.issueSendToParent
    });
  }, [
    props.cellData.issueDescription,
    props.cellData.issueReasons,
    props.cellData.issueSendToParent,
    props.cellData.issueStatus
  ]);

  return (
    <div>
      {props.cellData.issueReasons?.length ? (
        <IconComponents.ShieldSelectedIconComponent style={{ cursor: 'pointer' }} onClick={() => setIsOpen(true)} />
      ) : (
        <IconComponents.ShieldIconComponent
          color="#ffff"
          style={{
            cursor: props.disableButton ? 'not-allowed' : 'pointer',
            opacity: props.disableButton ? '0.4' : '1'
          }}
          onClick={() => setIsOpen(props.disableButton ? false : true)}
        />
      )}
      <Drawer open={modalIsOpen} onClose={() => formik.resetForm()}>
        <StudentTeacherNotesStyled>
          <div className="d-flex mb-4">
            <ChevronLeft
              className="ms-n3"
              style={{ cursor: 'pointer' }}
              width={30}
              height={30}
              onClick={() => formik.resetForm()}
            />
            <div className="d-flex flex-grow-1">
              <Avatar name={props.student.avatarImageName!} width={90} height={90} />
              <div>
                <div className="student-name">{student?.fullName}</div>
                <div className="username">
                  {student?.studentId} <span className="dot">.</span> {student?.userName}
                </div>
              </div>
            </div>
            <Button
              style={{ height: 'fit-content' }}
              color="secondary"
              disabled={disabled}
              buttonType="text"
              onClick={() => {
                props.onChange({
                  issueDescription: null!,
                  issueReasons: null!,
                  issueSendToParent: null!,
                  issueStatus: null!
                });
                formik.resetForm();
              }}
            >
              <IconComponents.DeleteIconComponent />
            </Button>
          </div>
          <div className="form-body">
            <span className="input-label mt-3">Report an Issue:</span>
            <InputSelectMultiple
              value={formik.values.issueReasons as any}
              disabled={
                !(user.userType == UserType.Admin.toLowerCase()) && (!!props.cellData.issueStatus || props.disabled)
              }
              options={issuesReasonsData || []}
              onChange={(issues: any) => {
                formik.setFieldValue('issueReasons', issues);
              }}
            />
            {formik.errors.issueReasons && <Errors errors={{ required: [formik.errors.issueReasons] }} />}
            <span className="input-label mt-3">Description:</span>
            <FormControl>
              <TextArea
                style={{ minHeight: '150px' }}
                disabled={
                  !(user.userType == UserType.Admin.toLowerCase()) && (!!props.cellData.issueStatus || props.disabled)
                }
                placeholder="Type description here. Where approriate, please include a rough time when the incident occured"
                onChange={(e) => {
                  formik.setFieldValue('issueDescription', e);
                }}
                value={formik.values.issueDescription}
              />
            </FormControl>
            {!props.cellData.issueStatus && (
              <Checkbox
                className="send-parent mt-3"
                disabled={props.disabled}
                checked={formik.values.issueSendToParent}
                onChange={(e) => {
                  formik.setFieldValue('issueSendToParent', e.target.checked);
                }}
              >
                Send a copy to Parent(s)
              </Checkbox>
            )}
          </div>
          {props.cellData.issueStatus === RegisterStudentIssueStatuses.PENDING ? (
            <div className="pending-message">
              <IconComponents.PendingIconComponent />
              Your report is in review. Thank you for your patience.
            </div>
          ) : props.cellData.issueStatus === RegisterStudentIssueStatuses.SENT ? (
            <div className="sent-message">
              <IconComponents.SuccessIconComponent />
              Your message has been sent
            </div>
          ) : props.cellData.issueStatus === RegisterStudentIssueStatuses.REJECTED ? (
            <div className="alert-message">
              <IconComponents.InformationIconComponent />
              Sorry your report has been rejected by an admin.
            </div>
          ) : formik.values.issueSendToParent ? (
            <div className="alert-message">
              <IconComponents.InformationIconComponent />
              This message will be reviewed by the admin before forwarding it to the parent(s)
            </div>
          ) : (
            <></>
          )}

          {((!props.cellData.issueStatus && !props.disabled) || user.userType == UserType.Admin.toLowerCase()) && (
            <div className="d-flex flex-row gap-3 mt-5">
              <Button className="col" color="secondary" type="button" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                className="col"
                onClick={() => {
                  formik.submitForm();
                  message.success('Behaviour issue added for student');
                }}
                color="primary"
                type="button"
              >
                Save
              </Button>
            </div>
          )}
        </StudentTeacherNotesStyled>
      </Drawer>
    </div>
  );
}

export type StudentTeacherNotesProps = {
  disabled?: boolean;
  disableButton?: boolean;
  student: {
    studentId: string;
    fullName: string;
    userName: string;
    id: string;
    created_at: string;
    avatarImageName: string;
  };
  cellData: {
    rowIndex: number;
    columnId: number;
    issueReasons: { label: string; value: string }[];
    issueDescription: string;
    issueSendToParent: boolean;
    issueStatus: string;
  };
  onChange: (payload: {
    issueReasons: { label: string; value: string }[];
    issueDescription: string;
    issueSendToParent?: boolean;
    issueStatus?: string;
  }) => void;
};

type FormValues = {
  issueReasons?: { label: string; value: string }[];
  issueDescription?: string;
  issueSendToParent?: boolean;
  issueStatus?: string;
};
