import { AppContext } from 'AppContext';
import { registersApi } from 'app/services/registers';
import { EventPayload } from 'app/services/web-socket/ws-socket.service';
import { AppStore, MAIN_SELECTORS, notificationsSlice } from 'app/store';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClassCheckersHeader } from './ClassCheckersHeader';
import { ClassCheckersRegister } from './ClassCheckersRegister';

export const StateContext = createContext<{
  lessonData?: any;
}>({});

export const ClassCheckersDetailsPage = () => {
  const { registerId } = useParams();
  const { data: queryData, refetch } = registersApi.useGetRegisterQuery(
    {
      registerId: registerId!,
      populate: {
        register_students: {
          populate: {
            student: { populate: { primary_parent: true } },
            student_class: true,
            callLogs: { populate: { admin: true, parent: true, student: true } }
          }
        },
        class: { populate: { year: true, subject: true } },
        teacher: true,
        checkinLogs: { populate: { admin: true, timeStamp: true } }
      }
    },
    {
      skip: !registerId
    }
  );

  const user = useSelector(MAIN_SELECTORS.user);
  const notificationSelector = useSelector((state: AppStore) => state[notificationsSlice.name]);
  const [lessonData, setLessonData] = useState<any>();
  const appContext = useContext(AppContext);

  useEffect(() => {
    setLessonData(queryData);
  }, [queryData]);

  useEffect(() => {
    appContext.socket.on('checkinlog-updated', (args: any) => {
      if (args.id.toString() === registerId?.toString()) {
        refetch();
      }
    });

    return () => {
      appContext.socket.off('checkinlog-updated');
    };
  }, [appContext]);

  useEffect(() => {
    const notification: EventPayload = notificationSelector?.notification;
    if (notification && notification.background) {
      refetch();

      /*  if (queryData) {
        const updatedLessonData = {
          ...queryData,
          data: {
            ...queryData.data,
            attributes: {
              ...queryData.data.attributes,
              register_students: {
                data: queryData.data.attributes.register_students.data.map((student) => {
                  if (student.id === registerStudentId) {
                    return {
                      ...student,
                      attributes: {
                        ...student.attributes,
                        hasAttended:
                          attendance.hasAttended !== undefined
                            ? attendance.hasAttended
                            : student.attributes.hasAttended,
                        hasLeftEarly:
                          attendance.hasLeftEarly !== undefined
                            ? attendance.hasLeftEarly
                            : student.attributes.hasLeftEarly,
                        isLate: attendance.isLate !== undefined ? attendance.isLate : student.attributes.isLate
                        // Add other attributes here that you want to update conditionally
                      }
                    };
                  }
                  return student;
                })
              }
            }
          }
        };

        console.log(updatedLessonData.data.attributes.register_students.data[0]);
        setLessonData({ ...updatedLessonData });
      } */
    }
  }, [notificationSelector]);

  useEffect(() => {
    appContext.joinRoom({
      userId: user?.id?.toString()!,
      entityId: lessonData?.data?.id,
      userType: user.userType,
      portal: user.userType,
      entity: 'register'
    });
    return () => {
      appContext.leaveRoom({
        userId: user?.id?.toString()!,
        entityId: lessonData?.data?.id,
        userType: user.userType,
        portal: user.userType,
        entity: 'register'
      });
    };
  }, [lessonData]);

  useEffect(() => {
    lessonData?.data?.attributes?.register_students?.data?.map((regStudent: any) => {
      const studentId = regStudent?.attributes?.student?.data?.id;
      appContext.joinRoom({
        userId: user?.id?.toString()!,
        entityId: studentId,
        userType: user.userType,
        portal: user.userType,
        entity: 'student'
      });
    });

    return () => {
      lessonData?.data?.attributes?.register_students?.data?.map((regStudent: any) => {
        const studentId = regStudent?.attributes?.student?.data?.id;
        appContext.leaveRoom({
          userId: user?.id?.toString()!,
          entityId: studentId,
          userType: user.userType,
          portal: user.userType,
          entity: 'student'
        });
      });
    };
  }, [lessonData]);

  return (
    <>
      <ClassCheckersHeader lessonData={lessonData} />
      <div className="mt-2 px-2 px-sm-4 col-12 bg-light mb-8">
        <ClassCheckersRegister lessonData={lessonData} />
      </div>
    </>
  );
};
