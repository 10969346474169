import React, { useState } from 'react';

interface AlertProps {
  type: string;
  children: React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
  hideIcon?: boolean;
}


const Alert = ({ type, children, closable = false, onClose = () => {}, hideIcon = false }: AlertProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`alert d-flex  justify-content-between alert-${type}`} >
      {!hideIcon && <span className="fas fa-exclamation fa-fw mr-1 alert__icon"></span>}
      <p className="alert__text">{children}</p>
      {closable && (
        <button
          className="btn-close"
          type="button"
          aria-label="Close"
          style={{marginRight:"10px" }}
          onClick={() => {
            handleClose();
          }}
        ></button>
      )}
    </div>
  );
};

export default Alert;
