import Select from 'react-select';

export default function InputSelectMultiple(props: InputSelectMultipleProps) {
  const { label, options, onChange, value } = props;
  return (
    <div>
      {label && (
        <label htmlFor="" className="fw-bolder mb-2">
          {label}
        </label>
      )}
      <Select
        isMulti
        isDisabled={props.disabled}
        name="multi"
        options={options as any}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export type InputSelectMultipleProps = {
  label?: string;
  options: Array<{ value: any; label: string }>;
  feedback?: string;
  classes?: string;
  value?: string[];
  onChange?: (value: any) => void;
  required?: boolean;
  disabled?: boolean;
};
