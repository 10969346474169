import { Notification, notificationsSlice } from 'app/store';
import { getEntityLink } from 'app/utils/utils';
import { useMemo, useRef } from 'react';
import { useStore } from 'react-redux';
import * as IO from 'socket.io-client';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';
import { UserType } from '../types';

export const useWSSocket = () => {
  const URL: string = process.env.REACT_APP_API_URL as string;

  const savedConfig = useRef<WSSocketConfig>();
  const connected = useRef<boolean>(false);

  const socket = useMemo<IO.Socket<any, any>>(() => {
    return IO.io(URL, {
      autoConnect: false
    });
  }, []);

  const store = useStore();

  const connect = (config?: WSSocketConfig) => {
    if (connected.current) return;
    savedConfig.current = config;
    connected.current = true;
    socket.on(WSChannelEvents.CONNECT, () => onConnected());
    socket.on(WSChannelEvents.DISCONNECT, (...args) => onDisconnected(...args));
    socket.on(WSChannelEvents.NOTIFY_BACKGROUND, onNotifyBackgroundChannelTriggered);
    socket.on(WSChannelEvents.NOTIFY, onNotifyChannelTriggered);
    socket.on(`${config?.userType?.toLowerCase()}-user-${config?.userId}`, onPrivateChannelTriggered);
    socket.on(WSChannelEvents.WELCOME, onWelcomeChannelTriggered);
    socket.connect();
  };

  const joinRoom = (config?: WSSocketConfig) => {
    socket.emit('join-room', config);
  };

  const leaveRoom = (config?: WSSocketConfig) => {
    socket.emit('leave-room', config);
  };

  const disconnect = () => {
    savedConfig.current = null!;
    connected.current = false;
    socket.offAny();
    socket.disconnect();
    socket.close();
  };

  const onConnected = () => {
    // socket authenticate.
    if (!savedConfig.current) return;
    socket.emit(WSEmitEvents.JOIN, {
      portal: savedConfig.current.portal,
      userId: savedConfig.current.userId,
      entityId: savedConfig.current.entityId
    });
  };

  const onDisconnected = (reason: string, description?: DisconnectDescription | undefined) => {
    console.log(`socket disconnected`, reason, description);
  };

  const onPrivateChannelTriggered = (payload: EventPayload) => {
    const modifiablePayload = {
      ...payload,
      url: getEntityLink(savedConfig.current?.userType?.toString()!, payload) as string
    };
    store.dispatch(notificationsSlice.actions.newNotification(modifiablePayload as Notification));
  };

  const onNotifyChannelTriggered = (payload: EventPayload) => {
    const modifiablePayload = {
      ...payload,
      url: getEntityLink(savedConfig.current?.userType?.toString()!, payload) as string
    };
    store.dispatch(notificationsSlice.actions.newNotification(modifiablePayload as Notification));
  };

  const onWelcomeChannelTriggered = (payload: string) => {
    // only for testing purpose
  };

  const onNotifyBackgroundChannelTriggered = (payload: EventPayload) => {
    const modifiablePayload = {
      ...payload,
      url: getEntityLink(savedConfig.current?.userType?.toString()!, payload) as string
    };
    store.dispatch(notificationsSlice.actions.newNotification(modifiablePayload as Notification));
  };

  return { connect, disconnect, joinRoom, leaveRoom, socket };
};

enum WSChannelEvents {
  NOTIFY_BACKGROUND = 'notify-background',
  NOTIFY = 'notify',
  WELCOME = 'welcome',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect'
}

enum WSEmitEvents {
  JOIN = 'join'
}

type WSSocketConfig = {
  userId: string;
  entityId: string;
  portal: Portal;
  userType: UserType;
  entity?: string;
};

export type EventPayload = {
  entity: 'student' | 'teacher' | 'register' | 'registerStudent' | 'class';
  entityId: string;
  eventCode: string;
  eventType: 'info';
  message: string;
  persist: true;
  background: boolean;
  url?: string;
  receiverId?: string;
  messageValues?: any;
};

export type Portal = 'admin' | 'teacher' | 'student' | 'parent' | 'guest' | 'admin-meeting-recording';
