import { Tooltip, TooltipProps } from 'antd';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { IconComponents } from '../Icons';

const ButtonStyled = styled.button`
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 11px;
  font-family: var(--st-font-family-primary);
  font-style: normal;
  font-weight: 600;
  outline: none;
  padding: 0.5rem 1.5rem;
  border-radius: 7px;
  border: 0;
  user-select: none;
  text-decoration: none;

  &.st-v1-button-sm {
    font-size: 14px;
  }
  &.st-v1-button-md {
    font-size: 16px;
  }
  &.st-v1-button-lg {
    font-size: 18px;
  }
  &.st-v1-button-xl {
    font-size: 21px;
  }

  &.st-v1-button-primary {
    background: #4c54a0;
    color: #ffffff;
    &:not(:disabled):hover {
      background: #2c368e;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #4c54a033;
    }
  }

  &.st-v1-button-secondary {
    background: #f1f1f5;
    color: #696974;
    &:not(:disabled):hover {
      background: #f2f2f7;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px rgba(76, 84, 160, 0.2);
    }
  }

  &.st-v1-button-danger {
    background: #fc5a5a;
    color: #ffffff;
    &:not(:disabled):hover {
      background: #fc5a5a;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #cfaaaa4e;
    }
  }

  &.st-v1-button-success {
    background: #3dd598;
    color: #ffffff;
    &:not(:disabled):hover {
      background: #3dd598;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #cfaaaa4e;
    }
  }

  &.st-v1-button-text-primary {
    background: transparent;
    color: #4c54a0;
    border: 0;
    padding: 5px;

    &:not(:disabled):hover {
      background: #edeef5;
    }
  }

  &.st-v1-button-text-danger {
    background: transparent;
    color: #fc5a5a;
    border: 0;
    padding: 5px;
    &:not(:disabled):hover {
      background: #edeef5;
    }
  }

  &.st-v1-button-text-secondary {
    background: transparent;
    color: #92929d;
    border: 0;
    padding: 5px;
    &:not(:disabled):hover {
      background: #edeef5;
    }
  }

  &.st-v1-button-outlined-primary {
    background: #ffffff;
    border: 1px solid #4c54a0;
    color: #4c54a0;
    &:not(:disabled):hover {
      background: #f2f2f7;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #4c54a033;
    }
  }

  &.st-v1-button-outlined-success {
    background: #ffffff;
    border: 1px solid #3dd598;
    color: #3dd598;
    &:not(:disabled):hover {
      background: #f2f2f7;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #4c54a033;
    }
  }

  &.st-v1-button-outlined-danger {
    background: #ffffff;
    border: 1px solid #fc5a5a;
    color: #fc5a5a;
    &:not(:disabled):hover {
      background: #f2f2f7;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #4c54a033;
    }
  }

  &.st-v1-button-outlined-danger {
    background: #ffffff;
    border: 1px solid #fc5a5a;
    color: #fc5a5a;
    &:not(:disabled):hover {
      background: #f2f2f7;
    }
    &:focus {
      box-shadow: 0px 0px 0px 5px #4c54a033;
    }
  }

  &[disabled] {
    background: #f2f2f7;
    color: #92929d;
    border: none;
    box-shadow: none;
    & > svg {
      fill: white;
      stroke: white;
      path {
        fill: white;
        stroke: white;
      }
    }
  }

  ${(props: any) => (props?.iconDirection === 'right' ? 'flex-direction: row-reverse;' : '')}
`;

export const Button = forwardRef((props: React.PropsWithChildren<ButtonProps>, ref: any) => {
  function resolveButtonColor(): string {
    return props.color ? '-' + props.color : '-primary';
  }

  function resolveButtonType(): string {
    return props.buttonType ? '-' + props.buttonType : '';
  }

  function resolveButtonSize(): string {
    return props.size ? 'st-v1-button-' + props.size : '';
  }

  const className = `st-v1-button${resolveButtonType()}${resolveButtonColor()} ${resolveButtonSize()} ${
    props.className || ''
  }`;

  const children = (
    <>
      {props.loading ? <IconComponents.LoadingIconComponent color="white" svg={{ width: '15px' }} /> : props.icon}
      {props.loading ? <span>Loading..</span> : props.children ? props.children : props.name}
    </>
  );

  return (
    <Tooltip {...props.tooltip}>
      <ButtonStyled
        {...props}
        name={props.name as any}
        onClick={props.onClick}
        className={className}
        type={props.type || 'button'}
        disabled={props.disabled || props.loading}
        ref={ref}
      >
        {children}
      </ButtonStyled>
    </Tooltip>
  );
});

export type ButtonProps = {
  name?: string | React.ReactNode;
  className?: string;
  disabled?: boolean | undefined;
  buttonType?: ButtonType;
  color?: ButtonColor;
  size?: ButtonSize;
  type?: Type;
  icon?: React.ReactElement;
  iconDirection?: IconDirection;
  loading?: boolean | undefined;
  onClick?: OnClickFunc;
  style?: React.CSSProperties;
  tooltip?: TooltipProps;
};

export type IconDirection = 'left' | 'right';

export type Type = 'button' | 'submit' | 'reset';

export type ButtonType = 'text' | 'outlined';

export type ButtonColor = 'primary' | 'secondary' | 'danger' | 'success';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';

export type OnClickFunc = (x: any) => void;
