import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TogglerSection } from './ToggleSection';
import { TogglerButton } from './TogglerButton';

const TogglerStyled = styled.div`
  .toggler-button {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .toggler-section {
  }
  &.hide {
    .toggler-section {
      max-height: 0;
      overflow: hidden;
    }
  }
`;

type TogglerProps = React.PropsWithChildren<{
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  onToggled?: (visiable: boolean) => void;
  visible?: boolean;
  disabled?: boolean;
  className?: string;
  topRightSection?: React.ReactNode;
  iconOnLeftSide?: boolean;
}>;

export function Toggler(props: TogglerProps) {
  const [visible, setVisible] = useState<boolean>();
  useEffect(() => {
    setVisible(props.visible!);
  }, [props.visible]);
  return (
    <TogglerStyled className={`${!visible ? 'hide' : ''} ${props.className}`}>
      <div className="d-flex flex-row w-100 algin-items-center">
        <TogglerButton
          buttonType="text"
          color="secondary"
          className="toggler-button active-class-title"
          iconOnLeftSide={props.iconOnLeftSide}
          name={props.title}
          opened={visible}
          onClick={() => {
            setVisible((state) => {
              props.onToggled && props.onToggled(!state);
              return !state;
            });
          }}
        ></TogglerButton>

        {props.topRightSection}
      </div>
      {props.description && props.description}
      <TogglerSection className="toggler-section">{props.children}</TogglerSection>
    </TogglerStyled>
  );
}
