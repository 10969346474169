import moment from 'moment';
import qs from 'qs';
import api from './index';
import {
  ApiResponse,
  ApiResponseData,
  GetQueryPayload,
  GetRegistersResponse,
  IUpcomingLesson,
  IUpcomingLessonsRequestPayload,
  Register,
  RequestQuery,
  UpdateRegisterRequestBody
} from './types';

export const registersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegisters: builder.query<GetRegistersResponse, GetQueryPayload>({
      providesTags: ['Register', 'Class'],
      query: (payload) => {
        return {
          url: `/api/registers?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    changeTeacher: builder.mutation<any, { body: { teacherId: string; registerId: string } }>({
      invalidatesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: payload.body.registerId
              }
            ]
          : ['Register'];
      },
      query: (payload) => {
        return {
          url: `/api/registers/changeTeacher`,
          method: 'POST',
          body: payload.body
        };
      }
    }),
    updateClassStatus: builder.mutation<any, { body: { newStatus: string; registerId: string } }>({
      invalidatesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: payload.body.registerId
              }
            ]
          : ['Register'];
      },
      query: (payload) => {
        return {
          url: `/api/registers/updateClassStatus`,
          method: 'POST',
          body: payload.body
        };
      }
    }),
    getRegistersMarkings: builder.query<any, void>({
      providesTags: ['RegistersMarkings'],
      query: () => {
        const query = qs.stringify(
          {
            populate: [
              'curriculums',
              'class',
              'class.subject',
              'class.year',
              'homeworkFiles',
              'homeworkSolutions',
              'register_students',
              'register_students.homeworkSubmissionFiles',
              'register_students.homeworkMarkedFiles',
              'register_students.student',
              'register_students.homework_marking_teacher'
            ],
            sort: ['id:desc']
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/registers?${query}`,
          method: 'GET'
        };
      },
      transformResponse: (response: any) =>
        response?.data?.map((item: any) => ({
          id: item.id,
          ...item.attributes,
          topics: item.attributes.curriculums?.data?.map((cur: any) => cur.attributes.topic),
          classDate: {
            title: moment(item.attributes.classDate).format('DD MMM YYYY'),
            link: item.id
          },
          yearGroup: item.attributes?.class?.data?.attributes.year?.data?.attributes?.shortName,
          subject: item.attributes?.class?.data?.attributes?.subject?.data?.attributes?.name
        }))
    }),
    getRegister: builder.query<
      ApiResponse<{ id: string; attributes: Register }>,
      RequestQuery & {
        registerId: string;
      }
    >({
      providesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: payload.registerId
              }
            ]
          : ['Register', 'Class', 'LessonMaterial'];
      },
      query: (payload) => {
        const query = qs.stringify(
          {
            populate: payload.populate,
            filters: payload.filters,
            sort: payload.sort
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/registers/${payload.registerId}?${query}`,
          method: 'GET'
        };
      }
    }),
    getLesson: builder.query<ApiResponse<ApiResponseData<Register>>, string>({
      providesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: payload
              }
            ]
          : ['Register', 'LessonMaterial'];
      },
      query: (lessonId) => {
        const query = qs.stringify(
          {
            populate: {
              register_students: {
                filters: {
                  $or: [
                    {
                      freeTrialCancelled: false
                    },
                    {
                      freeTrialCancelled: {
                        $null: true
                      }
                    }
                  ]
                },
                populate: [
                  'student',
                  'student.user',
                  'student.primary_parent',
                  'issue_reasons',
                  'student_class',
                  'callLogs',
                  'callLogs.admin',
                  'callLogs.parent',
                  'callLogs.student'
                ]
              },
              class: {
                populate: ['year', 'subject', 'teacher', 'subject_level']
              },
              checkinLogs: {
                populate: ['admin', 'timestamp']
              },
              lesson_material: true,
              payments: true,
              homeworkFiles: true,
              homeworkSolutions: true,
              lessonFiles: true,
              teacher: true
            }
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/registers/${lessonId}?${query}`,
          method: 'GET'
        };
      }
    }),
    updateLesson: builder.mutation<any, { registerId: string; register: Register }>({
      invalidatesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: payload.registerId
              }
            ]
          : ['Register'];
      },
      query: (payload) => {
        return {
          url: `/api/registers/${payload.registerId}`,
          method: 'PUT',
          body: { data: payload.register }
        };
      }
    }),
    getIssueReasons: builder.query<any, string>({
      providesTags: ['Register'],
      query: (group) => {
        const query = qs.stringify(
          {
            filters: {
              group
            }
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/issue-reasons?${query}`,
          method: 'GET'
        };
      },
      transformResponse: (response: any) =>
        response?.data?.map((item: any) => ({
          label: item?.attributes?.name,
          value: item?.id
        }))
    }),
    assignHomeworksToTeacher: builder.mutation<any, { registerId: string; teacherID: string }>({
      query: ({ registerId, teacherID }) => {
        return {
          url: `/api/registers/assignHomeworksToTeacher/${registerId}/${teacherID}`,
          method: 'PUT'
        };
      },
      invalidatesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: result
              },
              {
                type: 'RegistersMarkings',
                id: result
              }
            ]
          : ['Register', 'RegistersMarkings'];
      }
    }),
    getHomeworks: builder.query<any, GetQueryPayload & { teacherId: string }>({
      providesTags: (result, error, payload) => {
        return result
          ? [
              {
                type: 'Register',
                id: result
              }
            ]
          : ['Register'];
      },
      query: (payload) => {
        return {
          url: `/api/registers/getHomework?teacherId=${payload.teacherId}&${payload.requestQuery}`,
          method: 'GET'
        };
      },
      transformResponse: (response: any) => {
        return response?.results?.map((item: any) => ({
          id: item.id,
          ...item,
          topics: item?.curriculums?.map((cur: any) => cur?.topic),
          classDate: {
            title: moment(item.classDate).format('DD MMM YYYY'),
            link: item.id
          },
          yearGroup: item?.class?.year?.shortName,
          subject: item?.class?.subject?.name
        }));
      }
    }),

    updateRegister: builder.mutation<ApiResponse<any>, { registerId: string; body: UpdateRegisterRequestBody }>({
      invalidatesTags: ['Register'],
      query: (payload) => {
        return {
          url: `/api/registers/${payload.registerId}`,
          method: 'PUT',
          body: { data: payload.body }
        };
      }
    }),
    getUpcomingLessons: builder.query<IUpcomingLesson[], IUpcomingLessonsRequestPayload>({
      providesTags: ['Register'],
      query: (payload) => {
        const query = qs.stringify(payload);
        return {
          url: `/api/registers/upcomingLessons?${query}`,
          method: 'GET'
        };
      }
    }),
    updateCurriculum: builder.mutation<any, any>({
      invalidatesTags: ['Register'],
      query: (payload) => {
        return {
          url: `/api/registers/updateCurriculum`,
          method: 'PUT',
          body: payload
        };
      }
    }),
    createRegister: builder.mutation<any, any>({
      invalidatesTags: ['Register'],
      query: (body) => {
        return {
          url: `/api/registers`,
          method: 'POST',
          body
        };
      }
    }),
    deleteRegister: builder.mutation<any, string>({
      invalidatesTags: ['Register'],
      query: (id) => {
        return {
          url: `/api/registers/${id}`,
          method: 'DELETE'
        };
      }
    }),
    hasHomeworkSubmitted: builder.query({
      providesTags: ['Register'],
      query: (payload) => {
        const query = qs.stringify(payload);
        return {
          url: `/api/registers/hasHomeworkSubmitted?${query}`,
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useGetRegistersQuery,
  useLazyGetRegistersQuery,
  useGetRegisterQuery,
  useGetLessonQuery,
  useUpdateLessonMutation,
  useGetIssueReasonsQuery,
  useGetRegistersMarkingsQuery,
  useLazyGetRegistersMarkingsQuery,
  useAssignHomeworksToTeacherMutation,
  useUpdateRegisterMutation,
  useUpdateCurriculumMutation,
  useCreateRegisterMutation,
  useDeleteRegisterMutation,
  useHasHomeworkSubmittedQuery
} = registersApi;

export const useGetLessonDataQueryState = registersApi.endpoints.getLesson.useQueryState;

// --------------------------------------------------------------------------------------------------------------------

export type GetRegisterRequestResponse = {
  data: {
    id: string;
    attributes: Register[];
  };
};
