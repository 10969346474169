import styled from 'styled-components';
import { Errors, FormControl, FormControlError, Input } from '../v1/Input';
import { IconComponents } from '../Icons';

const SearchInputStyled = styled.div`
  .search-input-form-control {
    width: 304px;
    position: relative;
    input {
      padding-right: 40px;
      width: 100%;
    }
  }
  .search-input-icon {
    position: absolute;
    right: 15px;
    top: 12px;
  }
`;

const SearchInput = (props: SearchInputComponentProps) => {
  return (
    <SearchInputStyled className={props.className}>
      <FormControl className="search-input-form-control">
        <Input name={props.name!} onChangeDebouncing={props.onChange} value={props.value} />
        <span className="search-input-icon">
          <IconComponents.SearchIconComponent />
        </span>
        {props.errors && <Errors errors={props.errors} />}
      </FormControl>
    </SearchInputStyled>
  );
};

type SearchInputComponentProps = {
  onChange?: (e: string) => void;
  value?: string;
  name?: string;
  className?: string;
  errors?: FormControlError;
};

export default SearchInput;
