import { useState } from 'react';
import { ReactComponent as EyeSvg } from 'app/assets/svgs/ic_view Fill 2.svg';
import { ReactComponent as NoEyeSvg } from 'app/assets/svgs/ic_not visible.svg';

export default function useTogglePassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const EyeIcon = passwordShown ? EyeSvg : NoEyeSvg;

  const togglePassword = () => {
    setPasswordShown((state) => !state);
  };

  return { togglePassword, passwordShown, EyeIcon };
}
