import qs from 'qs';
import api from './index';
import {
  ApiResponse,
  ApiResponseData,
  Class,
  CreateTeacherRequest,
  GetQueryPayload,
  GetTeacherCapabilitiesResponse,
  GetTeacherClassesResponse,
  GetTeacherResponse,
  GetTeachersResponse,
  UpdateTeacherCapabilityRequest,
  UpdateTeacherRequest
} from './types';

export const teacherApi = api.injectEndpoints({
  endpoints: (builder) => ({
    classTeachers: builder.query<
      ApiResponse<
        ApiResponseData<{
          class: { data: ApiResponseData<Class> };
          classId: string;
          createdAt: string;
          type: string;
          updatedAt: string;
        }>[]
      >,
      {
        populate?: { [key: string]: any };
        filters?: { [key: string]: any };
        pagination?: { [key: string]: any };
        sort?: string[];
      }
    >({
      providesTags: ['Teacher'],
      query: (payload) => {
        const query = qs.stringify(
          {
            populate: payload.populate,
            filters: payload.filters,
            pagination: payload.pagination,
            sort: payload.sort
          },
          {
            encodeValuesOnly: true
          }
        );
        return {
          url: `/api/class-teachers?${query}`,
          method: 'GET'
        };
      }
    }),
    updateTeacher: builder.mutation<any, { profileData: UpdateTeacherRequest; id: string }>({
      query: (body) => ({
        url: `/api/teachers/${body.id}`,
        method: 'PUT',
        body: { data: body.profileData }
      }),
      invalidatesTags: ['Teacher', 'User']
    }),
    getTeacher: builder.query<GetTeacherResponse, GetQueryPayload & { teacherId: string }>({
      providesTags: ['Teacher','User'],
      query: (payload) => {
        return {
          url: `/api/teachers/${payload.teacherId}?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    getCapabilities: builder.query<GetTeacherCapabilitiesResponse, { query: string; teacherId: string }>({
      providesTags: ['Teacher'],
      query: (payload) => ({
        url: `/api/teacher-capabilities/forTeacher/${payload.teacherId}?${payload.query}`,
        method: 'GET'
      })
    }),
    updateCapabilities: builder.mutation<
      any,
      {
        teacherId: string;
        requestBody: UpdateTeacherCapabilityRequest;
      }
    >({
      invalidatesTags: ['Teacher'],
      query: (payload) => ({
        url: `/api/teacher-capabilities/forTeacher/${payload.teacherId}`,
        method: 'PUT',
        body: payload.requestBody
      })
    }),

    createTeacher: builder.mutation<any, CreateTeacherRequest>({
      invalidatesTags: ['Teacher'],
      query: (requestBody) => ({
        url: `/api/teachers`,
        method: 'POST',
        body: {
          data: requestBody
        }
      })
    }),
    getTeachers: builder.query<GetTeachersResponse, GetQueryPayload>({
      providesTags: ['Teacher'],
      query: (payload) => {
        return {
          url: `/api/teachers?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    getTeacherClasses: builder.query<GetTeacherClassesResponse, GetQueryPayload>({
      providesTags: ['Teacher'],
      query: (payload) => {
        return {
          url: `/api/class-teachers?${payload?.requestQuery}`,
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useUpdateTeacherMutation,
  useGetCapabilitiesQuery,
  useLazyGetCapabilitiesQuery,
  useUpdateCapabilitiesMutation,
  useGetTeachersQuery,
  useLazyGetTeachersQuery,
  useCreateTeacherMutation
} = teacherApi;
