import Spinner from 'app/components/Spinner';
import { useAuth } from 'app/utils/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { loading, authStatus } = useAuth();
  const navigate = useNavigate();
  if (loading) {
    return <Spinner />;
  } else if (authStatus === 'unauthenticated') {
    navigate('/login');
    return <p>You are not authorized to view this page</p>;
  }

  return children;
}

export default RequireAuth;
