import { scheduleApi } from 'app/services/schedule';
import { ClassStudentClassStatuses, UserType } from 'app/services/types';
import { dateFormat, utcToLocalDateTime } from 'app/utils/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Calendar as BaseCalender, Event, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
import { CustomEvent } from './CustomEvent';
import CustomHeader from './CustomHeader';
import CustomToolbar from './CustomToolbar';

const CalenderStyled = styled.div`
  .calender {
    padding: 15px 0 0 0;
    background: #ffffff;
    border: 1px solid #e2e2ea;
    border-radius: 20px;
    max-height: 75vh;
    .rbc-event {
      border-radius: 10px;
    }
    .rbc-day-slot {
      .rbc-timeslot-group {
        border: 1px solid rgba(226, 226, 234, 0.5);
      }
    }

    .rbc-month-view {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
  }

  .rbc-event-label {
    display: none;
  }

  @media screen and (max-width: 1000px) {
    .calender {
      min-width: 1200px;
    }
  }

  .react-big-calendar {
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
    color: rgba(105, 105, 116, 1);
    border: 1px solid rgba(226, 226, 234, 0.8) !important;
  }

  .rbc-toolbar {
    padding: 20px;
    background: white;
    margin-bottom: 0;
    display: flex;

    .rbc-btn-group:last-child {
      order: -1;
    }

    .rbc-btn-group:first-child {
      order: 2;
      button:first-child {
        border: 0;
        margin-right: 10px;
      }
      button {
        border-radius: 8px;
      }
      button:last-child {
        margin-left: 10px;
      }
    }

    .rbc-btn-group button {
      color: rgba(105, 105, 116, 0.8);
      font-weight: 500;
    }
  }

  .rbc-header {
    border: 0;
    padding-top: 5px;
  }

  .rbc-timeslot-group {
    border-bottom: 0;
  }

  .rbc-time-view {
    background: white;
    border: 0;
    border-top: 1px solid rgba(226, 226, 234, 1) !important ;
  }

  .rbc-time-content {
    border-top: 1px solid rgba(226, 226, 234, 1) !important ;
  }

  .rbc-time-slot {
    border-top: 0 !important;
  }

  .rbc-events-container {
    margin-right: 0 !important;
  }

  .rbc-event,
  .rbc-event.rbc-selected {
    background-color: rgba(230, 57, 70, 1);
    border: 1px solid white !important;
  }

  .rbc-event-content {
    font-weight: 500;
  }

  .rbc-toolbar-label {
    font-size: 24px;
    font-weight: bolder;
  }

  .event-container-sm {
    .event-container-custom {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      padding: 0 6px;
    }
  }
`;

export const Calendar = (props: IProp) => {
  const localizer = momentLocalizer(moment);
  const [query, setQuery] = useState<any>({
    startDate: moment().startOf('week').format('YYYY-MM-DD'),
    endDate: moment().startOf('week').add(1, 'week').format('YYYY-MM-DD'),
    entityType: props?.entityType,
    entityId: props?.entityId
  });

  const {
    data: allRegisterEventsData,
    refetch: refetchAllRegisters,
    isUninitialized
  } = scheduleApi.useGetScheduleLessonsQuery(
    {
      ...query,
      startDate: query.startDate,
      endDate: query.endDate
    },
    {
      skip:
        (!query?.classIds &&
          !query?.teacherIds &&
          !query?.studentIds &&
          !query?.meetingAccountIds &&
          !query?.subjectIds &&
          !query?.yearIds) ||
        props.calenderType !== 'lessonSchedule'
    }
  );

  const { studentEventsData } = scheduleApi.useGetScheduleQuery(query, {
    skip: !query || !props?.entityId || !props.entityType || props.calenderType === 'lessonSchedule',
    selectFromResult: (e) => ({
      studentEventsData: e?.data?.map(
        (register) =>
          ({
            start: utcToLocalDateTime(register.date, register.startTime).toDate(),
            end: utcToLocalDateTime(register.date, register.endTime).toDate(),
            allDay: false,
            color: register.subjectColour,
            resource: register,
            showEventPopup: props.showEventPopup,
            onlyStatuses: props.onlyStatuses
          } as EventsMappedToView)
      )
    })
  });

  useEffect(() => {
    setQuery({
      ...query,
      ...props?.filters
    });
    if (props.calenderType === 'lessonSchedule' && !isUninitialized) {
      refetchAllRegisters();
    }
  }, [
    props?.filters,
    props?.filters?.studentIds?.length,
    props?.filters?.teacherIds?.length,
    props?.filters?.classIds?.length,
    props?.filters?.meetingAccountIds?.length,
    props?.filters?.subjectIds?.length,
    props?.filters?.yearIds?.length
  ]);

  return (
    <CalenderStyled>
      <BaseCalender
        className="calender"
        localizer={localizer}
        events={
          allRegisterEventsData?.map(
            (register) =>
              ({
                start: utcToLocalDateTime(register.date, register.startTime).toDate(),
                end: utcToLocalDateTime(register.date, register.endTime).toDate(),
                allDay: false,
                color: register.subjectColour,
                resource: register,
                showEventPopup: props.showEventPopup,
                onlyStatuses: props.onlyStatuses
              } as EventsMappedToView)
          ) ?? studentEventsData
        }
        startAccessor="start"
        endAccessor="end"
        culture="en-gb"
        scrollToTime={moment().toDate()}
        titleAccessor={'resource'}
        defaultView="week"
        views={['month', 'day', 'week']}
        toolbar={true}
        eventPropGetter={(event: any) => {
          return {
            className: moment(event.end).diff(moment(event.start)) < 3.6e6 ? 'event-container-sm' : 'event-container',
            style: {
              backgroundColor: event.color || 'green'
            }
          };
        }}
        components={{
          toolbar: CustomToolbar,
          header: CustomHeader,
          event: CustomEvent
        }}
        onNavigate={(newDate: any, view: any, action: any) => {
          const startDate = moment(newDate)
            .startOf(view.toLowerCase() as any)
            .format(dateFormat());
          const endDate = moment(newDate)
            .endOf(view.toLowerCase() as any)
            .format(dateFormat());
          setQuery({
            ...query,
            startDate,
            endDate
          });
        }}
      />
    </CalenderStyled>
  );
};

export type EventsMappedToView = Event & { color: string };

export interface IProp {
  entityId: string;
  entityType: UserType;
  filters?: {
    studentIds: string[];
    teacherIds?: string[];
    classIds?: string[];
    meetingAccountIds?: string[];
    subjectIds?: string[];
    yearIds?: string[];
  };
  showEventPopup?: boolean;
  onlyStatuses?: ClassStudentClassStatuses[];
  calenderType?: 'lessonSchedule';
}
