import { Link, useSearchParams } from 'react-router-dom';

import AuthCard from '../../../components/Cards/AuthCard';

import { message } from 'antd';
import Spinner from 'app/components/Spinner';
import { Button } from 'app/components/v1/Button';
import { Errors, FormControl, Input } from 'app/components/v1/Input';
import { useCreatePasswordMutation, useGetAccountByPasswordResetTokenMutation } from 'app/services/auth';

import { useErrorHandler } from 'app/utils/error-handler/error-handler';
import { useFormValidation } from 'app/utils/hooks/useFormValidation';
import { SearchParams } from 'app/utils/router';
import { password, passwordConfirmation, yup } from 'app/utils/validations';
import { useFormik } from 'formik';
import { useEffect } from 'react';

export interface ICreatePasswordProps {
  title: string;
}

export default function ResetPassword(props: ICreatePasswordProps) {
  const { handle } = useErrorHandler();
  const { title } = props;
  const [searchParams] = useSearchParams();
  const formik = useFormik<ResetPasswordFormValues>({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: yup.object({
      password: password(),
      passwordConfirmation: passwordConfirmation()
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await handle(() => {
        createPassword({
          password: values.password,
          passwordResetToken: searchParams.get(SearchParams.CREATE_PASSWORD_TOKEN)!
        });
      });
      message.success('Your password reset successfully');
      window.location.href = '/login';
    }
  });

  const [getAccountByToken, { data, isLoading: isLoadingAccountInformation }] =
    useGetAccountByPasswordResetTokenMutation();

  const [
    createPassword,
    { isLoading: isCreatePasswordLoading, isSuccess: isCreatePasswordSuccess, isError: isCreatePasswordError }
  ] = useCreatePasswordMutation();

  useFormValidation();

  useEffect(() => {
    if (searchParams.get(SearchParams.CREATE_PASSWORD_TOKEN)) {
      getAccountByToken({
        passwordResetToken: searchParams.get(SearchParams.CREATE_PASSWORD_TOKEN)!
      });
    }
  }, [searchParams.get(SearchParams.CREATE_PASSWORD_TOKEN)]);

  if (!searchParams.get(SearchParams.CREATE_PASSWORD_TOKEN)) {
    return <h1 className="text-center">Invalid Token</h1>;
  } else if (isLoadingAccountInformation) {
    return <Spinner />;
  } else if (isCreatePasswordSuccess) {
    return (
      <AuthCard
        title="Password Created Successfully"
        subtitle="Login to your account with newly created password please"
      >
        <Link
          className="btn btn-primary w-25 m-auto d-inline-flex align-items-center justify-content-center rounded-3"
          to="/login"
        >
          Login
        </Link>
      </AuthCard>
    );
  }

  return (
    //<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}>
    <div className="d-flex flex-column col-12 col-lg-5">
      <AuthCard title={title}>
        <div className="d-flex align-items-center flex-column mb-4">
          <img src={data?.avatarImageName} alt="" />
          <div className="fw-bolder">{data?.username}</div>
          <div>{data?.email}</div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
        >
          <FormControl>
            <Input name="Password" type="password" onChange={(value) => formik.setFieldValue('password', value)} />
            {formik.errors.password && <Errors errors={{ required: [formik.errors.password] }} />}
          </FormControl>
          <FormControl className="mt-3">
            <Input
              name="Confirm Password"
              type="password"
              onChange={(value) => formik.setFieldValue('passwordConfirmation', value)}
            />
            {formik.errors.passwordConfirmation && (
              <Errors errors={{ required: [formik.errors.passwordConfirmation] }} />
            )}
          </FormControl>

          <Button
            className="st-v1-button st-v1-button-primary col-12 mx-0 mt-3"
            name="Confirm Password"
            loading={isCreatePasswordLoading || isLoadingAccountInformation}
            disabled={isCreatePasswordLoading || isLoadingAccountInformation}
            type="submit"
          />
        </form>
      </AuthCard>
      <p className="text-center text-primary mt-6">
        Don’t have an account yet?{' '}
        <Link to="/signup" className="">
          Sign Up
        </Link>
      </p>{' '}
    </div>
    //</GoogleReCaptchaProvider>
  );
}

type ResetPasswordFormValues = {
  password: string;
  passwordConfirmation: string;
};
