import api from './index';
import { GetQueryPayload } from './types';

export const notesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findNotes: builder.query<any, GetQueryPayload>({
      providesTags: ['Note'],
      query: (payload) => {
        return {
          url: `/api/notes?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    fineOneNote: builder.query<any, GetQueryPayload & { id: string }>({
      providesTags: ['Note'],
      query: (payload) => {
        return {
          url: `/api/notes/${payload.id}?${payload.requestQuery}`,
          method: 'GET'
        };
      }
    }),
    updateOneNote: builder.mutation<any, any>({
      invalidatesTags: ['Note'],
      query: (body) => ({
        url: `/api/notes/${body.id}`,
        method: 'PUT',
        body: { data: body }
      })
    }),
    createOneNote: builder.mutation<any, any>({
      invalidatesTags: ['Note'],
      query: (body) => ({
        url: '/api/notes',
        method: 'POST',
        body: { data: body }
      })
    }),
    deleteOneNote: builder.mutation<any, { id: string }>({
      invalidatesTags: ['Note'],
      query: (body) => ({
        url: `/api/notes/${body.id}`,
        method: 'DELETE',
        body: { data: body.id }
      })
    })
  })
});
