const BREAK_POINTS = {
  MAX_XL: '1600px',
  MIN_XL: '1400px',
  MAX_L: '1200px',
  MIN_L: '1025px ',
  MAX_S: '1024px',
  MIN_S: '769px',
  MAX_XS_: '768px',
  MIN_XS: '481px',
  MAX_XXS_: '480px',
  MIN_XXS: '320px '
};

const MAIN_COLORS = {
  primary: '#4C54A0',
  secondary: '#92929D',
  secondary2: '#C4C4C4',
  success: '#45B1B2',
  danger: '#FC5A5A',
  info: '#50B5FF',
  warning: '#FFC542',
  white: '#ffffff'
};

const FONT_SIZES = {
  SM: '12px',
  MD: '16px',
  LG: '24px'
};

const FONT_WEIGHTS = {
  B7: '700',
  B6: '600',
  B4: '400'
};

const THEME_COLORS = {
  primaryText: '#000',
  secondaryText: '#92929D'
};

const STYLE_DEFAULTS = {
  MAIN_COLORS,
  THEME_COLORS,
  BREAK_POINTS,
  FONT_SIZES,
  FONT_WEIGHTS
};

export default STYLE_DEFAULTS;
