// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
  height: unset !important;
  padding-top: 20px !important;
  border: 1px solid #e2e2ea !important;
}

.form-floating-element {
  border: 1px solid #e2e2ea;
  color: #92929d;
}

.form-floating-eyeIcon {
  right: 10px;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.form-icon {
  right: 10px;
}

.css-qc6sy-singleValue_red {
  color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/Form/styles/input.styles.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,4BAAA;EACA,oCAAA;AACF;;AACA;EACE,yBAAA;EACA,cAAA;AAEF;;AAAA;EACE,WAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AAGF;;AADA;EACE,WAAA;AAIF;;AAFA;EACE,qBAAA;AAKF","sourcesContent":[".textarea {\n  height: unset !important;\n  padding-top: 20px !important;\n  border: 1px solid #e2e2ea !important;\n}\n.form-floating-element {\n  border: 1px solid #e2e2ea;\n  color: #92929d;\n}\n.form-floating-eyeIcon {\n  right: 10px;\n  cursor: pointer;\n  width: 15px;\n  height: 15px;\n}\n.form-icon {\n  right: 10px;\n}\n.css-qc6sy-singleValue_red {\n  color: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
