import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import storage, { STORAGE_KEYS } from 'app/utils/storage';
import moment from 'moment';

export const prepareHeaders = (
  headers: Headers,
  {
    getState,
    endpoint
  }: {
    getState: () => any;
    endpoint: any;
  }
) => {
  const token = storage.getItem(STORAGE_KEYS.TOKEN);
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
};

//RTK Interceptor
export const staggeredBaseQueryWithBailOut = (baseUrl: string) =>
  retry(
    async (args, api, extraOptions) => {
      const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders
      });

      // if token corrupted for any reason clear the storage so we can avoid infinite loop
      const token = storage.getItem(STORAGE_KEYS.TOKEN);
      const refreshToken = storage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
      if (token && refreshToken && isExpired(token)) {
        const refreshResult = await fetchBaseQuery({
          baseUrl
        })(
          {
            url: 'api/token/refresh',
            method: 'POST',
            body: { refreshToken: refreshToken }
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          storeOrUpdateToken((refreshResult.data as any).jwt, (refreshResult.data as any).refreshToken);
        } else {
          removeToken();
          window.location.href = '/login';
        }
      }

      return await baseQuery(args, api, extraOptions);
    },
    {
      retryCondition: (error:any, payload, { attempt }) => {
        return error.status.toString() === '500' && attempt < 2;
      }
    }
  );
export const formatDate = (str: string): string => {
  const date = new Date(str);
  return date.toDateString().slice(4);
};

const isExpired = (token: string) => {
  const expireTime = JSON.parse(atob(token.split('.')[1])).exp;
  const expired = moment.unix(expireTime).isSameOrBefore(moment());
  return expired;
};
const removeToken = () => {
  storage.removeItem(STORAGE_KEYS.TOKEN);
  storage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  storage.removeItem(STORAGE_KEYS.USER_ID);
};
const storeOrUpdateToken = (token: string, refreshToken: string) => {
  storage.setItem(STORAGE_KEYS.TOKEN, token);
  storage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
};
