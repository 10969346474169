import { Theme } from 'app/ui-kit/theme/Theme';
import styled from 'styled-components';

interface IToolbarViewButtonProps {
  isActive: boolean;
}

export const ToolbarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  /* write media query */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ToolbarLabel = styled.h3`
  font-family: var(--st-font-family-primary);
  font-style: normal;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-left: 26px;
  color: #171725;
`;

export const ToolbarButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 15px;
`;
export const ToolbarButton = styled.button`
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  padding: 10px 13px 10px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background-color: #fff;
`;

export const ToolbarButtonIcon = styled.img`
  width: 7px;
  height: 12px;
`;

export const ToolbarViewButton = styled.a<IToolbarViewButtonProps>`
  align-items: center;
  font-family: var(--st-font-family-secondary);
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border: 1px solid;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: ${(props) => (props.isActive ? '#ffffff' : '#171725')};
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: ${(props) => (props.isActive ? Theme.colors.primaryColor : '#fff')};
`;

export const ToolbarViewButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;


export const EventTitle = styled.p`
  font-family: var(--st-font-family-secondary);
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fafafb;
`;

export const ClassId = styled.p`
  font-family: var(--st-font-family-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fafafb;
`;
